<template>
	<v-container fluid>
		<v-data-table :loading="loading" :items="items" :headers="headers" :footer-props="{ itemsPerPageOptions: [-1] }">
			<template v-slot:[`item.actions`]="{ item }">
				<v-btn icon @click="openScriptDialog(item)" v-if="$can('scripts:executar')">
					<v-icon>mdi-play-circle</v-icon>
				</v-btn>
				<v-btn icon :loading="item.loading" @click="logs(item)" v-if="$can('scripts:logs')">
					<v-icon>mdi-script</v-icon>
				</v-btn>
			</template>
		</v-data-table>
		<div style="height: 80px"></div>
		<v-btn color="primary" :loading="stopLoading" fab dark fixed bottom right persistent @click="interromper" v-if="$can('scripts:interromper')">
			<v-icon>mdi-stop</v-icon>
		</v-btn>
		<v-dialog width="600" v-model="scriptDialog" persistent scrollable>
			<v-card :loading="scriptLoading">
				<v-toolbar flat>
					<span>{{obj.script}}</span>
					<v-spacer></v-spacer>
					<v-icon @click="closeScriptDialog">mdi-close</v-icon>
				</v-toolbar>
				<v-divider></v-divider>
				<v-card-text>
					<v-alert type="info" class="ma-0 mt-5" v-if="obj.info">
						<pre>{{obj.info}}</pre>
					</v-alert>
					<v-chip-group column>
						<v-chip close @click:close="removerArg(i)" v-for="(item, i) in args" :key="i">{{item}}</v-chip>
					</v-chip-group>
					<v-text-field hide-details @keydown.enter="addArg" placeholder="Adicionar argumento" v-model="arg" append-outer-icon="mdi-plus" @click:append-outer="addArg"></v-text-field>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn color="secondary" @click="executar">Executar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<style scoped>
	pre {
		white-space: pre-wrap;
		white-space: -moz-pre-wrap;
		white-space: -pre-wrap;
		white-space: -o-pre-wrap;
		word-wrap: break-word;
	}
</style>

<script>
/* eslint-disable no-empty */
import eventBus from '../config/eventBus';
import events from '../config/events';

export default {
	data(){
		return {
			items: [],
			loading: false,
			scriptDialog: false,
			scriptLoading: false,
			stopLoading: false,
			args: [],
			arg: '',
			obj: {},
			headers: [
				{ text: 'Script', value: 'script', sortable: false },
				{ text: '', value: 'actions', sortable: false, align: 'right', width: 148 }
			]
		};
	},
	created(){
		this.carregar();
		eventBus.$on(events.REFRESH, this.carregar);
	},
	destroyed(){
		eventBus.$off(events.REFRESH, this.carregar);
	},
	methods: {
		addArg(){
			if(this.arg) {
				this.args.push(this.arg);
				this.arg = '';
			}
		},
		removerArg(i){
			this.args.splice(i, 1);
		},
		closeScriptDialog(){
			this.args = [];
			this.arg = '';
			this.scriptDialog = false;
		},
		async carregar() {
			this.loading = true;
			try {
				this.items = (await this.$scriptsService.find()).map(x => {
					x.loading = false;
					return x;
				});
			}
			catch(err){
				console.log(err);
			}
			this.loading = false;
		},
		async executar() {
			this.scriptLoading = true;
			try {
				await this.$scriptsService.executar(this.obj.script, this.args);
			}
			catch(err){
				console.log(err);
			}
			this.scriptLoading = false;
			this.closeScriptDialog();
		},
		async logs(obj) {
			obj.loading = true;
			try {
				const data = await this.$scriptsService.logs(obj.script);
				this.$downloadFromData(data, 'logs.txt');
			}
			catch(err){
				console.log(err);
			}
			obj.loading = false;
		},
		async interromper(){
			this.stopLoading = true;
			try {
				await this.$scriptsService.interromper();
			}
			catch(err){
				console.log(err);
			}
			this.stopLoading = false;
		},
		openScriptDialog(obj){
			this.obj = obj;
			this.scriptDialog = true;
		}
	}
};
</script>