<template>
	<v-container fluid>
		<Filtro :items="headers" @filtro="filtrar"></Filtro>
		<v-data-table :server-items-length="itemsLength" :loading="loading" :items="items" :headers="headers" :footer-props="{ itemsPerPageOptions: [25] }" :options.sync="options">
			<template v-slot:[`item.endereco`]="{ item }">{{item.endereco | endereco}}</template>
			<template v-slot:[`item.actions`]="{ item }">
				<template v-if="$enum().PERFIL_USUARIO && $enum().TIPO_ESTABELECIMENTO">
					<v-btn icon @click="openEditDialog(item)" v-if="$can('estabelecimentos:atualizar')">
						<v-icon>mdi-pencil</v-icon>
					</v-btn>
					<v-btn icon @click="openRemoveDialog(item)" v-if="$can('estabelecimentos:remover') && ($admin() || item.tipo == $enum().TIPO_ESTABELECIMENTO.REVENDA)">
						<v-icon>mdi-delete</v-icon>
					</v-btn>
				</template>
			</template>
		</v-data-table>
		<div style="height: 80px"></div>
		<v-btn color="primary" fab dark fixed bottom right persistent @click="openAddDialog" v-if="$can('estabelecimentos:cadastrar') && (!$usuario().mongoId || $admin())">
			<v-icon>mdi-plus</v-icon>
		</v-btn>
		<v-dialog width="600" v-model="dialog" persistent scrollable>
			<v-card :loading="dialogLoading">
				<v-toolbar flat>
					<span>{{obj.id ? 'Editar' : 'Adicionar'}} Estabelecimento</span>
					<v-spacer></v-spacer>
					<v-icon @click="dialog = false">mdi-close</v-icon>
				</v-toolbar>
				<v-divider></v-divider>
				<v-card-text>
					<Estabelecimento v-model="obj" ref="form"></Estabelecimento>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn color="secondary" @click="salvar">Salvar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog width="300" v-model="removeDialog" persistent>
			<v-card :loading="removeLoading">
				<v-container>Deseja remover este estabelecimento?</v-container>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn color="secondary" @click="remover">Sim</v-btn>
					<v-btn color="error" @click="removeDialog = false">Não</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
/* eslint-disable no-empty */
import eventBus from '../config/eventBus';
import events from '../config/events';
import Estabelecimento from '../components/Estabelecimento';

export default {
	components: {
		Estabelecimento
	},
	data(){
		return {
			items: [],
			loading: false,
			options: {},
			itemsLength: 0,
			dialog: false,
			removeDialog: false,
			dialogLoading: false,
			removeLoading: false,
			obj: {},
			where: {},
			c: 0,
			headers: [
				{ text: 'Nº', value: 'id', sortable: false },
				{ text: 'Tipo', value: 'tipo', sortable: false, filtro: 'select', options: Object.values(this.$enum().TIPO_ESTABELECIMENTO) },
				{ text: 'Nome Fantasia', value: 'nomeFantasia', sortable: false, filtro: 'text' },
				{ text: 'Razão Social', value: 'razaoSocial', sortable: false, filtro: 'text' },
				{ text: 'Endereço', value: 'endereco', sortable: false },
				{ text: 'Telefone', value: 'telefone', sortable: false, filtro: 'text' },
				...(this.$admin() ? [{ text: 'Plano', value: 'plano.nome', sortable: false }] : []),
				...(this.$admin() ? [{ text: 'Matriz', value: 'matriz.nomeFantasia', sortable: false }] : []),
				{ text: '', value: 'actions', sortable: false, align: 'right', width: 148 }
			]
		};
	},
	created(){
		eventBus.$on(events.REFRESH, this.carregar);
	},
	destroyed(){
		eventBus.$off(events.REFRESH, this.carregar);
	},
	methods: {
		filtrar(values){
			this.where = {
				...(values.razaoSocial ? { iLike_razaoSocial: `%${values.razaoSocial}%` } : {}),
				...(values.nomeFantasia ? { iLike_nomeFantasia: `%${values.nomeFantasia}%` } : {}),
				...(values.telefone ? { iLike_telefone: `%${values.telefone}%` } : {}),
				...(values.tipo ? { tipo: values.tipo } : {})
			};
			this.carregar(1);
		},
		async carregar(page) {
			this.loading = true;
			try {
				const result = await this.$estabelecimentosService.find(page, {
					...this.where
				});
				this.items = result.docs;
				this.options.page = result.page;
				this.itemsLength = result.total;
			}
			catch(err){
				console.log(err);
			}
			this.loading = false;
		},
		async salvar(){
			if(this.$refs.form.validate()) {
				this.dialogLoading = true;
				try {
					if(this.obj.id) {
						await this.$estabelecimentosService.update(this.obj.id, this.obj);
						this.carregar(this.options.page);
					}
					else {
						await this.$estabelecimentosService.create(this.obj);
						this.carregar(1);
					}
				}
				catch(err){
					console.log(err);
				}
				this.dialog = false;
				this.dialogLoading = false;
			}
		},
		async remover(){
			this.removeLoading = true;
			try {
				await this.$estabelecimentosService.remove(this.obj.id);
				this.carregar(this.items.length == 1 ? this.options.page - 1 : this.options.page);
			}
			catch(e) {}
			this.removeDialog = false;
			this.removeLoading = false;
		},
		openEditDialog(obj){
			this.resetForm();
			this.$nextTick(() => {
				obj.c = ++this.c;
				this.obj = JSON.parse(JSON.stringify(obj));
				this.dialog = true;
			});
		},
		openAddDialog(){
			this.resetForm();
			this.$nextTick(() => {
				this.obj = {
					c: ++this.c,
					tipo: this.$enum().TIPO_ESTABELECIMENTO.REVENDA,
					ativo: true,
					endereco: { },
					bloqueiosGerente: [],
					bloqueiosAtendente: [],
					bloqueiosPortaria: [],
					bloqueiosSupervisor: [],
					bloqueiosProprietario: []
				};
				this.dialog = true;
			});
		},
		openRemoveDialog(obj){
			this.obj = JSON.parse(JSON.stringify(obj));
			this.removeDialog = true;
		},
		resetForm(){
			if(this.$refs.form){
				this.$refs.form.reset();
			}
		}
	},
	watch: {
		options(v) {
			this.carregar(v.page);
		}
	}
};
</script>