import store from './store';

export default () => {
	store.state.axios.interceptors.response.use(response => response, error => {
		if (error.response) {
			const _error = error.response.data;
			let message;

			if (typeof _error == 'string') {
				message = _error;
			}
			else if (_error.message) {
				message = _error.message;
			}
			else if (_error.error) {
				message = _error.error;
			}
			else {
				message = _error;
			}

			store.commit('error', message);
		}
		else {
			store.commit('error', 'Erro de conexão');
		}
		return Promise.reject(error);
	});

	return store.state.axios;
};