<template>
	<v-container fluid>
		<v-data-table :server-items-length="itemsLength" :loading="loading" :items="items" :headers="headers" :footer-props="{ itemsPerPageOptions: [25] }" :options.sync="options">
			<template v-slot:[`item.actions`]="{ item }">
				<v-btn icon @click="openEditDialog(item)" v-if="$can('planos:atualizar')">
					<v-icon>mdi-pencil</v-icon>
				</v-btn>
				<v-btn icon @click="openRemoveDialog(item)" v-if="$can('planos:remover')">
					<v-icon>mdi-delete</v-icon>
				</v-btn>
			</template>
		</v-data-table>
		<div style="height: 80px"></div>
		<v-btn color="primary" fab dark fixed bottom right persistent @click="openAddDialog" v-if="$can('planos:cadastrar')">
			<v-icon>mdi-plus</v-icon>
		</v-btn>
		<v-dialog width="600" v-model="dialog" persistent scrollable>
			<v-card :loading="dialogLoading">
				<v-toolbar flat>
					<span>{{obj.id ? 'Editar' : 'Adicionar'}} Plano</span>
					<v-spacer></v-spacer>
					<v-icon @click="dialog = false">mdi-close</v-icon>
				</v-toolbar>
				<v-divider></v-divider>
				<v-card-text>
					<Plano v-model="obj" ref="form"></Plano>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn color="secondary" @click="salvar">Salvar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog width="300" v-model="removeDialog" persistent>
			<v-card :loading="removeLoading">
				<v-container>Deseja remover este plano?</v-container>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn color="secondary" @click="remover">Sim</v-btn>
					<v-btn color="error" @click="removeDialog = false">Não</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
/* eslint-disable no-empty */
import eventBus from '../config/eventBus';
import events from '../config/events';
import Plano from '../components/Plano';

export default {
	components: {
		Plano
	},
	data(){
		return {
			items: [],
			loading: false,
			options: {},
			itemsLength: 0,
			dialog: false,
			removeDialog: false,
			dialogLoading: false,
			removeLoading: false,
			obj: {},
			headers: [
				{ text: 'Nº', value: 'id', sortable: false },
				{ text: 'Plano', value: 'nome', sortable: false },
				{ text: 'Descrição', value: 'descricao', sortable: false},
				{ text: 'Valor', value: 'valor', sortable: false },
				{ text: 'Lim. de Sublojas', value: 'limiteLojas', sortable: false },
				{ text: '', value: 'actions', sortable: false, align: 'right', width: 148 }
			]
		};
	},
	created(){
		eventBus.$on(events.REFRESH, this.carregar);
	},
	destroyed(){
		eventBus.$off(events.REFRESH, this.carregar);
	},
	methods: {
		async carregar(page) {
			this.loading = true;
			try {
				const result = await this.$planosService.find(page);
				this.items = result.docs;
				this.options.page = result.page;
				this.itemsLength = result.total;
			}
			catch(err){
				console.log(err);
			}
			this.loading = false;
		},
		async salvar(){
			if(this.$refs.form.validate()) {
				this.dialogLoading = true;
				try {
					if(this.obj.id) {
						await this.$planosService.update(this.obj.id, this.obj);
						this.carregar(this.options.page);
					}
					else {
						await this.$planosService.create(this.obj);
						this.carregar(1);
					}
				}
				catch(err){
					console.log(err);
				}
				this.dialog = false;
				this.dialogLoading = false;
			}
		},
		async remover(){
			this.removeLoading = true;
			try {
				await this.$planosService.remove(this.obj.id);
				this.carregar(this.items.length == 1 ? this.options.page - 1 : this.options.page);
			}
			catch(e) {}
			this.removeDialog = false;
			this.removeLoading = false;
		},
		openEditDialog(obj){
			this.resetForm();
			this.$nextTick(() => {
				this.obj = JSON.parse(JSON.stringify(obj));
				this.dialog = true;
			});
		},
		openAddDialog(){
			this.resetForm();
			this.$nextTick(() => {
				this.obj = {
					permissoesGerente: this.$enum().PERMISSOES_PADROES,
					permissoesAtendente: this.$enum().PERMISSOES_PADROES,
					permissoesPortaria: this.$enum().PERMISSOES_PADROES,
					permissoesSupervisor: this.$enum().PERMISSOES_PADROES, 
					permissoesProprietario: this.$enum().PERMISSOES_PADROES,
					tiposPagamento: this.$enum().PERMISSOES_PADROES
				};
				this.dialog = true;
			});
		},
		openRemoveDialog(obj){
			this.obj = JSON.parse(JSON.stringify(obj));
			this.removeDialog = true;
		},
		resetForm(){
			if(this.$refs.form){
				this.$refs.form.reset();
			}
		}
	},
	watch: {
		options(v) {
			this.carregar(v.page);
		}
	}
};
</script>