<template>
	<Administracao relatorioHistorico></Administracao>
</template>

<script>
import Administracao from './Administracao';
export default {
	components: {
		Administracao
	}
};
</script>