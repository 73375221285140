<template>
	<v-container>
		<v-toolbar>
			<v-select v-model="estabelecimentoId" hide-details label="Selecionar estabelecimento" :items="$estabelecimentos()" item-text="nomeFantasia" item-value="id" @change="estabelecimentoSelecionado"></v-select>
			<v-spacer></v-spacer>
			<v-btn :disabled="!estabelecimentoId" color="primary" @click="salvar">Salvar</v-btn>
		</v-toolbar>
		<v-divider></v-divider>
		<v-row v-if="estabelecimentoId">
			<v-col cols="12" md="3" lg="3" xl="2">
				<v-card>
					<v-card-text>
						<v-form>
							<v-subheader>Plano de fundo</v-subheader>
							<v-btn @click="$refs.arquivo.click()" color="primary" class="mb-3" block large>Selecionar arquivo</v-btn>
							<v-text-field class="mb-1" type="number" v-model="width" label="Largura" @input="render"></v-text-field>
							<v-text-field class="mb-1" type="number" v-model="height" label="Altura" @input="render"></v-text-field>
							<input v-model="bg" type="color" id="bg" class="input-color" @input="render"><label for="bg">Cor de fundo</label>
							<v-subheader>QR Code</v-subheader>
							<v-text-field class="mb-1" type="number" v-model="qrX" label="X" @input="render"></v-text-field>
							<v-text-field class="mb-1" type="number" v-model="qrY" label="Y" @input="render"></v-text-field>
							<v-text-field class="mb-1" type="number" v-model="tamanho" label="Tamanho" @input="render"></v-text-field>
							<input type="file" style="display: none;" ref="arquivo" accept="image/*" @change="fileChange">
						</v-form>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" md="3" lg="3" xl="2">
				<v-layout justify-center>
					<v-card :width="width" :height="height" :loading="loading">
						<img :src="url" style="max-width: 100%">
					</v-card>
				</v-layout>
			</v-col>
			<v-col cols="12" md="3" lg="3" xl="2">
				<v-card>
					<v-card-text>
						<v-form>
							<v-subheader>Logo</v-subheader>
							<v-btn color="primary" class="mb-3" block large @click="$refs.logoArquivo.click()">Selecionar arquivo</v-btn>
							<v-text-field class="mb-1" type="number" v-model="logoX" label="X" @input="render"></v-text-field>
							<v-text-field class="mb-1" type="number" v-model="logoY" label="Y" @input="render"></v-text-field>
							<v-text-field class="mb-1" type="number" v-model="logoWidth" label="Largura" @input="render"></v-text-field>
							<v-text-field class="mb-1" type="number" v-model="logoHeight" label="Altura" @input="render"></v-text-field>
							<v-subheader>Informações</v-subheader>
							<v-textarea outlined label="Texto" v-model="info" @input="render" rows="3"></v-textarea>
							<input v-model="bgInfo" type="color" id="bg-info" class="input-color" @input="render"><label for="bg-info">Cor de fundo</label>
							<br>
							<input v-model="fgInfo" type="color" id="fg-info" class="input-color" @input="render"><label for="fg-info">Cor da fonte</label>
							<input type="file" style="display: none;" ref="logoArquivo" accept="image/*" @change="logoChange">
						</v-form>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" md="3" lg="3" xl="2">
				<v-card>
					<v-card-text>
						<v-form>
							<v-subheader>Numerar QRcode</v-subheader>
							<v-checkbox indeterminate dense hide-details: label="Numerar" v-model="numerar"></v-checkbox>
							<v-text-field v-if="numerar" class="mb-1" type="number" v-model="numeroAtual" label="Número Atual" @input="render"></v-text-field>
							<v-text-field v-if="numerar" class="mb-1" type="number" v-model="numeracaoX" label="X" @input="render"></v-text-field>
							<v-text-field v-if="numerar" class="mb-1" type="number" v-model="numeracaoY" label="Y" @input="render"></v-text-field>
						</v-form>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<style scoped>
	.input-color {
		height: 30px;
		width: 30px;
		transform: translateY(5px);
		margin-bottom: 14px;
	}
</style>

<script>
import qrRender from '../config/qrRender';

export default {
	data(){
		return {
			image: null,
			logoImage: null,
			width: null,
			height: null,
			qrX: null,
			qrY: null,
			numerar: false,
			numeracaoX: null,
			numeracaoY: null,
			numeroAtual: null,
			logoX: null,
			logoY: null,
			logoWidth: null,
			logoHeight: null,
			tamanho: null,
			estabelecimentoId: null,
			loading: false,
			file: null,
			logoFile: null,
			url: null,
			bg: null,
			bgInfo: null,
			fgInfo: null,
			info: null
		};
	},
	methods: {
		fileChange(e) {
			this.file = e.target.files[0];
			if(this.file) {
				const reader = new FileReader();
				reader.readAsDataURL(this.file);
				reader.onload = () => {
					this.image = reader.result;
					this.render();
				};
			}
			e.target.value = null;
		},
		logoChange(e) {
			this.logoFile = e.target.files[0];
			if(this.logoFile) {
				const reader = new FileReader();
				reader.readAsDataURL(this.logoFile);
				reader.onload = () => {
					this.logoImage = reader.result;
					this.render();
				};
			}
			e.target.value = null;
		},
		async render() {
			this.url = await qrRender({
				img: this.image,
				logoImg: this.logoImage,
				code: 'Hello World!',
				width: this.width,
				height: this.height,
				qrX: this.qrX,
				qrY: this.qrY,
				logoX: this.logoX,
				logoY: this.logoY,
				logoWidth: this.logoWidth,
				logoHeight: this.logoHeight,
				tamanho: this.tamanho,
				bg: this.bg,
				bgInfo: this.bgInfo,
				fgInfo: this.fgInfo,
				info: this.info,
				numerar: this.numerar,
				numeracaoX: this.numeracaoX,
				numeracaoY: this.numeracaoY,
				numeroAtual: this.numeroAtual,
			});
		},
		async estabelecimentoSelecionado(){
			this.loading = true;
			try {
				const estabelecimento = (await this.$estabelecimentosService.find(1, {
					id: this.estabelecimentoId
				})).docs[0];
				if(estabelecimento.image) {
					this.image = `${this.$store.state.endpoint}/uploads/${estabelecimento.image}`;
					this.logoImage = estabelecimento.logoImage ? `${this.$store.state.endpoint}/uploads/${estabelecimento.logoImage}` : null;
					this.width = estabelecimento.width;
					this.height = estabelecimento.height;
					this.qrX = estabelecimento.qrX;
					this.qrY = estabelecimento.qrY;
					this.tamanho = estabelecimento.tamanho;
					this.logoX = estabelecimento.logoX;
					this.logoY = estabelecimento.logoY;
					this.logoWidth = estabelecimento.logoWidth;
					this.logoHeight = estabelecimento.logoHeight;
					this.bg = estabelecimento.bg;
					this.fgInfo = estabelecimento.fgInfo;
					this.bgInfo = estabelecimento.bgInfo;
					this.info = estabelecimento.info;
					this.numerar = estabelecimento.numerar;
					this.numeracaoX = estabelecimento.numeracaoX;
					this.numeracaoY = estabelecimento.numeracaoY;
					this.numeroAtual = estabelecimento.numeroAtual;
					this.render();
				}
				else {
					this.width = 300;
					this.height = 520;
					this.qrX = 46;
					this.qrY = 191;
					this.tamanho = 210;
					this.logoX = 46;
					this.logoY = 20;
					this.logoWidth = 210;
					this.logoHeight = 180;
					this.bg = '#1279A8';
					this.bgInfo = '#0E597B';
					this.fgInfo = '#FFFFFF';
					this.info = 'vendergas.com.br\n📞 (61) 3333-3333\n💻 vendergasdf';
					this.url = null;
					this.numerar = false;
					this.numeracaoX = 46;
					this.numeracaoY = 20;
					this.numeroAtual = 1;
				}
			}
			catch(err) {
				console.log(err);
			}
			this.loading = false;
		},
		async salvar() {
			this.loading = true;
			try {
				const formData = new FormData();
				if(this.file) {
					formData.append('image', this.file);
				}
				if(this.logoFile) {
					formData.append('logoImage', this.logoFile);
				}
				formData.append('width', this.width);
				formData.append('height', this.height);
				formData.append('qrX', this.qrX);
				formData.append('qrY', this.qrY);
				formData.append('tamanho', this.tamanho);
				formData.append('logoX', this.logoX);
				formData.append('logoY', this.logoY);
				formData.append('logoWidth', this.logoWidth);
				formData.append('logoHeight', this.logoHeight);
				formData.append('bg', this.bg);
				formData.append('fgInfo', this.fgInfo);
				formData.append('bgInfo', this.bgInfo);
				formData.append('info', this.info);
				formData.append('numerar', this.numerar);
				formData.append('numeracaoX', this.numeracaoX);
				formData.append('numeracaoY', this.numeracaoY);
				formData.append('numeroAtual', this.numeroAtual);
				await this.$estabelecimentosService.arteQrcode(this.estabelecimentoId, formData);
				this.estabelecimentoId = null;
				this.$success('Arte do QR Code atualizada');
			}
			catch(err) {
				console.log(err);
			}
			this.loading = false;
		}
	}
};
</script>