import axios from '../config/axios';
const PATH = '/estabelecimentos';

export default {
	async create(body) {
		const response = await axios().post(PATH, body);
		return response.data;
	},
	async find(page = 1, params) {
		const response = await axios().get(PATH, {
			params: {
				page,
				...params
			}
		});
		return response.data;
	},
	async update(id, body) {
		const response = await axios().put(`${PATH}/${id}`, body);
		return response.data;
	},
	async remove(id) {
		const response = await axios().delete(`${PATH}/${id}`);
		return response.data;
	},
	async arteQrcode(id, body) {
		const response = await axios().put(`${PATH}/${id}/arte_qrcode`, body);
		return response.data;
	},
	async listarClientes(id, page = 1, nome) {
		const response = await axios().get(`${PATH}/${id}/clientes`, {
			params: {
				page,
				$nome: nome
			}
		});
		return response.data;
	}
};