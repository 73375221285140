import axios from '../config/axios';
const PATH = '/scripts';

export default {
	async find() {
		const response = await axios().get(PATH);
		return response.data;
	},
	async executar(script, args) {
		const response = await axios().post(`${PATH}/executar`, { script, args });
		return response.data;
	},
	async interromper() {
		const response = await axios().post(`${PATH}/interromper`);
		return response.data;
	},
	async logs(script) {
		const response = await axios().get(`${PATH}/logs`, {
			params: {
				script
			}
		});
		return response.data;
	},
	async runSQL(sql) {
		const response = await axios().post(`${PATH}/sql`, {
			sql
		});
		return response.data;
	}
};