import axios from '../config/axios';
const PATH = '/clientes';

export default {
	async create(body) {
		const response = await axios().post(PATH, body);
		return response.data;
	},
	async find(page = 1, params) {
		const response = await axios().get(PATH, {
			params: {
				page,
				...params
			}
		});
		return response.data;
	},
	async alteracoesValor(page = 1, id) {
		const response = await axios().get(`${PATH}/${id}/alteracoes_valor`, {
			params: {
				page
			}
		});
		return response.data;
	},
	async update(id, body) {
		const response = await axios().put(`${PATH}/${id}`, body);
		return response.data;
	},
	async vincular(id, mongoId) {
		const response = await axios().put(`${PATH}/${id}/vincular`, {mongoId});
		return response.data;
	},
	async remove(id, vgremove) {
		const response = await axios().delete(`${PATH}/${id}`, {
			headers: {
				vgremove
			}
		});
		return response.data;
	},
	async buscarPeloTelefone(telefone) {
		const response = await axios().get(`${PATH}/buscarPeloTelefone`, {
			params: {
				telefone
			}
		});
		return response.data;
	},
	async listEstabelecimentos(id) {
		const response = await axios().get(`${PATH}/${id}/estabelecimentos`);
		return response.data;
	},
	async addEstabelecimento(id, idEstabelecimento) {
		const response = await axios().post(`${PATH}/${id}/estabelecimentos`, {
			id: idEstabelecimento
		});
		return response.data;
	},
	async removeEstabelecimento(id, idEstabelecimento) {
		const response = await axios().delete(`${PATH}/${id}/estabelecimentos/${idEstabelecimento}`);
		return response.data;
	},
	async incrementarValor(filter, value) {
		const response = await axios().post(`${PATH}/incrementarValor`, {
			filter,
			value
		});
		return response.data;
	},
};