import Vue from 'vue';
import usuariosService from '../services/usuarios';
import estabelecimentosService from '../services/estabelecimentos';
import planosService from '../services/planos';
import utilsService from '../services/utils';
import clientesService from '../services/clientes';
import produtosService from '../services/produtos';
import veiculosService from '../services/veiculos';
import entregadoresService from '../services/entregadores';
import valoresNegociadoService from '../services/valoresNegociado';
import errosService from '../services/erros';
import pedidosService from '../services/pedidos';
import scriptsService from '../services/scripts';
import taxasTransacaoService from '../services/taxasTransacao';
import rotasService from '../services/rotas';
import qrcodesLidoService from '../services/qrcodesLido';
import errosSincronizacaoService from '../services/errosSincronizacao';
import errosSincronizacaoVNService from '../services/errosSincronizacaoVN';

Vue.use({
	install(V) {
		V.prototype.$usuariosService = usuariosService;
		V.prototype.$estabelecimentosService = estabelecimentosService;
		V.prototype.$planosService = planosService;
		V.prototype.$utilsService = utilsService;
		V.prototype.$clientesService = clientesService;
		V.prototype.$produtosService = produtosService;
		V.prototype.$veiculosService = veiculosService;
		V.prototype.$entregadoresService = entregadoresService;
		V.prototype.$valoresNegociadoService = valoresNegociadoService;
		V.prototype.$errosService = errosService;
		V.prototype.$pedidosService = pedidosService;
		V.prototype.$scriptsService = scriptsService;
		V.prototype.$taxasTransacaoService = taxasTransacaoService;
		V.prototype.$rotasService = rotasService;
		V.prototype.$qrcodesLidoService = qrcodesLidoService;
		V.prototype.$errosSincronizacaoService = errosSincronizacaoService;
		V.prototype.$errosSincronizacaoVNService = errosSincronizacaoVNService;
	}
});