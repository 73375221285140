<template>
	<v-form ref="form">
		<fieldset class="vg-fieldset">
			<legend>Informações Gerais</legend>
			<v-select v-if="$admin()" label="Tipo" :items="tiposEstabelecimento" v-model="value.tipo" :rules="[rules.required]"></v-select>
			<v-autocomplete :search-input.sync="qsMatriz" :loading="loadingMatrizes" label="Matriz" v-model="value.matrizId" v-if="value.tipo == TIPO_ESTABELECIMENTO.REVENDA && $admin()" :rules="[rules.required]" :items="matrizes" item-value="id" item-text="nomeFantasia"></v-autocomplete>
			<v-autocomplete :search-input.sync="qsPlano" :loading="loadingPlanos" label="Plano" v-model="value.planoId" v-if="value.tipo == TIPO_ESTABELECIMENTO.MATRIZ && $admin()" :rules="[rules.required]" :items="planos" item-value="id" item-text="nome"></v-autocomplete>
			<v-text-field label="Razão Social" v-model="value.razaoSocial" :rules="[rules.required]"></v-text-field>
			<v-text-field label="Nome Fantasia" v-model="value.nomeFantasia" :rules="[rules.required]"></v-text-field>
			<v-text-field type="number" label="CNPJ" v-model="value.cnpj"></v-text-field>
			<v-text-field type="number" label="Telefone" v-model="value.telefone" :rules="[rules.required]"></v-text-field>
			<v-text-field type="number" label="Inscrição Estadual" v-model="value.inscricaoEstadual"></v-text-field>
			<v-text-field type="number" label="Inscrição Municipal" v-model="value.inscricaoMunicipal"></v-text-field>
			<v-text-field label="Email" v-model="value.email" :rules="[rules.required, rules.email]"></v-text-field>
			<v-switch label="Ativo" v-model="value.ativo" v-if="$usuario().matrizId != value.id"></v-switch>
		</fieldset>
		<fieldset class="vg-fieldset">
			<legend>Endereço</legend>
			<Endereco ref="endereco" v-model="value.endereco"></Endereco>
		</fieldset>
		<template v-if="value.planoId">
			<template v-for="(bloqueio, i) in bloqueios">
				<fieldset class="vg-fieldset" :key="i" v-if="(plano[bloqueio.permissao] || []).length">
					<legend>Bloquear permissões do {{bloqueio.nome}}</legend>
					<v-chip-group column multiple active-class="primary" v-model="value[bloqueio.model]">
						<v-chip v-for="(route, j) in plano[bloqueio.permissao]" :key="j" :value="route">{{route}}</v-chip>
					</v-chip-group>
				</fieldset>
			</template>
		</template>
	</v-form>
</template>

<script>
import rules from '../config/rules';
import Endereco from './Endereco';

export default {
	components: {
		Endereco
	},
	props: {
		value: {
			type: Object,
			default(){
				return {};
			}
		}
	},
	data(){
		return {
			rules,
			tiposEstabelecimento: [],
			matrizes: [],
			loadingMatrizes: false,
			loadingPlanos: false,
			routes: [],
			planos: [],
			qsMatriz: null,
			qsPlano: null,
			TIPO_ESTABELECIMENTO: {},
			plano: {},
			bloqueios: [
				{ nome: 'Gerente', model: 'bloqueiosGerente', permissao: 'permissoesGerente'},
				{ nome: 'Atendente', model: 'bloqueiosAtendente', permissao: 'permissoesAtendente'},
				{ nome: 'Portaria', model: 'bloqueiosPortaria', permissao: 'permissoesPortaria'},
				{ nome: 'Supervisor', model: 'bloqueiosSupervisor', permissao: 'permissoesSupervisor'},
				{ nome: 'Proprietario', model: 'bloqueiosProprietario', permissao: 'permissoesProprietario'}
			]
		};
	},
	async created(){
		this.TIPO_ESTABELECIMENTO = this.$enum().TIPO_ESTABELECIMENTO;
		this.tiposEstabelecimento = Object.values(this.TIPO_ESTABELECIMENTO);
		this.initAutocompletes();
		this.obterPlano(this.value.planoId);
	},
	methods: {
		validate(){
			return this.$refs.form.validate();
		},
		reset(){
			this.$refs.form.reset();
		},
		initAutocompletes(){
			if(this.value.plano){
				this.planos = [this.value.plano];
			}
			else if(!this.qsPlano && this.$can('planos:listar')) {
				this.carregarPlanos();
			}
			if(this.value.matriz){
				this.matrizes = [this.value.matriz];
			}
			else if(!this.qsMatriz) {
				this.carregarMatrizes();
			}
		},
		async carregarMatrizes(){
			this.loadingMatrizes = true;
			try {
				this.matrizes = (await this.$estabelecimentosService.find(1, {
					...(this.qsMatriz ? { iLike_nomeFantasia: `%${this.qsMatriz}%` } : {}),
					tipo: this.TIPO_ESTABELECIMENTO.MATRIZ
				})).docs;
			}
			catch(err){
				console.log(err);
			}
			this.loadingMatrizes = false;
		},
		async carregarPlanos(){
			this.loadingPlanos = true;
			try {
				this.planos = (await this.$planosService.find(1, {
					...(this.qsPlano ? { iLike_nome: `%${this.qsPlano}%` } : {})
				})).docs;
			}
			catch(err){
				console.log(err);
			}
			this.loadingPlanos = false;
		},
		obterPlano(id){
			if(id){
				this.plano = this.planos.find(x => x.id == id);
			}
			else {
				this.plano = {};
			}
		}
	},
	watch: {
		value: {
			deep: true,
			handler(){
				this.$emit('input', this.value);
			}
		},
		'value.c'(){
			this.initAutocompletes();
		},
		qsMatriz(v, o){
			if(v != null && (v || o)){
				this.carregarMatrizes();
			}
		},
		qsPlano(v, o){
			if(v != null && (v || o)){
				this.carregarPlanos();
			}
		},
		'value.tipo'(v){
			if(v == this.TIPO_ESTABELECIMENTO.MATRIZ){
				this.value.matrizId = null;
			}
			else {
				this.value.planoId = null;
			}
		},
		'value.planoId'(v){
			this.obterPlano(v);
		}
	}
};
</script>