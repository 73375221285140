<template>
	<v-list dense :color="bg" :dark="dark">
		<v-list-item style="float:right">
			<v-btn icon @click="ativarCancelarEdicao()">
				<v-icon>mdi-pencil</v-icon>
			</v-btn>
		</v-list-item>
		<v-list-item>
			<v-list-item-icon>
				<v-icon>mdi-account-check</v-icon>
			</v-list-item-icon>
			<v-list-item-content v-if="!editar">
				<v-list-item-title>{{ cliente.nome }}</v-list-item-title>
			</v-list-item-content>
			<v-list-item-content v-else>
				<v-text-field label="Nome" v-model="copiaCliente.nome" ></v-text-field>
			</v-list-item-content>
		</v-list-item>
		<v-list-item>
			<v-list-item-icon>
				<v-icon>mdi-map-marker</v-icon>
			</v-list-item-icon>
			<v-list-item-content v-if="!editar">
				<v-list-item-title>{{
					cliente.endereco | endereco
				}}</v-list-item-title>
			</v-list-item-content>
			<v-list-item-content v-else>
				<Endereco ref="endereco" v-model="copiaCliente.endereco"></Endereco>
			</v-list-item-content>
		</v-list-item>
		<v-list-item>
			<v-list-item-icon>
				<v-icon>mdi-phone</v-icon>
			</v-list-item-icon>
			<v-list-item-content v-if="!editar">
				<v-list-item-title>{{
					cliente.telefonePrincipal || "---"
				}}</v-list-item-title>
			</v-list-item-content>
			<v-list-item-content v-else>
				<fieldset class="vg-fieldset">
			<legend>Telefones</legend>
				<span>* Clique na tag para definir o telefone principal</span>
				<v-chip-group column active-class="primary" v-model="copiaCliente.telefonePrincipal">
					<v-chip close v-for="phone in copiaCliente.telefones" :key="phone" @click:close="removePhone(phone)" :value="phone">{{phone}}</v-chip>
				</v-chip-group>
				<v-text-field append-outer-icon="mdi-plus" type="number" step="1" :label="`Novo telefone`" v-model="telefone" hint="Precione ENTER para inserir" @keydown.enter="pushPhone" @click:append-outer="pushPhone"></v-text-field>
			</fieldset>
			</v-list-item-content>
		</v-list-item>
		<v-list-item>
			<v-list-item-icon>
				<v-icon>mdi-account-plus</v-icon>
			</v-list-item-icon>
			<v-list-item-content>
				<v-list-item-title>{{
					cliente.dataPrimeiraLeitura | datetime
				}}</v-list-item-title>
			</v-list-item-content>
		</v-list-item>
		<v-list-item>
			<v-list-item-icon>
				<v-icon>mdi-cash-usd</v-icon>
			</v-list-item-icon>
			<v-list-item-content>
				<v-list-item-title
					>{{ cliente.valorUltimaCompra ? "R$ " : ""
					}}{{
						cliente.valorUltimaCompra || "---"
					}}</v-list-item-title
				>
			</v-list-item-content>
		</v-list-item>
		<v-list-item>
			<v-list-item-icon>
				<v-icon>mdi-cash-usd-outline</v-icon>
			</v-list-item-icon>
			<v-list-item-content>
				<v-list-item-title v-if="!editar"
					>{{ cliente.valorCompra ? "R$ " : ""
					}}{{ cliente.valorCompra || "---" }}</v-list-item-title
				>
                <v-list-item-title v-else>
                    <v-text-field type="number" label="Valor" v-model="copiaCliente.valorCompra" min="0" step="0.01"></v-text-field>
                </v-list-item-title>
			</v-list-item-content>
		</v-list-item>
		<v-list-item>
			<v-list-item-icon>
				<v-icon>mdi-calendar-check</v-icon>
			</v-list-item-icon>
			<v-list-item-content>
				<v-list-item-title>{{
					cliente.dataUltimaCompra | datetime
				}}</v-list-item-title>
			</v-list-item-content>
		</v-list-item>
		<v-list-item>
			<v-list-item-icon>
				<v-icon>mdi-history</v-icon>
			</v-list-item-icon>
			<v-list-item-content>
				<v-list-item-title
					>{{ cliente.media.toFixed(1) }}
					{{ cliente.media == 1 ? "dia" : "dias" }}</v-list-item-title
				>
			</v-list-item-content>
		</v-list-item>
		<v-list-item>
			<v-list-item-icon>
				<v-icon>mdi-qrcode-scan</v-icon>
			</v-list-item-icon>
			<v-list-item-content>
				<v-list-item-title v-if="!editar"
					>{{ cliente.frequenciaVisita.toFixed(1) }}
					{{
                        cliente.frequenciaVisita == 1 ? "dia" : "dias"
					}}</v-list-item-title
				>
                <v-list-item-title v-else>
                    <v-text-field type="number" label="Frequência de leitura" v-model="copiaCliente.frequenciaVisita"></v-text-field>
                </v-list-item-title>

			</v-list-item-content>
		</v-list-item>
		<v-list-item>
			<v-list-item-icon>
				<v-icon>mdi-calendar-clock</v-icon>
			</v-list-item-icon>
			<v-list-item-content>
				<v-list-item-title
					>{{ cliente.diasPassados }}
					{{
						cliente.diasPassados == "---"
							? ""
							: cliente.diasPassados == 1
							? "dia"
							: "dias"
					}}</v-list-item-title
				>
			</v-list-item-content>
		</v-list-item>
		<v-list-item>
			<v-list-item-icon>
				<v-icon>mdi-qrcode</v-icon>
			</v-list-item-icon>
			<v-list-item-content>
				<v-list-item-title>{{
					cliente.qrcodeEntregue
						? "QR code entregue"
						: "QR code não entregue"
				}}</v-list-item-title>
			</v-list-item-content>
		</v-list-item>
		<v-list-item v-if="historico">
			<v-list-item-icon>
				<v-icon>mdi-shopping</v-icon>
			</v-list-item-icon>
			<v-list-item-content>
				<v-list-item-title
					>{{ getCountCompras(cliente) }} compras</v-list-item-title
				>
			</v-list-item-content>
		</v-list-item>
		<v-list-item>
			<v-list-item-icon>
				<v-icon>mdi-package</v-icon>
			</v-list-item-icon>
			<v-list-item-content>
				<v-list-item-title>{{
					getProdutos(cliente)
				}}</v-list-item-title>
			</v-list-item-content>
		</v-list-item>
		<v-list-item>
			<v-list-item-icon>
				<v-icon>mdi-routes</v-icon>
			</v-list-item-icon>
			<v-list-item-content>
				<v-progress-linear indeterminate v-if="loadingRota"></v-progress-linear>
				<v-list-item-title v-else>
					<template v-if="rotasChip.length == 0">---</template>
					<v-chip v-else v-for="rota in rotasChip" small :key="rota.id" color="primary">{{rota.nome}}</v-chip>
				</v-list-item-title>
			</v-list-item-content>
		</v-list-item>
        <v-list-item style="float:left">
            <v-list-item-content v-if="editar">
                <v-btn @click="ativarCancelarEdicao()" :disabled="loadingRota" color="error">Cancelar</v-btn>
            </v-list-item-content>
        </v-list-item>
        <v-list-item style="float:right">
            <v-list-item-content v-if="editar">
                <v-btn color="secondary" :disabled="loadingRota" @click="salvar(copiaCliente)">Salvar</v-btn>
            </v-list-item-content>
        </v-list-item>
        <div v-if="editar">
            <br>
            <br>
        </div>
	</v-list>
</template>

<script>
import Endereco from '../components/Endereco';
export default {
	components: {
		Endereco,
	},
	props: ['cliente', 'bg', 'dark', 'historico', 'carregarQrcodes'],
	data() {
		return {
			loadingRota: false,
			rotasSearch: [],
			rotasTotais: [],
			editar: false,
            telefone: '',
            copiaCliente: null,
		};
	},
	created(){
		this.carregarRotas();
	},
	methods: {
        pushPhone(){
			if(this.telefone && !this.cliente.telefones.includes(this.telefone)) {
				this.cliente.telefones.push(this.telefone);
				this.telefone = '';
			}
		},
        removePhone(phone){
			this.cliente.telefones = this.cliente.telefones.filter(x => x != phone);
		},
        async salvar(cliente) {
            if (cliente.nome !== null && cliente.nome != '') {
                try {
                    this.loadingRota = true;
					await this.$clientesService.update(cliente.id, cliente);
					this.editar = false;
					this.loadingRota = false;
					this.carregarQrcodes(1, true);
				}
				catch (err) {
					console.log(err);
					this.loadingRota = false;
				}
			}
		},
		getCountCompras(qrcode) {
			return this.historico.filter(q => q.clienteId == qrcode.id && q.compra).length;
		},
		getProdutos(qrcode) {
			const qrcodesLidos = this.historico.filter(q => q.clienteId == qrcode.id && q.compra);
			const somas = [];
			for (const qrcodeLido of qrcodesLidos) {
				for (const qrcodeItem of qrcodeLido.qrcodeItems) {
					const soma = somas.find(s => (s.produto || {}).id == (qrcodeItem.produto || {}).id);
					if (!soma) {
						somas.push({
							produto: qrcodeItem.produto,
							quantidade: qrcodeItem.quantidade
						});
					}
					else {
						soma.quantidade += qrcodeItem.quantidade;
					}
				}
			}
			return somas.map(soma => `${soma.quantidade}x ${soma.produto.nome}`).join(', ') || '---';
		},
		async carregarRotas() {
			this.loadingRota = true;
			try {
				this.rotasSearch = (await this.$rotasService.find(1, {
					estabelecimentoId: this.cliente.estabelecimentoId
				})).docs;
				for (const rota of this.rotasSearch) {
					if (!this.rotasTotais.find(r => r.id == rota.id)) {
						this.rotasTotais.push(rota);
					}
				}
			}
			catch (err) {
				console.log(err);
			}
			this.loadingRota = false;
		},
        ativarCancelarEdicao() {
            console.log(this.copiaCliente)
            if(this.editar) {
                this.editar = false;
            }
            else {
                this.copiaCliente = {...this.cliente};
                this.editar = true;
            }
        }
	},
	computed: {
		rotasChip() {
			return this.cliente.rotas.map(id => {
				return this.rotasTotais.find(rota => rota.id == id) || null;
			}).filter(r => !!r);
		}
	},
	watch: {
		cliente(){
			this.carregarRotas();
            this.editar = false;
		}
	}
};
</script>