<template>
	<v-container fluid>
		<v-data-table :server-items-length="itemsLength" :loading="loading" :items="items" :headers="headers" :footer-props="{ itemsPerPageOptions: [25] }" :options.sync="options">
			<template v-slot:[`item.pedido.id`]="{ item }">
				<v-chip small :color="(item.pedido || {}).sincronizado ? 'green' : 'red'" dark>{{(item.pedido || {}).id || '---'}}</v-chip>
			</template>
			<template v-slot:[`item.createdAt`]="{ item }">{{item.createdAt | datetime}}</template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-btn icon @click="open(item)">
					<v-icon>mdi-eye</v-icon>
				</v-btn>
				<v-btn icon @click="openRemoveDialog(item)" v-if="$can('errosSincronizacao:remover')">
					<v-icon>mdi-delete</v-icon>
				</v-btn>
			</template>
		</v-data-table>
		<v-dialog width="300" v-model="removeDialog" persistent>
			<v-card :loading="removeLoading">
				<v-container>Deseja remover este erro?</v-container>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn color="secondary" @click="remover">Sim</v-btn>
					<v-btn color="error" @click="removeDialog = false">Não</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog width="900" v-model="dialog" persistent scrollable>
			<v-card :loading="dialogLoading" v-if="erro">
				<v-toolbar flat dense>
					<v-chip :color="(erro.pedido || {}).sincronizado ? 'green' : 'red'" dark>{{(erro.pedido || {}).id || '---'}}</v-chip><v-divider vertical class="mx-5"></v-divider><span>{{erro.estabelecimento.nomeFantasia}}</span>
					<v-spacer></v-spacer>
					<v-icon @click="dialog = false">mdi-close</v-icon>
				</v-toolbar>
				<v-divider></v-divider>
				<v-card-text>
					<br>
					<p>
						<strong>Stack</strong>
						<pre>{{erro.stack}}</pre>
					</p>
					<p>
						<strong>Response</strong>
						<pre>{{erro.response || 'NULL'}}</pre>
					</p>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn :disabled="!erro.pedido || erro.pedido.sincronizado" :loading="syncLoading" color="secondary" @click="sincronizar(erro.pedido.id)">Sincronizar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog width="600" v-model="responseDialog" persistent scrollable>
			<v-card :loading="dialogLoading" v-if="erro">
				<v-toolbar flat>
					<span>Resposta da Requisição</span>
					<v-spacer></v-spacer>
					<v-icon @click="responseDialog = false">mdi-close</v-icon>
				</v-toolbar>
				<v-divider></v-divider>
				<v-card-text>
					<br>
					<pre>{{response}}</pre>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<style scoped>
	pre {
		overflow: auto;
		background-color: #333;
		color: #fff;
		padding: 1em;
	}
</style>

<script>
/* eslint-disable no-empty */
import eventBus from '../config/eventBus';
import events from '../config/events';

export default {
	data(){
		return {
			items: [],
			loading: false,
			syncLoading: false,
			options: {},
			itemsLength: 0,
			removeDialog: false,
			removeLoading: false,
			dialog: false,
			dialogLoading: false,
			responseDialog: false,
			response: null,
			erro: {
				pedido: {},
				estabelecimento: {}
			},
			headers: [
				{ text: 'Nº', value: 'id', sortable: false },
				{ text: 'Pedido', value: 'pedido.id', sortable: false },
				{ text: 'Estabelecimento', value: 'estabelecimento.nomeFantasia', sortable: false },
				{ text: 'Tentativa', value: 'tentativa', sortable: false },
				{ text: 'Data', value: 'createdAt', sortable: false },
				{ text: '', value: 'actions', sortable: false, align: 'right', width: 148 }
			]
		};
	},
	created(){
		eventBus.$on(events.REFRESH, this.carregar);
		eventBus.$on(events.ESTABELECIMENTOS_ATUALIZADOS, this.carregar);
	},
	destroyed(){
		eventBus.$off(events.REFRESH, this.carregar);
		eventBus.$off(events.ESTABELECIMENTOS_ATUALIZADOS, this.carregar);
	},
	methods: {
		async sincronizar(pedidoId){
			this.syncLoading = true;
			try {
				await this.$pedidosService.sincronizar(pedidoId);
				(this.erro.pedido || {}).sincronizado = true;
				this.carregar(this.options.page);
			}
			catch(e) {}
			this.syncLoading = false;
		},
		async carregar(page) {
			this.loading = true;
			try {
				const result = await this.$errosSincronizacaoService.find(page, {
					in_estabelecimentoId: this.$estabelecimentos().map(x => x.id).join(',') || null
				});
				this.items = result.docs;
				this.options.page = result.page;
				this.itemsLength = result.total;
			}
			catch(err){
				console.log(err);
			}
			this.loading = false;
		},
		async remover(){
			this.removeLoading = true;
			try {
				await this.$errosSincronizacaoService.remove(this.erro.id);
				this.carregar(this.items.length == 1 ? this.options.page - 1 : this.options.page);
			}
			catch(e) {}
			this.removeDialog = false;
			this.removeLoading = false;
		},
		openRemoveDialog(erro){
			this.erro = JSON.parse(JSON.stringify(erro));
			this.removeDialog = true;
		},
		open(erro){
			this.erro = erro;
			this.dialog = true;
		}
	},
	watch: {
		options(v) {
			this.carregar(v.page);
		}
	}
};
</script>