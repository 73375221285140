<template>
	<v-form ref="form">
		<v-select label="Estabelecimento" :rules="[rules.required]" v-model="value.estabelecimentoId" :items="$estabelecimentos(this.value.estabelecimento ? JSON.parse(JSON.stringify(this.value.estabelecimento)) : null)" item-text="nomeFantasia" item-value="id"></v-select>
		<v-text-field label="Nome" v-model="value.nome" :rules="[rules.required]"></v-text-field>
		<v-text-field label="Peso" v-model="value.peso" type="number" step="0.001" min="0" suffix="KG"></v-text-field>
		<v-text-field label="Marca" v-model="value.marca"></v-text-field>
		<v-textarea label="Descrição" v-model="value.descricao"></v-textarea>
		<v-switch label="Padrão na emissão de pedido" v-model="value.padrao"></v-switch>
		<v-switch label="Estoque Único" v-model="value.estoqueUnico"></v-switch>
		<v-text-field v-if="!value.estoqueUnico" prefix="R$" label="Valor Vazio" v-model="value.valorVazio" type="number" step="0.01" min="0"></v-text-field>
		<v-text-field prefix="R$" :label="value.estoqueUnico ? 'Valor' : 'Valor Cheio'" v-model="value.valorCheio" type="number" step="0.01" min="0"></v-text-field>
		<v-text-field v-if="!value.estoqueUnico" prefix="R$" label="Custo Vazio" v-model="value.valorCustoVazio" type="number" step="0.01" min="0"></v-text-field>
		<v-text-field prefix="R$" :label="value.estoqueUnico ? 'Custo' : 'Custo Cheio'" v-model="value.valorCustoCheio" type="number" step="0.01" min="0"></v-text-field>
	</v-form>
</template>

<script>
import rules from '../config/rules';

export default {
	props: {
		value: {
			type: Object,
			default(){
				return {};
			}
		}
	},
	data(){
		return {
			rules
		};
	},
	created(){},
	methods: {
		validate(){
			return this.$refs.form.validate();
		},
		reset(){
			this.$refs.form.reset();
		}
	}
};
</script>