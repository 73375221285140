<template>
	<v-container fill-height>
		<v-layout fill-height align-center justify-center>
			Redirecionando...
		</v-layout>
	</v-container>
</template>

<script>
export default {
	created(){
		this.$home();
	}	
};
</script>