import Vue from 'vue';
import store from './store';
import socket from './socket';
import config from './config';
import router from './router';
import printJs from 'print-js';
import moment from 'moment';
import * as xlxs from "xlsx";

/* load the codepage support library for extended support with older formats  */
import { set_cptable } from "xlsx";
import * as cptable from 'xlsx/dist/cpexcel.full.mjs';
set_cptable(cptable);

Vue.use({
	install(V) {
		V.prototype.$socket = socket;
		// eslint-disable-next-line no-undef
		V.prototype.$dev = process.env.NODE_ENV == 'development';

		V.prototype.$token = () => {
			return (store.state.loginData || {}).token;
		};

		V.prototype.$entregador = () => {
			return (store.state.loginData || {}).usuario.entregador;
		};

		V.prototype.$excel = ({ headers, data, mapper = {}, dataInicio, dataFim }) => {

			const array = []

			const produtos = data.map(item => {
				for(const key in mapper) {
					if(mapper[key] && typeof mapper[key] == 'function') {
						item[key] = mapper[key](item);
						if(item.produtos != null){
							return item.produtos
						}
					}
				}
			})

			data.forEach(info => {
                if(info.totais) {
                    array.push([info.cliente.nome, "", "", "", "", "", "", info.valor, ""])
                } else {
                    const clienteNome = info.cliente.nome
                    const entregadorNome = info.entregador.nome
                    const rotaNome = info.rotum.nome
                    const leitura1 = info.leitura1 ? moment(info.leitura1,'DD/MM/YYYY HH:mm').format("DD/MM/YYYY - HH:mm "): '';
                    const leitura2 = info.leitura2 ? moment(info.leitura2,'DD/MM/YYYY HH:mm').format("DD/MM/YYYY - HH:mm "): '';
                    const tempoLeituras = info.tempoLeituras != undefined ? info.tempoLeituras +" minuto(s)" : "--"
                    const observacao = info.observacao || "--"
                    const valor = info.valor || "--"
                    const compra = info.compra ? "Sim" : "Não"
                    const formatedArray = [clienteNome, entregadorNome, rotaNome, leitura1, leitura2, tempoLeituras, observacao, valor, compra]
                    array.push(formatedArray)
                }
			});
			array.forEach(arr => {
				let prod = produtos.shift()
				arr.push([prod])
			})

			//HEADER 
			const header = [`QR CODES LIDOS ${moment(dataInicio).format('DD/MM/YYYY') || "-"} - ${moment(dataFim).format('DD/MM/YYYY') || "-"}`]
			
			//COLUMS
			const columsName = headers.map((header) => {
				return header.text
			})

			//WORKBOOK
			const workBook = xlxs.utils.book_new()
			const workSheetData = [
				header,
				columsName,
				...array,
			]

			const workSheet = xlxs.utils.aoa_to_sheet(workSheetData)
			
			//LARGURA DAS LINHAS
			var wsrows = [
				{hpx: 30}
			]

			workSheetData.forEach( () => {
				wsrows.push({hpx:20})
			});

			workSheet['!rows'] = wsrows

			//LARGURA DAS COLUNAS
			var wscols = [
				{wch:35},
				{wch:25},
				{wch:15},
				{wch:18},
				{wch:20}
			];

			workSheet['!cols'] = wscols

			//APEND WORKSHEET TO WORKBOOK
			xlxs.utils.book_append_sheet(workBook, workSheet, "QRCodesLidos.xlsx")

			//WRITE DATA TO EXCEL
			xlxs.writeFile(workBook, 
			`QRCodesLidos${moment(dataInicio).format('DD/MM/YYYY') || "-"}a${moment(dataFim).format('DD/MM/YYYY') || "-"}.xlsx`)

		}

		V.prototype.$print = ({ headers, data, mapper = {}, title, dataInicio, dataFim }) => {
			const _data = JSON.parse(JSON.stringify(data));
			const properties = headers.map(item => ({
				field: item.value,
				displayName: item.text
			}));
			const infos = [];
			if(title) {
				infos.push(`<span class="title">${title.toUpperCase()}</span>`);
			}
			if(dataInicio || dataFim) {
				infos.push(`<span class="date">${[
					...(dataInicio ? [moment(dataInicio).format('DD/MM/YYYY')] : []),
					...(dataFim ? [moment(dataFim).format('DD/MM/YYYY')] : [])
				].join(' - ')}</span>`);
			}
			printJs({
				printable: _data.map(item => {
					for(const key in mapper) {
						if(mapper[key] && typeof mapper[key] == 'function') {
							item[key] = mapper[key](item);
						}
					}
					for(const property of properties) {
						if(item[property.field] == null) {
							if(property.field.includes('.')) {
								const parts = property.field.split('.');
								let val = item || {};
								for (const [i, part] of parts.entries()) {
									if(val[part] == null) {
										if(i == parts.length - 1) {
											val[part] = '';
											break;
										}
										else {
											val[part] = {};
										}
									}
									val = val[part] || {};
								}
							}
							else {
								item[property.field] = '';
							}
						}
					}
					return item;
				}),
				properties,
				type: 'json',
				style: `
					@page {
						size: Letter landscape;
					}
					* {
						font-family: Arial, Helvetica, sans-serif; font-size: 12px;
					}
					.title {
						font-weight: bold; font-size: 20px;
					}
					.date {
						font-size: 12px;
					}
					hr: {
						border: none;
						border-bottom: 1px solid #ccc;
					}
				`,
				...(infos.length ? { header: infos.join('<br>') + '<hr>' } : {}),
				gridStyle: 'padding: 5px; border: 1px solid #ccc; font-size: 12px;'
			});
		};

		V.prototype.$usuario = (usuario) => {
			if (usuario) {
				store.commit('usuario', usuario);
			}
			else {
				return (store.state.loginData || {}).usuario;
			}
		};

		V.prototype.$enum = () => {
			return store.state.enum;
		};

		V.prototype.$admin = () => {
			return (store.state.loginData || {}).usuario.perfil == (store.state.enum.PERFIL_USUARIO || {}).ADMINISTRADOR;
		};

		V.prototype.$estabelecimentos = (est) => {
			const result = [...store.state.estabelecimentos];
			if (est) {
				if (!result.some(x => x.id == est.id)) {
					result.push(est);
				}
			}
			return result;
		};

		V.prototype.$routes = () => {
			return store.state.routes;
		};

		V.prototype.$endpoint = () => {
			return store.state.endpoint;
		};

		V.prototype.$alert = (msg) => {
			store.commit('error', msg);
		};

		V.prototype.$success = (msg) => {
			store.commit('success', msg);
		};

		V.prototype.$info = (msg) => {
			store.commit('info', msg);
		};

		V.prototype.$loading = (value) => {
			store.commit('loading', value);
		};

		V.prototype.$tiposPagamento = () => {
			const usuario = (store.state.loginData || {}).usuario || {};
			if (usuario.entregador) {
				return Object.values(store.state.enum.TIPO_PAGAMENTO);
			}
			if (usuario.perfil == (store.state.enum.PERFIL_USUARIO || {}).ADMINISTRADOR) {
				return Object.values(store.state.enum.TIPO_PAGAMENTO).filter(x => !!x);
			}
			return (usuario.matriz || {}).plano.tiposPagamento.filter(x => !!x);
		};

		V.prototype.$downloadFromData = (data, fileName) => {
			const a = document.createElement('a');
			const file = new Blob([data], { type: 'octet/stream' });
			const url = URL.createObjectURL(file);
			a.href = url;
			a.download = fileName;
			a.click();
			URL.revokeObjectURL(url);
		};

		V.prototype.$downloadFromUrl = (url, fileName) => {
			const a = document.createElement('a');
			a.href = url;
			a.download = fileName;
			a.click();
		};

		V.prototype.$can = (route) => {
			if (!route) {
				return true;
			}
			const usuario = store.state.loginData ? store.state.loginData.usuario : null;
			const PERFIL_USUARIO = store.state.enum ? store.state.enum.PERFIL_USUARIO : null;
			if (!usuario || !PERFIL_USUARIO) {
				return false;
			}
			if (usuario.entregador) {
				return true;
			}
			if (usuario.perfil != PERFIL_USUARIO.ADMINISTRADOR) {
				const matriz = usuario ? usuario.matriz : null;
				const plano = matriz ? matriz.plano : null;
				let permissoes = [];
				let bloqueios = [];
				if (plano) {
					switch (usuario.perfil) {
						case PERFIL_USUARIO.ATENDENTE:
							permissoes = plano.permissoesAtendente;
							bloqueios = matriz.bloqueiosAtendente;
							break;
						case PERFIL_USUARIO.GERENTE:
							permissoes = plano.permissoesGerente;
							bloqueios = matriz.bloqueiosGerente;
							break;
						case PERFIL_USUARIO.PORTARIA:
							permissoes = plano.permissoesPortaria;
							bloqueios = matriz.bloqueiosPortaria;
							break;
						case PERFIL_USUARIO.PROPRIETARIO:
							permissoes = plano.permissoesProprietario;
							bloqueios = matriz.bloqueiosProprietario;
							break;
						case PERFIL_USUARIO.SUPERVISOR:
							permissoes = plano.permissoesSupervisor;
							bloqueios = matriz.bloqueiosSupervisor;
							break;
					}
				}
				if (!permissoes.includes(route) || bloqueios.includes(route)) {
					return false;
				}
			}
			return true;
		};

		V.prototype.$home = () => {
			const usuario = (store.state.loginData || {}).usuario || {};
			if (config.qrcode) {
				if (usuario.entregador) {
					router.push('/qrcodes');
				}
				else {
					router.push('/administracao');
				}
			}
			else {
				router.push('/teleatendimento');
			}
		};

		V.prototype.$simplify = (text) => {
			if (text) {
				text = text.replace(/[áàâã]/gi, 'a');
				text = text.replace(/[éèêẽ]/gi, 'e');
				text = text.replace(/[íìîĩ]/gi, 'i');
				text = text.replace(/[óòôõ]/gi, 'o');
				text = text.replace(/[úùôõ]/gi, 'u');
				text = text.replace(/[ç]/gi, 'c');
				return text.toLocaleLowerCase();
			}
			return '';
		};

		V.prototype.$getPosition = () => {
			return new Promise((resolve, reject) => {
				if (navigator.geolocation) {
					navigator.geolocation.getCurrentPosition(function (position) {
						resolve(position.coords);
					}, function () {
						reject();
					});
				}
				else {
					reject();
				}
			});
		};
	}
});