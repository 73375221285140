<template>
	<v-container fluid fill-height>
		<v-layout fill-height align-center justify-center>
			<v-card width="400" color="transparent" flat>
				<v-container class="text-center">
					<img src="~/assets/images/logo-qrcode.png" v-if="config.qrcode" height="100">
					<img src="~/assets/images/logo-vendergas.png" v-else height="100">
				</v-container>
				<v-card class="elevation-5">
					<v-alert class="ma-0" type="success" tile>
						<span v-if="config.qrcode">
							ACOMPANHE SUAS ENTREGAS FACILMENTE
						</span>
						<span v-else>
							SISTEMA INOVADOR COMPLETO E FÁCIL DE USAR
						</span>
					</v-alert>
					<v-divider></v-divider>
					<v-card-text>
						<v-form ref="form">
							<v-text-field hide-details dense v-model="email" placeholder="Email"></v-text-field>
							<br>
							<v-text-field hide-details dense type="password" v-model="senha" placeholder="Senha"></v-text-field>
							<br>
							<v-switch v-if="config.qrcode" label="Entrar como entregador" v-model="entregador"></v-switch>
						</v-form>
					</v-card-text>
					<v-divider></v-divider>
					<v-card-actions class="pt-0">
						<v-btn color="secondary" class="mt-3" @click="logar" :loading="loading">Entrar</v-btn>
					</v-card-actions>
				</v-card>
			</v-card>
		</v-layout>
	</v-container>
</template>

<script>
/* eslint-disable no-empty */
import config from '../config/config';

export default {
	data() {
		return {
			email: '',
			senha: '',
			loading: false,
			config,
			entregador: false
		};
	},
	methods: {
		async logar(){
			if(this.$refs.form.validate()){
				this.loading = true;
				try {
					const loginData = await this.$usuariosService.auth(this.email, this.senha, this.entregador);
					this.$store.commit('loginData', loginData);
					this.$home();
				}
				catch(err){
					console.log(err);
				}
				this.loading = false;
			}
		}
	}
};
</script>