<template>
	<div>
		<qrcode-stream @decode="onDecode" ref="qr" :track="false"></qrcode-stream>
		<audio src="~/assets/audio/beep.mp3" ref="beep"></audio>
		<v-dialog v-model="associacaoDialog" width="300" persistent>
			<v-card :loading="associacaoLoading">
				<v-toolbar flat dense>
					<span>Associar QR code a um cliente</span>
					<v-spacer></v-spacer>
					<v-icon @click="closeAssociacaoDialog()">mdi-close</v-icon>
				</v-toolbar>
				<v-divider></v-divider>
				<v-card-text>
					<v-form>
						<v-autocomplete label="Selecionar cliente" hide-details :loading="loadingClientes" :search-input.sync="qsCliente" v-model="clienteId" :items="clientes" item-text="nome" item-value="id"></v-autocomplete>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-btn color="secondary" @click="associar">Salvar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="scanDialog" width="600" scrollable persistent>
			<v-card :loading="loading">
				<v-toolbar flat dense>
					<span>Informações</span>
					<v-spacer></v-spacer>
					<v-icon @click="closeScanDialog">mdi-close</v-icon>
				</v-toolbar>
				<v-divider></v-divider>
				<v-card-text class="pa-0">
					<v-form class="pa-5">
						<v-textarea v-model="observacao" label="Observação"></v-textarea>
						<v-text-field :disabled="!!(cliente || {}).id" type="number" step="0.01" v-model="valor" label="Valor da compra"></v-text-field>
						<v-switch :disabled="pedidoFeito" label="É uma compra?" v-model="compra"></v-switch>
						<v-autocomplete :disabled="pedidoFeito" v-if="$usuario().mongoId && compra" label="Responsável pela venda" hide-details :loading="loadingResponsavel" :search-input.sync="qsResponsavel" v-model="usuarioId" :items="usuarios" item-text="nome" item-value="id"></v-autocomplete>
					</v-form>
					<v-subheader v-if="compra">Produtos</v-subheader>
					<v-form class="pa-5" v-if="compra">
						<v-row v-for="(item, i) in items" :key="i" class="mb-5">
							<v-col cols="12" xs="12" md="5">
								<v-select hide-details label="Produto" :items="produtos" item-text="nome" item-value="id" v-model="item.produtoId"></v-select>
							</v-col>
							<v-col cols="12" xs="12" md="5">
								<v-text-field hide-details label="Quantidade" type="number" v-model="item.quantidade" step="1" min="1"></v-text-field>
							</v-col>
							<v-col cols="12" xs="12" md="2">
								<v-btn color="red" dark fab small class="elevation-1" @click="removeItem(item.UNIQUEID)">
									<v-icon>mdi-minus</v-icon>
								</v-btn>
							</v-col>
						</v-row>
						<v-btn color="primary" @click="addItem">adicionar produto</v-btn>
					</v-form>
					<v-divider></v-divider>
					<v-list dense v-if="cliente">
						<v-list-item>
							<v-list-item-icon>
								<v-icon>mdi-account</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>{{cliente.nome}}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item>
							<v-list-item-icon>
								<v-icon>mdi-map-marker</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>{{cliente.endereco | endereco}}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item>
							<v-list-item-icon>
								<v-icon>mdi-phone</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>{{cliente.telefonePrincipal || '---'}}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item>
							<v-list-item-icon>
								<v-icon>mdi-account-plus</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>{{cliente.dataPrimeiraLeitura | datetime}}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item>
							<v-list-item-icon>
								<v-icon>mdi-cash-usd</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>R$ {{cliente.valorUltimaCompra || '0.00'}}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item>
							<v-list-item-icon>
								<v-icon>mdi-cash-usd-outline</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>{{cliente.valorCompra ? 'R$ ' : ''}}{{cliente.valorCompra || '---'}}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item>
							<v-list-item-icon>
								<v-icon>mdi-calendar-clock</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>{{cliente.dataUltimaCompra | datetime}}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item>
							<v-list-item-icon>
								<v-icon>mdi-history</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>{{cliente.media}} {{cliente.media == 1 ? 'dia' : 'dias'}}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item>
							<v-list-item-icon>
								<v-icon>mdi-qrcode-scan</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>{{cliente.frequenciaVisita}} {{cliente.frequenciaVisita == 1 ? 'dia' : 'dias'}}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item>
							<v-list-item-icon>
								<v-icon>mdi-calendar-clock</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>{{cliente.diasPassados || 0}} {{cliente.diasPassados == 1 ? 'dia' : 'dias'}}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions style="display:flex; justify-content: flex-end;">
					<v-btn v-if="$usuario().mongoId && compra && usuarioId" style="float:right" dark :color="pedidoFeito ? 'grey' : 'primary'" @click="pedidoFeito ? null : openPedidoDialog()">{{ pedidoFeito ? 'Venda feita' : 'Lançar venda'}}</v-btn>
					<v-btn color="secondary" :disabled="loading" style="float:right" @click="enviarDados" v-if="!($usuario().mongoId && compra && usuarioId)">Enviar</v-btn>
				
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="pedidoDialog" persistent scrollable :fullscreen="$vuetify.breakpoint.smAndDown">
			<v-card>
				<v-toolbar flat dense>
					<span>Lançar pedido</span>
					<v-spacer></v-spacer>
					<v-icon @click="pedidoDialog = false">mdi-close</v-icon>
				</v-toolbar>
				<v-divider></v-divider>
				<v-card-text class="pa-0">
					<Teleatendimento v-if="pedidoDialog" :pedido="obj" ref="pedidoForm" @done="pedidoLancado"></Teleatendimento>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import { QrcodeStream } from 'vue-qrcode-reader';
import moment from 'moment';
import Teleatendimento from '../pages/Teleatendimento';
import { nanoid } from 'nanoid';

export default {
	props: {
		qrcode: {
			type: Object,
			default() {
				return {};
			}
		},
		rota: [String, Number],
		todasRotas: {
			type: Array,
			deafault() {
				return []
			}
		},
		estabelecimento: [String, Number],
	},
	components: {
		QrcodeStream,
		Teleatendimento
	},
	data() {
		return {
			loading: false,
			associacaoDialog: false,
			associacaoLoading: false,
			clientes: [],
			loadingClientes: false,
			qsCliente: '',
			observacao: null,
			valor: null,
			clienteId: null,
			qrcodeLido: null,
			scanDialog: false,
			cliente: {},
			compra: false,
			obj: {},
			c: 0,
			pedidoDialog: false,
			pedidoId: null,
			loadingResponsavel: false,
			usuarios: [],
			usuarioId: null,
			qsResponsavel: '',
			pedidoFeito: false,
			items: [],
			produtos: []
		};
	},
	methods: {
		closeAssociacaoDialog() {
			this.clienteId = null;
			this.associacaoDialog = false;
		},
		openScanDialog() {
			this.observacao = null;
			//this.valor = null;
			this.compra = false;
			this.scanDialog = true;
			this.pedidoFeito = false;
			this.usuarioId = null;
			this.carregarProdutos();
		},
		closeScanDialog() {
			this.scanDialog = false;
		},
		async pedidoLancado({ pedido, mongoId }) {
			this.pedidoDialog = false;
			this.valor = Number(pedido.valorTotal);
			//this.pedidoId = pedido.id;
			this.pedidoFeito = true;
			/* this.items = items.map(item => ({
				UNIQUEID: nanoid(),
				produtoId: item.produto.id,
				quantidade: item.quantidade
			})); */
			if(mongoId) {
				try {
					await this.$clientesService.vincular(this.cliente.id, mongoId);
				}
				catch(err) { }
			}
			this.enviarDados();
		},
		async carregarClientes() {
			this.loadingClientes = true;
			try {
				this.clientes = (await this.$clientesService.find(1, {
					...(this.qsCliente ? { iLike_nome: `%${this.qsCliente}%` } : {}),
					qrcode: 'null',
					estabelecimentoId: this.estabelecimento,
					dataPrimeiraLeitura: true
				})).docs;
			}
			catch (err) {
				console.log(err);
			}
			this.loadingClientes = false;
		},
		async carregarUsuarios() {
			this.loadingResponsavel = true;
			try {
				this.usuarios = (await this.$usuariosService.find(1, {
					...(this.qsResponsavel ? { iLike_nome: `%${this.qsResponsavel}%` } : {}),
					in_matrizId: this.$usuario().associacoes.map(x => x.id).join(',')
				})).docs;
				const usuarioSel = this.usuarios.find((a, b) => this.$simplify(a.email) == this.$simplify(this.$usuario().email));
				if(usuarioSel) this.usuarioId = usuarioSel.id
			}
			catch (err) {
				console.log(err);
			}
			this.loadingResponsavel = false;
		},
		addItem() {
			this.items.push({
				UNIQUEID: nanoid(),
				produtoId: null,
				quantidade: 1
			});
		},
		removeItem(UNIQUEID) {
			this.items = this.items.filter(item => item.UNIQUEID != UNIQUEID);
		},
		async carregarProdutos() {
			if (this.estabelecimento) {
				this.loading = true;
				try {
					this.produtos = (await this.$produtosService.find(1, {
						estabelecimentoId: this.estabelecimento,
						paginate: 1000
					})).docs;
					this.produtos.reverse();
					this.loading = false;
				}
				catch (e) {
					console.log(e);
					this.loading = false;
				}
			}
		},
		async associar() {
			this.associacaoLoading = true;
			try {
				const result = await this.$qrcodesLidoService.associacao({
					rotaId: this.rota,
					qrcode: this.qrcodeLido,
					clienteId: this.clienteId,
					observacao: this.observacao,
					valor: this.valor,
					compra: this.compra
				});
				if (result) {
					this.$emit('done');
					this.$success('QR code escaneado');
				}
				else {
					this.$alert('Não conseguimos associar o cliente selecionado');
				}
			}
			catch (err) {
				console.log(err);
			}
			this.associacaoDialog = false;
			this.associacaoLoading = false;
		},
		async onDecode(v) {
			this.$refs.beep.play();
			this.$refs.qr.beforeResetCamera();
			this.qrcodeLido = v;
			if (this.qrcode.qrcode && v != this.qrcode.qrcode) {
				this.$alert('O QR code não corresponde ao cliente selecionado');
				this.valor = null;
			}
			else {
				if (this.cliente.dataUltimaCompra) {
					this.cliente.diasPassados = moment().diff(moment(this.cliente.dataUltimaCompra), 'days');
				}
				if (this.qrcode.valorUltimaCompra) {
					this.cliente = this.qrcode || {};
					this.valor = (this.cliente || {}).valorCompra || (this.cliente || {}).valorUltimaCompra || 0;
					this.openScanDialog();
				} else {
					const cliente = (await this.$clientesService.find(1, {
						qrcode: this.qrcodeLido
					})).docs[0] || {};
					if (!cliente.id || cliente.rotas.includes(this.rota)) {
						this.cliente = cliente;
						this.valor = (this.cliente || {}).valorCompra || (this.cliente || {}).valorUltimaCompra || 0;
						this.openScanDialog();
					} else {
						const rotasCertas = this.todasRotas.filter((r) => cliente.rotas.includes(r.id))
						let rotasFormatadas = null
						if (rotasCertas.length > 0) {
							rotasFormatadas = rotasCertas.reduce((acc, curr) => acc += curr.nome,'')
						}
						this.$alert('Esse QRcode não pertence a essa rota. '
							+ (rotasFormatadas ? 'A rota certa seria:' + rotasFormatadas : ''));
					}
				}
			}
			this.$emit('close');
		},
		async enviarDados() {
            if(!!this.loading) return;
			if (this.compra && this.items.length == 0) {
				this.$alert('Selecione pelo menos um produto');
			}
			else {
                this.loading = true;
				let position = null;
				try {
					position = await this.$getPosition();
				}
				catch (err) {
                    this.loading = false;
					console.log(err);
				}
				try {
					const result = await this.$qrcodesLidoService.create({
						rotaId: this.rota,
						qrcode: this.qrcodeLido,
						observacao: this.observacao,
						valor: this.valor,
						compra: this.compra,
						pedidoId: this.pedidoId,
						qrcodeItems: this.items,
						...(position ? {
							latitude: position.latitude,
							longitude: position.longitude
						} : {}),
					});
					if (result) {
						this.$emit('done');
						this.$success('QR code escaneado');
					}
					else {
						this.associacaoDialog = true;
					}
				}
				catch (err) {
                    this.loading = false;
					console.log(err);
				}
				this.closeScanDialog();
				this.loading = false;
			}
		},
		start() {
			this.$refs.qr.init();
		},
		openPedidoDialog() {
			if (this.items.length == 0) {
				this.$alert('Informe os produtos da venda');
			}
			else {
				const usuario = this.usuarios.find(u => u.id == this.usuarioId);
				this.obj = {};
				this.obj.c = ++this.c;
				this.obj.clienteId = this.cliente.id;
				this.obj.cliente = this.cliente;
				this.obj.usuarioId = this.usuarioId;
				this.obj.usuarioMongoId = usuario.mongoId;
				this.obj.items = this.items.filter(item => item.produtoId).map(item => {
					item.mongoId = this.produtos.find(p => p.id == item.produtoId).mongoId;
					return item;
				});
				this.pedidoDialog = true;
			}
		}
	},
	watch: {
		qsCliente() {
			if (navigator.onLine) this.carregarClientes();
		},
		qsResponsavel() {
			if (navigator.onLine) this.carregarUsuarios();
		}
	}
};
</script>