import axios from '../config/axios';
const PATH = '/qrcodesLido';

export default {
	async create(body) {
		const response = await axios().post(PATH, body);
		return response.data;
	},
	async associacao(body) {
		const response = await axios().post(`${PATH}/associacao`, body);
		return response.data;
	},
	async find(page = 1, params) {
		const response = await axios().get(PATH, {
			params: {
				page,
				...params
			}
		});
		return response.data;
	}
};