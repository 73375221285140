<template>
	<v-form ref="form" autocomplete="off">
		<fieldset class="vg-fieldset">
			<legend>Informações Gerais</legend>
			<v-select label="Estabelecimento" :rules="[rules.required]" v-model="value.estabelecimentoId" :items="$estabelecimentos(this.value.estabelecimento ? JSON.parse(JSON.stringify(this.value.estabelecimento)) : null)" item-text="nomeFantasia" item-value="id"></v-select>
			<v-text-field label="Nome" v-model="value.nome" :rules="[rules.required]"></v-text-field>
			<v-text-field label="Telefone" v-model="value.telefone"></v-text-field>
			<v-text-field label="Email" v-model="value.email"></v-text-field>
			<v-text-field label="Senha" v-model="value.senha" type="password"></v-text-field>
			<v-text-field label="CPF" v-model="value.cpf"></v-text-field>
			<v-autocomplete :search-input.sync="qsVeiculo" :loading="loadingVeiculos" label="Veículo" v-model="value.veiculoId" :items="veiculos" item-value="id" item-text="nome"></v-autocomplete>
			<v-switch label="Ativo" v-model="value.ativo"></v-switch>
			<v-switch label="Porteiro da Revenda" v-model="value.portaria"></v-switch>
		</fieldset>
		<fieldset class="vg-fieldset">
			<legend>Endereço</legend>
			<Endereco ref="endereco" v-model="value.endereco"></Endereco>
		</fieldset>
	</v-form>
</template>

<script>
import Endereco from '../components/Endereco';
import rules from '../config/rules';

export default {
	components: {
		Endereco
	},
	props: {
		value: {
			type: Object,
			default(){
				return {};
			}
		}
	},
	data(){
		return {
			rules: rules,
			qsVeiculo: null,
			loadingVeiculos: false,
			veiculos: []
		};
	},
	created(){
		this.initAutocompletes();
	},
	methods: {
		validate(){
			return this.$refs.form.validate();
		},
		reset(){
			this.$refs.form.reset();
		},
		initAutocompletes(){
			if(!this.qsVeiculo && this.$can('veiculos:listar')) {
				this.carregarVeiculos();
			}
		},
		async carregarVeiculos(){
			this.loadingVeiculos = true;
			try {
				this.veiculos = (await this.$veiculosService.find(1, {
					...(this.qsVeiculo ? { iLike_nome: `%${this.qsVeiculo}%` } : {}),
					in_estabelecimentoId: this.$estabelecimentos().map(x => x.id).join(',') || null
				})).docs;
			}
			catch(err){
				console.log(err);
			}
			this.loadingVeiculos = false;
		}
	},
	watch: {
		'value.c'(){
			this.initAutocompletes();
		},
		qsVeiculo(v, o){
			if(this.value.veiculo){
				this.veiculos = [this.value.veiculo];
			}
			else if(v != null && (v || o)){
				this.carregarVeiculos();
			}
		}
	}
};
</script>