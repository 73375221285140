<template>
	<v-form ref="form">
		<v-autocomplete :search-input.sync="qsCliente" :loading="loadingClientes" label="Cliente" v-model="value.clienteId" :items="clientes" item-value="id" item-text="nome" :rules="[rules.required]"></v-autocomplete>
		<v-autocomplete :search-input.sync="qsProduto" :loading="loadingProdutos" label="Produto" v-model="value.produtoId" :items="produtos" item-value="id" item-text="nome" :rules="[rules.required]"></v-autocomplete>
		<v-select v-if="!estoqueUnico && value.produtoId" label="Tipo de Estoque" v-model="value.tipoEstoque" :items="tiposEstoque" :rules="[rules.required]"></v-select>
		<v-text-field label="Valor" v-model="value.valor" :rules="[rules.required]" type="number" min="0"></v-text-field>
	</v-form>
</template>

<script>
import rules from '../config/rules';

export default {
	props: {
		value: {
			type: Object,
			default(){
				return {};
			}
		}
	},
	data(){
		return {
			rules: rules,
			qsProduto: null,
			loadingProdutos: false,
			qsCliente: null,
			loadingClientes: false,
			produtos: [],
			clientes: [],
			tiposEstoque: []
		};
	},
	created(){
		this.initAutocompletes();
		this.tiposEstoque = Object.values(this.$enum().TIPO_ESTOQUE);
	},
	methods: {
		validate(){
			return this.$refs.form.validate();
		},
		reset(){
			this.$refs.form.reset();
		},
		initAutocompletes(){
			if(!this.qsProduto && this.$can('produtos:listar')) {
				this.carregarProdutos();
			}
			if(!this.qsCliente && this.$can('clientes:listar')) {
				this.carregarClientes();
			}
		},
		async carregarProdutos(){
			this.loadingProdutos = true;
			try {
				this.produtos = (await this.$produtosService.find(1, {
					...(this.qsProduto ? { iLike_nome: `%${this.qsProduto}%` } : {}),
					in_estabelecimentoId: this.$estabelecimentos().map(x => x.id).join(',') || null
				})).docs;
			}
			catch(err){
				console.log(err);
			}
			this.loadingProdutos = false;
		},
		async carregarClientes(){
			this.loadingClientes = true;
			try {
				this.clientes = (await this.$clientesService.find(1, {
					...(this.qsCliente ? { iLike_nome: `%${this.qsCliente}%` } : {}),
					in_estabelecimentoId: this.$estabelecimentos().map(x => x.id).join(',') || null
				})).docs;
			}
			catch(err){
				console.log(err);
			}
			this.loadingClientes = false;
		}
	},
	watch: {
		'value.c'(){
			this.initAutocompletes();
		},
		qsProduto(v, o){
			if(this.value.produto){
				this.produtos = [this.value.produto];
			}
			else if(v != null && (v || o)){
				this.carregarProdutos();
			}
		},
		qsCliente(v, o){
			if(this.value.cliente){
				this.clientes = [this.value.cliente];
			}
			else if(v != null && (v || o)){
				this.carregarClientes();
			}
		},
		'value.produtoId'(v){
			const produtoSelecionado = this.produtos.find(x => x.id == v);
			if(produtoSelecionado && produtoSelecionado.estoqueUnico) {
				this.value.tipoEstoque = this.$enum().TIPO_ESTOQUE.ESTOQUE_CHEIO;
			}
		},
		'value.clienteId'(v){
			const clienteSelecionado = this.clientes.find(x => x.id == v);
			if(clienteSelecionado && !this.value.estabelecimentoId) {
				this.value.estabelecimentoId = clienteSelecionado.estabelecimentoId;
			}
		}
	},
	computed: {
		estoqueUnico(){
			const produtoSelecionado = this.produtos.find(x => x.id == this.value.produtoId);
			return produtoSelecionado ? produtoSelecionado.estoqueUnico : false;
		}
	}
};
</script>