<template>
	<v-container fluid>
		<Filtro :items="headers" @filtro="filtrar"></Filtro>
		<v-btn
			v-if="config.qrcode"
			@click="valorDialog = true"
			class="mb-3"
			color="primary"
			>Aumentar valor dos clientes</v-btn
		>
		<v-data-table
			group-by="estabelecimento.nomeFantasia"
			:server-items-length="itemsLength"
			:loading="loading"
			:items="items"
			:headers="headers"
			:footer-props="{ itemsPerPageOptions: [25] }"
			:options.sync="options"
		>
			<template v-slot:[`group.header`]="{ group, toggle }">
				<td colspan="10" style="cursor: pointer" @click="toggle">
					{{ group != "undefined" ? group : "Sem estabelecimento" }}
				</td>
			</template>
			<template v-slot:[`item.endereco`]="{ item }">{{
				item.endereco | endereco
			}}</template>
			<template v-slot:[`item.qrcode`]="{ item }">{{
				item.qrcode ? 'Sim' : 'Não'
			}}</template>
			<template v-slot:[`item.valorCompra`]="{ item }">{{
				'R$ ' + Number(item.valorCompra || 0).toFixed(2)
			}}</template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-btn
					icon
					@click="openEditDialog(item)"
					v-if="$can('clientes:atualizar')"
				>
					<v-icon>mdi-pencil</v-icon>
				</v-btn>
				<v-btn
					icon
					@click="openRemoveDialog(item)"
					v-if="$can('clientes:remover')"
				>
					<v-icon>mdi-delete</v-icon>
				</v-btn>
				<v-btn
					icon
					@click="openAssociations(item)"
					v-if="$can('clientes:listar_associacoes')"
				>
					<v-icon>mdi-domain</v-icon>
				</v-btn>
				<v-btn
					icon
					@click="openAlteracoesValor(item)"
					v-if="$can('clientes:listar') && config.qrcode"
				>
					<v-icon>mdi-numeric-9-plus-box-multiple</v-icon>
				</v-btn>
			</template>
		</v-data-table>
		<div style="height: 80px"></div>
		<v-btn
			color="primary"
			fab
			dark
			fixed
			bottom
			right
			persistent
			@click="openAddDialog"
			v-if="$can('clientes:cadastrar')"
		>
			<v-icon>mdi-plus</v-icon>
		</v-btn>
		<v-dialog width="600" v-model="dialog" persistent scrollable>
			<v-card :loading="dialogLoading">
				<v-toolbar flat>
					<span>{{ obj.id ? "Editar" : "Adicionar" }} Cliente</span>
					<v-spacer></v-spacer>
					<v-icon @click="dialog = false">mdi-close</v-icon>
				</v-toolbar>
				<v-divider></v-divider>
				<v-card-text>
					<Cliente v-model="obj" ref="form"></Cliente>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn color="secondary" @click="salvar">Salvar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog width="300" v-model="removeDialog" persistent>
			<v-card :loading="removeLoading">
				<v-container>Deseja remover este cliente?</v-container>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn color="secondary" @click="remover">Sim</v-btn>
					<v-btn color="error" @click="removeDialog = false"
						>Não</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="associacoesDialog" width="600" persistent>
			<v-card>
				<v-toolbar flat>
					<span>Associações de {{ obj.nome }}</span>
					<v-spacer></v-spacer>
					<v-icon @click="associacoesDialog = false"
						>mdi-close</v-icon
					>
				</v-toolbar>
				<v-divider></v-divider>
				<v-card-text>
					<Associacao
						:cliente="obj"
						ref="associacaoForm"
					></Associacao>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog v-model="valorDialog" width="300" persistent>
			<v-card>
				<v-toolbar flat>
					<span>Aumentar valor dos clientes</span>
					<v-spacer></v-spacer>
					<v-icon @click="valorDialog = false">mdi-close</v-icon>
				</v-toolbar>
				<v-divider></v-divider>
				<v-card-text>
					<v-form>
						<v-text-field
							label="Valor a incrementar"
							type="number"
							min="0"
							v-model="increment"
						></v-text-field>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-btn color="primary" @click="aumentarValor"
						>Aumentar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="alteracoesValorPopup" max-width="1000" persistent>
			<v-card>
				<v-toolbar flat>
					<span
						>Histórico de alterações do valor de
						{{ alteracoesValorObj.nome }}</span
					>
					<v-spacer></v-spacer>
					<v-icon @click="alteracoesValorPopup = false"
						>mdi-close</v-icon
					>
				</v-toolbar>
				<v-divider></v-divider>
				<v-card-text class="ma-0 pa-0">
					<v-data-table
						:loading="loadingAlteracoesValor"
						:page="alteracoesValorPage"
						:server-items-length="alteracoesValorLength"
						:options.sync="alteracoesValorOptions"
						:items="alteracoesValor"
						:headers="alteracoesValorHeaders"
					>
						<template v-slot:[`item.valorAntigo`]="{ item }">{{
							item.valorAntigo || 0
						}}</template>
						<template v-slot:[`item.createdAt`]="{ item }">{{
							item.createdAt | datetime
						}}</template>
						<template v-slot:[`item.alteradoPor`]="{ item }">{{
							(item.usuario || item.entregador).nome
						}}</template>
					</v-data-table>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
/* eslint-disable no-empty */
import eventBus from '../config/eventBus';
import events from '../config/events';
import Cliente from '../components/Cliente';
import Associacao from '../components/Associacao';
import config from '../config/config';

export default {
	components: {
		Cliente,
		Associacao
	},
	data() {
		return {
			items: [],
			loading: false,
			options: {},
			itemsLength: 0,
			dialog: false,
			removeDialog: false,
			dialogLoading: false,
			removeLoading: false,
			associacoesDialog: false,
			valorDialog: false,
			increment: 0,
			where: {},
			obj: {},
			alteracoesValorObj: {},
			c: 0,
			config,
			alteracoesValorPopup: false,
			alteracoesValor: [],
			loadingAlteracoesValor: false,
			alteracoesValorLength: 0,
			alteracoesValorPage: 1,
			alteracoesValorOptions: {},
			alteracoesValorHeaders: [
				{ text: 'Alterado por', value: 'alteradoPor', sortable: false },
				{ text: 'Valor antigo', value: 'valorAntigo', sortable: false },
				{ text: 'Valor novo', value: 'valorNovo', sortable: false },
				{ text: 'Data', value: 'createdAt', sortable: false },
			],
			headers: [
				{ text: 'Nº', value: 'id', sortable: false },
				{ text: 'Nome', value: 'nome', sortable: false, filtro: 'text' },
				{ text: 'Telefone', value: 'telefonePrincipal', sortable: false, filtro: 'text' },
				{ text: 'Endereço', value: 'endereco', sortable: false },
				{ text: 'Possui QR Code', value: 'qrcode', sortable: false },
				{ text: 'Perfil', value: 'perfil', sortable: false, filtro: 'select', options: Object.values(this.$enum().PERFIL_CLIENTE) },
				{ text: 'Tipo', value: 'tipoPessoa', sortable: false, filtro: 'select', options: Object.values(this.$enum().TIPO_PESSOA) },
				{ text: 'Valor de compra (R$)', value: 'valorCompra', sortable: false, filtro: 'text' },
				// { text: 'CPF', value: 'cpf', sortable: false, filtro: 'text' },
				// { text: 'CNPJ', value: 'cnpj', sortable: false, filtro: 'text' },
				{ text: '', value: 'actions', sortable: false, align: 'right', width: 187 }
			]
		};
	},
	created() {
		eventBus.$on(events.REFRESH, this.carregar);
		eventBus.$on(events.ESTABELECIMENTOS_ATUALIZADOS, this.carregar);
	},
	destroyed() {
		eventBus.$off(events.REFRESH, this.carregar);
		eventBus.$off(events.ESTABELECIMENTOS_ATUALIZADOS, this.carregar);
	},
	methods: {
		openAssociations(obj) {
			this.resetAssociacoes();
			this.$nextTick(() => {
				obj.c = ++this.c;
				this.obj = JSON.parse(JSON.stringify(obj));
				this.associacoesDialog = true;
			});
		},
		async carregarAlteracoesValor(page) {
			this.loadingAlteracoesValor = true;
			try {
				const result = await this.$clientesService.alteracoesValor(page, this.alteracoesValorObj.id);
				this.alteracoesValor = result.docs;
				this.alteracoesValorPage = result.page;
				this.alteracoesValorLength = result.total;
			}
			catch (err) {
				console.log(err);
			}
			this.loadingAlteracoesValor = false;
		},
		openAlteracoesValor(obj) {
			this.alteracoesValor = [];
			this.alteracoesValorPopup = true;
			this.alteracoesValorObj = obj;
			this.carregarAlteracoesValor(1);
		},
		resetAssociacoes() {
			if (this.$refs.associacaoForm) {
				this.$refs.associacaoForm.reset();
			}
		},
		async aumentarValor() {
			this.loading = true;
			try {
				await this.$clientesService.incrementarValor({
					in_estabelecimentoId: this.$estabelecimentos().map(x => x.id).join(',') || null,
					...this.where
				}, this.increment);
				this.$success(`Foi incrementado R$ ${this.increment} a todos os clientes`);
				this.valorDialog = false;
				this.increment = null;
				this.carregar();
			}
			catch (err) {
				console.log(err);
			}
			this.loading = false;
		},
		async carregar(page) {
			this.loading = true;
			try {
				const result = await this.$clientesService.find(page, {
					in_estabelecimentoId: this.$estabelecimentos().map(x => x.id).join(',') || null,
					...this.where
				});
				this.items = result.docs;
				this.options.page = result.page;
				this.itemsLength = result.total;
			}
			catch (err) {
				console.log(err);
			}
			this.loading = false;
		},
		async salvar() {
			if (this.$refs.form.validate()) {
				this.dialogLoading = true;
				try {
					if (this.obj.id) {
						await this.$clientesService.update(this.obj.id, this.obj);
						this.carregar(this.options.page);
					}
					else {
						await this.$clientesService.create(this.obj);
						this.carregar(1);
					}
				}
				catch (err) {
					console.log(err);
				}
				this.dialog = false;
				this.dialogLoading = false;
			}
		},
		async remover() {
			this.removeLoading = true;
			try {
				await this.$clientesService.remove(this.obj.id);
				this.carregar(this.items.length == 1 ? this.options.page - 1 : this.options.page);
			}
			catch (e) { }
			this.removeDialog = false;
			this.removeLoading = false;
		},
		openEditDialog(obj) {
			obj.endereco = obj.endereco || {};
			this.resetForm();
			this.$nextTick(() => {
				this.obj = JSON.parse(JSON.stringify(obj));
				this.dialog = true;
			});
		},
		openAddDialog() {
			this.resetForm();
			this.$nextTick(() => {
				this.obj = {
					c: ++this.c,
					endereco: {},
					telefones: [],
					rotas: []
				};
				this.dialog = true;
			});
		},
		openRemoveDialog(obj) {
			this.obj = JSON.parse(JSON.stringify(obj));
			this.removeDialog = true;
		},
		resetForm() {
			if (this.$refs.form) {
				this.$refs.form.reset();
			}
		},
		filtrar(values) {
			this.where = {
				...(values.nome ? { iLike_nome: `%${values.nome}%` } : {}),
				...(values.telefonePrincipal ? { iLike_telefonePrincipal: `%${values.telefonePrincipal}%` } : {}),
				...(values.perfil ? { perfil: values.perfil } : {}),
				...(values.valorCompra ? { valorCompra: values.valorCompra } : {}),
				...(values.tipo ? { perfil: values.tipo } : {}),
				...(values.cpf ? { iLike_cpf: `%${values.cpf}%` } : {}),
				...(values.cnpj ? { iLike_cnpj: `%${values.cnpj}%` } : {})
			};
			this.carregar(1);
		}
	},
	watch: {
		options(v) {
			this.carregar(v.page);
		}
	}
};
</script>