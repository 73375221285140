<template>
	<v-form ref="form">
		<v-text-field :rules="[rules.required]" label="Nome" v-model="value.nome"></v-text-field>
		<v-text-field :rules="[rules.required, rules.email]" label="Email" v-model="value.email"></v-text-field>
		<v-text-field :rules="[...(value.id ? [] : [rules.required])]" type="password" label="Senha" v-model="value.senha"></v-text-field>
		<v-select :items="perfis" :rules="[rules.required]" label="Perfil" v-model="value.perfil" v-if="value.id != $usuario().id"></v-select>
		<v-autocomplete v-if="$admin() && value.perfil != $enum().PERFIL_USUARIO.ADMINISTRADOR" :search-input.sync="qsMatriz" :loading="loadingMatrizes" label="Matriz" v-model="value.matrizId" :rules="[rules.required]" :items="matrizes" item-value="id" item-text="nomeFantasia"></v-autocomplete>
		<v-switch label="Ativo" v-model="value.ativo" v-if="value.id != $usuario().id"></v-switch>
	</v-form>
</template>

<script>
import rules from '../config/rules';

export default {
	props: {
		value: {
			type: Object,
			default(){
				return {};
			}
		}
	},
	data(){
		return {
			perfis: [],
			rules,
			loadingMatrizes: false,
			qsMatriz: null,
			matrizes: [],
			TIPO_ESTABELECIMENTO: {}
		};
	},
	async created(){
		this.perfis = Object.values(this.$enum().PERFIL_USUARIO).filter(x => x != this.$enum().PERFIL_USUARIO.ADMINISTRADOR || this.$admin());
		this.TIPO_ESTABELECIMENTO = this.$enum().TIPO_ESTABELECIMENTO;
		this.initAutocompletes();
	},
	methods: {
		validate(){
			return this.$refs.form.validate();
		},
		reset(){
			this.$refs.form.reset();
		},
		async carregarMatrizes(){
			this.loadingMatrizes = true;
			try {
				this.matrizes = (await this.$estabelecimentosService.find(1, {
					...(this.qsMatriz ? { iLike_nomeFantasia: `%${this.qsMatriz}%` } : {}),
					tipo: this.TIPO_ESTABELECIMENTO.MATRIZ
				})).docs;
			}
			catch(err){
				console.log(err);
			}
			this.loadingMatrizes = false;
		},
		initAutocompletes(){
			if(this.value.matriz){
				this.matrizes = [this.value.matriz];
			}
			else if(!this.qsMatriz){
				this.carregarMatrizes();
			}
		}
	},
	watch: {
		value: {
			deep: true,
			handler(){
				this.$emit('input', this.value);
			}
		},
		qsMatriz(v, o){
			if(v != null && (v || o)) {
				this.carregarMatrizes();
			}
		},
		'value.c'(){
			this.initAutocompletes();
		}
	}
};
</script>