<template>
	<v-container fluid>
		<v-card dark color="cyan" class="px-5 mb-3 white--text">
			<v-row>
				<v-col cols="12" sm="6" md="4" lg="3">
					<v-autocomplete clearable no-data-text="Digite para buscar uma rota" outlined hide-details :loading="loadingRota" :search-input.sync="qsRota" v-model="rotaId" :items="rotas" item-text="nome" item-value="id">
						<template v-slot:label>
							<span style="color: #fff">Selecionar rota</span>
						</template>
					</v-autocomplete>
				</v-col>
				<v-col>
					<v-btn light height="56" @click="openQrcode()" :disabled="!rotaId">
						<v-icon>mdi-qrcode-scan</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</v-card>
		<v-progress-linear indeterminate v-if="loadingQrcodes"></v-progress-linear>
		<v-subheader>{{totalQrcodes}} QR Codes encontrados</v-subheader>
		<v-row>
			<v-col cols="12" xs="12" sm="6" md="3" xl="2" v-for="qrcode in qrcodes" :key="qrcode.id">
				<v-card @click="openQrcode(qrcode)">
					<v-system-bar window>
						<span :style="{ fontWeight: qrcode.fg ? 'bolder' : 'normal', color: qrcode.fg }">{{qrcode.nome | limitchars(30)}} ({{getCount(qrcode)}})</span>
						<v-spacer></v-spacer>
						<v-icon :color="qrcode.fg" @click.stop="mostrarEndereco(qrcode)">mdi-map-marker</v-icon>
					</v-system-bar>
					<v-divider></v-divider>
					<v-sheet tile :color="qrcode.bg" :dark="qrcode.dark">
						<v-layout justify-center>
							<v-icon class="ma-5" size="100">mdi-qrcode-scan</v-icon>
						</v-layout>
						<v-btn fab dark class="elevation-0" small absolute right bottom color="green darken-3" v-if="getCountCompras(qrcode) > 0" style="transform: translate(5px, -30px);">{{getCountCompras(qrcode)}}</v-btn>
					</v-sheet>
				</v-card>
			</v-col>
		</v-row>
		<v-pagination :disabled="loadingQrcodes" v-if="pages > 1" :length="pages" v-model="page" :total-visible="5" @input="carregarQrcodes($event)"></v-pagination>
		<v-sheet v-if="rotaId && historico.length && qrcodes.length" color="cyan" dark class="py-1 px-2 mb-2 mt-3 elevation-1">
			<i class="mdi mdi-history"></i> Histórico
		</v-sheet>
		<v-data-table v-if="rotaId && historico.length && qrcodes.length" :items="historico" :headers="headersHistorico" :footer-props="{ itemsPerPageOptions: [25] }">
			<template v-slot:[`item.createdAt`]="{ item }">{{item.createdAt | datetime}}</template>
			<template v-slot:[`item.compra`]="{ item }">{{item.compra ? 'Sim' : 'Não'}}</template>
		</v-data-table>
		<v-dialog persistent v-model="lerQrcode" max-width="600">
			<v-card :loading="loading">
				<v-toolbar dense flat>
					<span>Ler QR code</span>
					<v-spacer></v-spacer>
					<v-icon @click="lerQrcode = false">mdi-close</v-icon>
				</v-toolbar>
				<v-divider></v-divider>
				<LeitorQrcode ref="qr" :qrcode="qrcode" :todasRotas="this.todasRotas" :rota="rotaId" @close="lerQrcode = false" @done="carregarQrcodes" :estabelecimento="estabelecimentoId"></LeitorQrcode>
			</v-card>
		</v-dialog>
		<v-dialog persistent v-model="qrcodeEnderecoAberto" max-width="600">
			<v-card>
				<v-toolbar dense flat>
					<span>Visualizar endereço</span>
					<v-spacer></v-spacer>
					<v-icon @click="qrcodeEnderecoAberto = false">mdi-close</v-icon>
				</v-toolbar>
				<v-divider></v-divider>
				<v-card-text class="mt-5" v-if="qrcodeEndereco">
					<p><b>Cliente:</b>&nbsp;{{qrcodeEndereco.nome}}</p>
					<p><b>Endereco:</b>&nbsp;{{qrcodeEndereco.endereco | endereco}}</p>
					<p><b>Valor:</b>&nbsp;{{qrcodeEndereco.valorCompra || 'Sem valor cadastrado'}}</p>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import eventBus from '../config/eventBus';
import events from '../config/events';
import LeitorQrcode from '../components/LeitorQrcode.vue';
import moment from 'moment';

export default {
	components: {
		LeitorQrcode
	},
	data(){
		return {
			rotas: [],
			todasRotas: [],
			qsRota: '',
			loadingRota: false,
			rotaId: null,
			loadingQrcodes: false,
			qrcodes: [],
			pages: 0,
			page: 1,
			lerQrcode: false,
			qrcode: {},
			loading: false,
			historico: [],
			totalQrcodes: 0,
			estabelecimentoId: null,
			qrcodeEndereco: null,
			timerBuscaRota: null,
			qrcodeEnderecoAberto: false,
			headersHistorico: [
				{ text: 'Cliente', value: 'cliente.nome', sortable: false },
				{ text: 'Entregador', value: 'entregador.nome', sortable: false },
				{ text: 'Data', value: 'createdAt', sortable: false },
				{ text: 'Observação', value: 'observacao', sortable: false },
				{ text: 'Valor (R$)', value: 'valor', sortable: false },
				{ text: 'Compra', value: 'compra', sortable: false }
			]
		};
	},
	created(){
		eventBus.$on(events.REFRESH, this.carregarQrcodes);
		eventBus.$on(events.ESTABELECIMENTOS_ATUALIZADOS, this.carregarTudo);
	},
	destroyed(){
		eventBus.$off(events.REFRESH, this.carregarQrcodes);
		eventBus.$off(events.ESTABELECIMENTOS_ATUALIZADOS, this.carregarTudo);
	},
	methods: {
		openQrcode(qrcode) {
			this.qrcode = qrcode;
			this.lerQrcode = true;
			if(this.$refs.qr) {
				this.$refs.qr.start();
			}
		},
		getCountCompras(qrcode) {
			return this.historico.filter(q => q.clienteId == qrcode.id && q.compra).length;
		},
		getCount(qrcode) {
			return this.historico.filter(q => q.clienteId == qrcode.id).length;
		},
		async carregarHistorico(){
			const dataInicio = moment().startOf('day').toDate();
			const dataFim = moment().endOf('day').toDate();
			if(this.rotaId) {
				this.historico = (await this.$qrcodesLidoService.find(1, {
					rotumId: this.rotaId,
					estabelecimentoId: this.estabelecimentoId,
					date_createdAt: [moment(dataInicio).toISOString(), moment(dataFim).toISOString()].join()
				})).docs;
			}
		},
		async carregarTudo(){
			this.rotaId = null;
			this.qrcodes = [];
			// await this.carregarRotas();
			if(this.$token() && this.$estabelecimentos().length) {
				this.rotas = (await this.$rotasService.find(1, {
					...(this.qsRota ? { iLike_nome: `%${this.qsRota}%` } : {}),
					in_estabelecimentoId: this.$estabelecimentos().map(x => x.id).join(',') || null,
				})).docs;
				this.todasRotas = [...this.rotas];
			}
		},
		async carregarRotas() {
			if(this.$token() && this.$estabelecimentos().length) {
				this.loadingRota = true;
				try {
					this.rotas = (await this.$rotasService.find(1, {
						...(this.qsRota ? { iLike_nome: `%${this.qsRota}%` } : {}),
						in_estabelecimentoId: this.$estabelecimentos().map(x => x.id).join(',') || null,
					})).docs;
				}
				catch(err){
					console.log(err);
				}
				this.loadingRota = false;
			}
		},
		async carregarQrcodes(page = 1){
			if(this.rotaId) {
				this.loadingQrcodes = true;
				try {
					await this.carregarHistorico();
					const results = await this.$clientesService.find(page, {
						not_qrcode: 'null',
						contains_rotas: this.rotaId,
						$orderByQrcode: true
					});
					this.qrcodes = results.docs.map(qrcode => {
						qrcode.diasPassados = moment().diff(moment(qrcode.dataUltimaCompra), 'days') || 0;
						if(!isNaN(qrcode.diasPassados)) {
							const lastHist = this.historico.filter(h => h.clienteId == qrcode.id)[0] || {};
							if(qrcode.diasPassados > qrcode.frequenciaVisita && qrcode.frequenciaVisita >= 1) {
								qrcode.bg = 'red';
								qrcode.fg = '#B71C1C';
								qrcode.dark = true;
							}
							else if(this.historico.find(h => h.clienteId == qrcode.id) && !lastHist.compra) {
								qrcode.bg = 'yellow';
								qrcode.fg = '#F57F17';
								qrcode.dark = false;
							}
							else if(this.historico.find(h => h.clienteId == qrcode.id) && lastHist.compra) {
								qrcode.bg = 'secondary';
								qrcode.fg = '#217a6b';
								qrcode.dark = true;
							}
						}
						return qrcode;
					});
					this.pages = results.pages;
					this.page = results.page;
					this.totalQrcodes = results.total;
				}
				catch(err){
					console.log(err);
				}
				this.loadingQrcodes = false;
			}
			else {
				this.qrcodes = [];
			}
		},
		mostrarEndereco(qrcode) {
            console.log(qrcode)
			this.qrcodeEndereco = qrcode;
			this.qrcodeEnderecoAberto = true;
		}
	},
	watch: {
		qsRota(){

			if (this.timerBuscaRota) {
				clearTimeout(this.timerBuscaRota);
			}
			this.timerBuscaRota = setTimeout(() => {
				if(navigator.onLine) this.carregarRotas();
			}, 500);
		},
		rotaId(v) {
			if(v) {
				const rota = this.rotas.find(rota => rota.id == v);
				this.estabelecimentoId = rota.estabelecimentoId;
				this.carregarQrcodes();
			}
			else {
				this.qrcodes = [];
			}
		}
	}
};
</script>