<template>
	<div>
		<div id="mapa" ref="mapa" :style="{height}" v-resize="calcHeight"></div>
		<!-- <img class="loader" ref="greenm" src="~/assets/images/green-marker.png">
		<img class="loader" ref="yellowm" src="~/assets/images/yellow-marker.png"> -->
	</div>
</template>

<script>
import L from 'leaflet';
import _ from 'lodash';
import moment from 'moment';

export default {
	props: {
        mostrarRota: {
            type: Boolean,
            default: false
        },
		qrcodes: {
			type: Array,
			default(){
				return {};
			}
		},
	},
	data(){
		return {
			map: null,
			height: 0,
			markers: null,
			linhas: [],
			tooltips: [],
            cores: [
                '#F44336',
                '#2196F3',
                '#FFEB3B',
                '#4CAF50',
                '#FF9800',
                '#9E9E9E',
                '#FFFFFF',
                '#795548',
                '#18FFFF',
                '#E91E63',
                '#1DE9B6',
                '#FFB300',
            ],
            corAtual: 0,
		};
	},
	methods: {
		calcHeight(){
			this.height = `calc(100vh - ${this.$refs.mapa.offsetTop}px - 124px)`;
		},
		addMarkers() {
			let qrcodes = this.qrcodes.filter(qrcode => qrcode.cliente && qrcode.cliente.latitude && qrcode.cliente.longitude);
			qrcodes = _.groupBy(qrcodes, 'clienteId');

			if(this.markers) {
				this.map.removeLayer(this.markers);
			}

			const markers = [];
			let clienteUnico = null;

            let entregadores = [];

			for(const qrcode in qrcodes) {
                qrcodes[qrcode].sort((a, b) => new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1);
				const cliente = qrcodes[qrcode][0].cliente;
				const compra = qrcodes[qrcode][0].compra;
				const quantidade = qrcodes[qrcode].length;
                let marcadorExistente = markers.find(marker =>  marker._latlng && marker._latlng.lat == cliente.latitude && marker._latlng.lng == cliente.longitude)
                if(marcadorExistente != undefined) continue
				cliente.diasPassados = moment().diff(moment(cliente.dataUltimaCompra), 'days') || 0;

				if(!isNaN(cliente.diasPassados)) {
					if(cliente.diasPassados > cliente.frequenciaVisita && cliente.frequenciaVisita >= 1) {
						cliente.bg = 'red';
						cliente.fg = '#B71C1C';
						cliente.dark = true;
					}
					else if(!compra) {
						cliente.bg = 'yellow';
						cliente.fg = '#F57F17';
						cliente.dark = false;
					}
					else if(compra) {
						cliente.bg = 'secondary';
						cliente.fg = '#217a6b';
						cliente.dark = true;
					}
				}

                let entregadorExiste = entregadores.find( ent => ent.id == qrcodes[qrcode][0].entregadorId);

                if(entregadorExiste == undefined) {
                    entregadores.push({ id: qrcodes[qrcode][0].entregadorId, qrcodesLidos: qrcodes[qrcode], cor: this.getCor() });
                } else {
                    qrcodes[qrcode].forEach( qr => {
                        entregadorExiste.qrcodesLidos.push(qr);
                    });
                    // entregadorExiste.qrcodesLidos.push([cliente.latitude, cliente.longitude]);
                }

                let entregador = entregadores.find( ent => ent.id == qrcodes[qrcode][0].entregadorId)

                const whiteCircle = `
                    background-color: #FFFFFF;
                    width: 1rem;
                    height: 1rem;
                    display: block;
                    align-items: center;
                    text-align: center;
                    justify-content: space-evenly;
                    position: relative;
                    border-radius: 2rem 2rem;
                    transform: rotate(-45deg);
                    border: 1px solid #FFFFFF`

                const markerHtmlStyles = `
                    background-color: ${entregador.cor};
                    width: 1.5rem;
                    height: 1.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;
                    label: 3;
                    position: relative;
                    border-radius: 3rem 3rem 0;
                    transform: rotate(45deg);
                    border: 1px solid #FFFFFF`
                    const icon = L.divIcon({
                        className: "my-custom-pin",
                        iconAnchor: [12, 30],
						iconSize: [24, 30],
						popupAnchor: [1, -30],
						tooltipAnchor: [16, -30],
                        html: `<span style="${markerHtmlStyles}" /> <span style="${whiteCircle}">${entregador.qrcodesLidos.length}</span></span>`
                    })


				const marker = L.marker([cliente.latitude, cliente.longitude], {
					icon: icon
				})
					.bindPopup(`
                    <div style="text-align: center">
                        <b>${cliente.nome}</b>
                        <br>
                        <div>${quantidade} leituras</div>
                        <br>
                        <div> 
                            ${ cliente.endereco && cliente.endereco.logradouro !== undefined && cliente.endereco.logradouro !== null ? cliente.endereco.logradouro +', ' : ''} 
                            ${ cliente.endereco && cliente.endereco.numero !== undefined && cliente.endereco.numero !== null ? cliente.endereco.numero +', ' : ''} 
                            ${ cliente.endereco && cliente.endereco.localidade !== undefined && cliente.endereco.localidade !== null ? cliente.endereco.localidade +', ' : ''}
                            ${ cliente.endereco && cliente.endereco.bairro !== undefined && cliente.endereco.bairro !== null ? cliente.endereco.bairro: ''}
                        </div>
                    </div>
                    `)
					.on('popupopen', () => {
						this.$emit('marcador', cliente);
					})
					.on('popupclose', () => {
						this.$emit('marcador', {});
					});
				markers.push(marker);

				if(!clienteUnico) {
					clienteUnico = cliente;
				}
			}

			if(markers.length > 0) {
                let posMarcadores = [];
				let zoom = 14;
				if(markers.length == 1) {
					zoom = 20;
					this.$nextTick(() => {
						markers[0].openPopup();
						this.$emit('marcador', clienteUnico);
					});
				}
				this.map.setView([clienteUnico.latitude, clienteUnico.longitude], zoom);
			}

			if(this.map) {
				this.markers = L.layerGroup(markers).addTo(this.map);
			}

            if(this.mostrarRota) {
                this.linhas = [];
                this.tooltips = [];
                let posMarcadores = [];

                entregadores.forEach(entregador => {
                    let latlngs = [];
                    let i = 1;
                    entregador.qrcodesLidos.sort((a, b) => new Date(a.createdAt) < new Date(b.createdAt) ? -1 : 1);
                    entregador.qrcodesLidos.forEach( function callback (qrcode) {
                        // Só adiciona o ponto se for a primeira ocorrência de leitura
                        // naquele local. By Pedro Santos
                        if(!posMarcadores.find(qr => qr.latitude == qrcode.cliente.latitude && qr.longitude == qrcode.cliente.longitude)) {
                            latlngs.push([qrcode.cliente.latitude, qrcode.cliente.longitude]);
                            posMarcadores.push({ latitude: qrcode.cliente.latitude, longitude: qrcode.cliente.longitude });
                            let marcador = markers.find(marker =>  marker._latlng && marker._latlng.lat == qrcode.cliente.latitude && marker._latlng.lng == qrcode.cliente.longitude)

                            marcador._icon.lastChild.lastChild.innerHTML = i;
                            i++;
                        }
                    });

                    this.linhas.push(L.polyline(latlngs, {color: entregador.cor}).addTo(this.map));
                    
                });
            }
		},

        getCor() {
            if(this.cores[this.corAtual] != undefined) {
                return this.cores[this.corAtual++];
            } else {
                this.corAtual = 0;
                return this.cores[0];
            }

        }
	},
	async mounted(){
		this.calcHeight();

		const position = [51.505, -0.09];
		try {
			const coords = await this.$getPosition();
			position[0] = coords.latitude;
			position[1] = coords.longitude;
		}
		catch(err) {
			console.log(err);
		}

		this.$nextTick(() => {
			this.map = L.map('mapa').setView(position, 14);

			const street = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
				attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
			});

			const satellite = L.tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
				subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
			});

			satellite.addTo(this.map);

			const baseMaps = {
				'Mapa': street,
				'Satelite': satellite
			};

			L.control.layers(baseMaps, null, { position: 'bottomleft' }).addTo(this.map);

			this.addMarkers();
		});



	},
	watch: {
		qrcodes(){
            if(this.linhas.length > 0) {
                this.linhas.forEach(linha => {
                    this.map.removeLayer(linha);
                });

                this.map.eachLayer(function(layer) {
                    if(layer.options.pane === "tooltipPane") {
                        layer.remove();
                    }
                });

                this.corAtual = 0;
            }
			this.addMarkers();
		},
        // mostrarRota(){
		// 	if(this.mostrarRota) {
        //         this.addMarkers();
        //     } else {
        //         this.linhas.remove(this.map)
        //     }
		// }
	}
};
</script>