<template>
	<v-container fluid>
		<v-data-table :server-items-length="itemsLength" :loading="loading" :items="items" :headers="headers" :footer-props="{ itemsPerPageOptions: [25] }" :options.sync="options">
			<template v-slot:[`item.createdAt`]="{ item }">{{item.createdAt | datetime}}</template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-btn icon @click="open(item)">
					<v-icon>mdi-eye</v-icon>
				</v-btn>
				<v-btn icon @click="openRemoveDialog(item)" v-if="$can('erros:remover')">
					<v-icon>mdi-delete</v-icon>
				</v-btn>
			</template>
		</v-data-table>
		<v-dialog width="300" v-model="removeDialog" persistent>
			<v-card :loading="removeLoading">
				<v-container>Deseja remover este erro?</v-container>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn color="secondary" @click="remover">Sim</v-btn>
					<v-btn color="error" @click="removeDialog = false">Não</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog width="900" v-model="dialog" persistent scrollable>
			<v-card :loading="dialogLoading" v-if="erro">
				<v-toolbar flat>
					<span><strong>{{erro.method}}</strong> {{erro.url}}</span>
					<v-spacer></v-spacer>
					<v-icon @click="dialog = false">mdi-close</v-icon>
				</v-toolbar>
				<v-divider></v-divider>
				<v-card-text>
					<br>
					<p>
						<strong>Stack</strong>
						<pre>{{erro.stack}}</pre>
					</p>
					<p>
						<strong>SQL</strong>
						<pre>{{erro.sql || 'NULL'}}</pre>
					</p>
					<p>
						<strong>Headers</strong>
						<pre>{{erro.headers}}</pre>
					</p>
					<p>
						<strong>Params</strong>
						<pre>{{erro.params}}</pre>
					</p>
					<p>
						<strong>Query</strong>
						<pre>{{erro.query}}</pre>
					</p>
					<p>
						<strong>Body</strong>
						<pre>{{erro.body}}</pre>
					</p>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn color="secondary" @click="run($endpoint())">Executar</v-btn>
					<v-btn color="secondary" @click="run('http://localhost:3200')">Debugar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog width="600" v-model="responseDialog" persistent scrollable>
			<v-card :loading="dialogLoading" v-if="erro">
				<v-toolbar flat>
					<span>Resposta da Requisição</span>
					<v-spacer></v-spacer>
					<v-icon @click="responseDialog = false">mdi-close</v-icon>
				</v-toolbar>
				<v-divider></v-divider>
				<v-card-text>
					<br>
					<pre>{{response}}</pre>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<style scoped>
	pre {
		overflow: auto;
		background-color: #333;
		color: #fff;
		padding: 1em;
	}
</style>

<script>
/* eslint-disable no-empty */
import eventBus from '../config/eventBus';
import events from '../config/events';
import axios from 'axios';

export default {
	data(){
		return {
			items: [],
			loading: false,
			options: {},
			itemsLength: 0,
			removeDialog: false,
			removeLoading: false,
			dialog: false,
			dialogLoading: false,
			responseDialog: false,
			response: null,
			erro: {
				usuario: {},
				estabelecimento: {}
			},
			headers: [
				{ text: 'Nº', value: 'id', sortable: false },
				{ text: 'Data', value: 'createdAt', sortable: false },
				{ text: 'Método', value: 'method', sortable: false },
				{ text: 'URL', value: 'url', sortable: false},
				{ text: 'Usuário', value: 'usuario.nome', sortable: false },
				{ text: 'Estabelecimento', value: 'estabelecimento.nomeFantasia', sortable: false },
				{ text: '', value: 'actions', sortable: false, align: 'right', width: 148 }
			]
		};
	},
	created(){
		eventBus.$on(events.REFRESH, this.carregar);
		eventBus.$on(events.ESTABELECIMENTOS_ATUALIZADOS, this.carregar);
	},
	destroyed(){
		eventBus.$off(events.REFRESH, this.carregar);
		eventBus.$off(events.ESTABELECIMENTOS_ATUALIZADOS, this.carregar);
	},
	methods: {
		async carregar(page) {
			this.loading = true;
			try {
				const result = await this.$errosService.find(page, {
					in_estabelecimentoId: this.$estabelecimentos().map(x => x.id).join(',') || null
				});
				this.items = result.docs;
				this.options.page = result.page;
				this.itemsLength = result.total;
			}
			catch(err){
				console.log(err);
			}
			this.loading = false;
		},
		async remover(){
			this.removeLoading = true;
			try {
				await this.$errosService.remove(this.erro.id);
				this.carregar(this.items.length == 1 ? this.options.page - 1 : this.options.page);
			}
			catch(e) {}
			this.removeDialog = false;
			this.removeLoading = false;
		},
		openRemoveDialog(erro){
			this.erro = JSON.parse(JSON.stringify(erro));
			this.removeDialog = true;
		},
		open(erro){
			this.erro = erro;
			this.dialog = true;
		},
		async run(endpoint){
			this.dialogLoading = true;
			const { authorization } = JSON.parse(this.erro.headers);
			try {
				const { data } = await axios({
					baseURL: endpoint,
					url: this.erro.url,
					method: this.erro.method,
					headers: {
						...(authorization ? { Authorization: authorization } : {}),
						debug: true
					},
					data: JSON.parse(this.erro.body)
				});
				this.response = JSON.stringify(data, null, 4);
			}
			catch(err){
				this.response = err.response ? JSON.stringify(err.response.data, null, 4) : err.stack;
			}
			this.responseDialog = true;
			this.dialogLoading = false;
		}
	},
	watch: {
		options(v) {
			this.carregar(v.page);
		}
	}
};
</script>