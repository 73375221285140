<template>
	<v-combobox
		:items="sugestoes"
		item-text="label"
		:search-input="qs"
		@update:search-input="onQs"
		return-object
		outlined
		hide-details
		dense
		label="Endereço"
		@change="onChange"
	></v-combobox>
</template>

<script>
import axios from 'axios';

const LISTA_DE_ESTADOS = [
	{ sigla: 'AC', cod: '12', nome: 'Acre' },
	{ sigla: 'AL', cod: '27', nome: 'Alagoas' },
	{ sigla: 'AP', cod: '16', nome: 'Amapá' },
	{ sigla: 'AM', cod: '13', nome: 'Amazonas' },
	{ sigla: 'BA', cod: '29', nome: 'Bahia' },
	{ sigla: 'CE', cod: '23', nome: 'Ceará' },
	{ sigla: 'DF', cod: '53', nome: 'Distrito Federal' },
	{ sigla: 'ES', cod: '32', nome: 'Espírito Santo' },
	{ sigla: 'GO', cod: '52', nome: 'Goiás' },
	{ sigla: 'MA', cod: '21', nome: 'Maranhão' },
	{ sigla: 'MT', cod: '51', nome: 'Mato Grosso' },
	{ sigla: 'MS', cod: '50', nome: 'Mato Grosso do Sul' },
	{ sigla: 'MG', cod: '31', nome: 'Minas Gerais' },
	{ sigla: 'PA', cod: '15', nome: 'Pará' },
	{ sigla: 'PB', cod: '25', nome: 'Paraíba' },
	{ sigla: 'PR', cod: '41', nome: 'Paraná' },
	{ sigla: 'PE', cod: '26', nome: 'Pernambuco' },
	{ sigla: 'PI', cod: '22', nome: 'Piauí' },
	{ sigla: 'RJ', cod: '33', nome: 'Rio de Janeiro' },
	{ sigla: 'RN', cod: '24', nome: 'Rio Grande do Norte' },
	{ sigla: 'RS', cod: '43', nome: 'Rio Grande do Sul' },
	{ sigla: 'RO', cod: '11', nome: 'Rondônia' },
	{ sigla: 'RR', cod: '14', nome: 'Roraima' },
	{ sigla: 'SC', cod: '42', nome: 'Santa Catarina' },
	{ sigla: 'SP', cod: '35', nome: 'São Paulo' },
	{ sigla: 'SE', cod: '28', nome: 'Sergipe' },
	{ sigla: 'TO', cod: '17', nome: 'Tocantins' }
];

export default {
	props: {
		value: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			qs: '',
			sugestoes: [],
		};
	},
	methods: {
		onQs(v) {
			if (v != null) {
				this.qs = v;
			}
		},
		async onChange(suggestion) {
			if (suggestion.address) {
				const uf = LISTA_DE_ESTADOS.find(estado => estado.nome == suggestion.address.state);
				const endereco = {
					cep: suggestion.address.postalCode,
					uf: uf.sigla,
					localidade: suggestion.address.city,
					bairro: suggestion.address.district,
					logradouro: suggestion.address.street
				};
				if (endereco.cep) {
					let viacep = null;
					try {
						viacep = (await axios.get(`https://viacep.com.br/ws/${endereco.cep.replace(/[^\d]/g, '')}/json/`)).data;
					}
					catch (err) { }
					if (viacep && !viacep.erro) {
						endereco.ibge = viacep.ibge;
						endereco.uf = viacep.uf;
					}
				}
				this.$emit('endereco', endereco);
			}
		},
		async carregarSugestoes() {
			let position = { lat: 0, lng: 0, accuracy: 0 };
			try {
				position = await this.getPosition();
			}
			catch (err) { }
			const result = await this.$utilsService.searchAddress(this.qs, `${position.lat},${position.lng}`);
			this.sugestoes = result.suggestions;
		},
		getPosition() {
			return new Promise((resolve, reject) => {
				if (navigator.geolocation) {
					navigator.geolocation.getCurrentPosition(function (position) {
						var pos = {
							lat: position.coords.latitude,
							lng: position.coords.longitude,
							accuracy: position.coords.accuracy
						};
						resolve(pos);
					}, function () {
						reject();
					});
				} else {
					reject();
				}
			});
		}
	},
	watch: {
		qs(v) {
			if (v != null) {
				this.carregarSugestoes();
				if (this.value != v) {
					this.$emit('input', v);
				}
			}
		},
		value(v) {
			if (v != null) {
				this.qs = v;
			}
		}
	}
};
</script>