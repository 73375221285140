<template>
	<v-form ref="form">
		<fieldset class="vg-fieldset">
			<legend>Informações gerais</legend>
			<v-text-field :rules="[rules.required]" label="Nome" v-model="value.nome"></v-text-field>
			<v-text-field :rules="[rules.required]" label="Descrição" v-model="value.descricao"></v-text-field>
			<v-text-field :rules="[rules.required]" label="Valor" type="number" min="0" step="0.01" v-model="value.valor"></v-text-field>
			<v-text-field :rules="[rules.required]" label="Limite de sublojas" type="number" min="0" step="1" v-model="value.limiteLojas"></v-text-field>
		</fieldset>
		<fieldset class="vg-fieldset">
			<legend>Tipos de pagamento acessíveis</legend>
			<v-row justify-space-around>
				<v-checkbox class="mx-2" v-model="value.tiposPagamento" v-for="(formaPagamento, i) of formasPagamento" :key="i" :label="formaPagamento" :value="formaPagamento"></v-checkbox>
			</v-row>
		</fieldset>
		<template v-for="(permissao, i) in permissoes">
			<fieldset class="vg-fieldset" :key="i">
				<legend>Permissões do {{permissao.nome}}</legend>
				<v-chip-group column multiple active-class="primary" v-model="value[permissao.model]">
					<v-chip v-for="(route, j) in routes" :key="j" :value="route">{{route}}</v-chip>
				</v-chip-group>
			</fieldset>
		</template>
	</v-form>
</template>

<script>
import rules from '../config/rules';

export default {
	props: {
		value: {
			type: Object,
			default(){
				return {};
			}
		}
	},
	data(){
		return {
			formasPagamento: [],
			routes: [],
			rules,
			permissoes: [
				{ nome: 'Gerente', model: 'permissoesGerente'},
				{ nome: 'Atendente', model: 'permissoesAtendente'},
				{ nome: 'Portaria', model: 'permissoesPortaria'},
				{ nome: 'Supervisor', model: 'permissoesSupervisor'},
				{ nome: 'Proprietario', model: 'permissoesProprietario'}
			]
		};
	},
	async created(){
		this.formasPagamento = Object.values(this.$enum().TIPO_PAGAMENTO);
		this.routes = Object.values(this.$routes());
	},
	methods: {
		validate(){
			return this.$refs.form.validate();
		},
		reset(){
			this.$refs.form.reset();
		}
	},
	watch: {
		value: {
			deep: true,
			handler(){
				this.$emit('input', this.value);
			}
		}
	}
};
</script>