export default {
	ESTABELECIMENTOS_ATUALIZADOS: 'estabelecimentos_atualizados',
	USUARIO_LOGADO: 'usuario_logado',
	USUARIO_DESLOGADO: 'usuario_deslogado',
	REFRESH: 'refresh',
	DESLOGAR: 'deslogar',
	INIT_SOCKET: 'init_socket',
	SCRIPT_LOG: 'script_log',
	SCRIPT_ERROR: 'script_error',
	SCRIPT_END: 'script_end',
	LIMPAR_ESTABELECIMENTOS: 'limpar_estabelecimentos',
	QRCODE_LIDO: 'qrcode_lido',
	NOTIFICATION: 'notification',
	ATUALIZAR_ESTABELECIMENTOS_LIST: 'atualizar_estabelecimentos_list',
	IMPRIMIR: 'imprimir',
	EXPORT_EXCEL: "export_excel",
	SHOW_PRINT_BUTTON: 'show_print_button',
	SHOW_EXCEL_BUTTON: 'show_excel_button'
};