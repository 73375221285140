<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12" sm="5" md="4" lg="3" xl="2">
				<v-select class="mb-5" v-model="arteId" :items="artes" item-text="nomeFantasia" item-value="id" label="Arte" hide-details outlined dense></v-select>
			</v-col>
		</v-row>
		<Filtro :items="headers" @filtro="filtrar"></Filtro>
		<v-data-table class="mt-5" group-by="estabelecimento.nomeFantasia" :server-items-length="itemsLenght" :loading="loading" :items="qrcodesGerados" :headers="headers" :footer-props="{ itemsPerPageOptions: [25] }" :options.sync="options">
			<template v-slot:[`group.header`]="{ group, toggle }">
				<td colspan="11" style="cursor:pointer" @click="toggle">{{group != 'undefined' ? group : 'Sem estabelecimento'}}</td>
			</template>
			<template v-slot:[`item.endereco`]="{ item }">{{item.endereco | endereco}}</template>
			<template v-slot:[`item.dataUltimaCompra`]="{ item }">{{item.dataUltimaCompra | datetime}}</template>
			<template v-slot:[`item.dataPrimeiraLeitura`]="{ item }">{{item.dataPrimeiraLeitura | datetime}}</template>
			<template v-slot:[`item.frequenciaVisita`]="{ item }">{{item.frequenciaVisita}} {{item.frequenciaVisita == 1 ? 'dia' : 'dias'}}</template>
			<template v-slot:[`item.media`]="{ item }">{{item.media}} {{item.media == 1 ? 'dia' : 'dias'}}</template>
			<template v-slot:[`item.rotas`]="{ item }">{{getRotas(item.rotas)}}</template>
			<template v-slot:[`item.qrcodeEntregue`]="{ item }">
				<v-switch @change="marcarComoEntregue($event, item.id)" v-model="item.qrcodeEntregue"></v-switch>
			</template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-btn icon @click="openEditDialog(item)" :disabled="!arteId">
					<v-icon>mdi-pencil</v-icon>
				</v-btn>
				<v-btn icon @click="refresh(item.qrcode, item.nome)" :disabled="!arteId">
					<v-icon>mdi-eye</v-icon>
				</v-btn>
				<v-btn icon @click="openRemoveDialog(item)">
					<v-icon>mdi-delete</v-icon>
				</v-btn>
			</template>
		</v-data-table>
		<div style="height: 80px"></div>
		<v-speed-dial v-model="fab" bottom right fixed v-if="arteId">
			<template v-slot:activator>
				<v-btn color="primary" fab dark v-model="fab">
					<v-icon>{{ fab ? 'mdi-close' : 'mdi-dots-vertical' }}</v-icon>
				</v-btn>
			</template>
			<v-tooltip left>
				<template v-slot:activator="{ on, attrs }">
					<v-btn v-on="on" v-bind="attrs" fab color="primary" dark @click="refresh()">
						<v-icon>mdi-qrcode-edit</v-icon>
					</v-btn>
				</template>
				<span>Gerar QR code</span>
			</v-tooltip>
			<v-tooltip left>
				<template v-slot:activator="{ on, attrs }">
					<v-btn v-on="on" v-bind="attrs" fab color="primary" dark @click="downloadPopup = true">
						<v-icon>mdi-download-multiple</v-icon>
					</v-btn>
				</template>
				<span>Baixar múltiplos QR codes</span>
			</v-tooltip>
		</v-speed-dial>
		<v-dialog persistent v-model="downloadPopup" width="300">
			<v-card :loading="downloadLoading">
				<v-toolbar dense flat>
					<span>Baixar múltiplos QR codes</span>
					<v-spacer></v-spacer>
					<v-icon @click="downloadPopup = false">mdi-close</v-icon>
				</v-toolbar>
				<v-divider></v-divider>
				<v-card-text>
					<v-form>
						<v-text-field hide-details :disabled="downloadLoading" v-model="quantidadeQrcodes" label="quantidade" type="number" min="1" max="5000" step="1"></v-text-field>
					</v-form>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn color="secondary" :disabled="downloadLoading" @click="gerarMultiplosQrcodes">Baixar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="clientePopup" width="300" persistent>
			<v-card :loading="loadingPopupCliente">
				<v-toolbar dense flat>
					<span>Associar a um cliente</span>
				</v-toolbar>
				<v-divider></v-divider>
				<v-card-text>
					<v-form ref="clienteForm">
						<v-autocomplete @change="selecionarCliente" no-data-text="Digite para buscar um cliente" :items="clientes" item-value="id" item-text="nome" :search-input.sync="qsCliente" v-model="clienteId" label="Selecionar cliente"></v-autocomplete>
						<v-text-field :disabled="!clienteId" label="Frequência das leituras" v-model="frequenciaVisita" type="number" step="1"></v-text-field>
						<fieldset class="vg-fieldset">
							<legend>Rotas</legend>
							<v-chip-group column :disabled="!clienteId">
								<v-chip @click:close="removeRota(rota.id)" close v-for="rota in rotasChip" :key="rota.id">{{rota.nome}}</v-chip>
							</v-chip-group>
							<v-autocomplete ref="autRota" :disabled="!clienteId" @change="addRota" no-data-text="Digite para buscar uma rota" :items="rotasSearch" item-value="id" item-text="nome" :search-input.sync="qsRota" v-model="rotaId" label="Adicionar rota"></v-autocomplete>
						</fieldset>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-btn color="secondary" @click="associarCliente">Associar</v-btn>
					<v-btn color="error" @click="fecharClientePopup">Cancelar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="qrcodePopup" width="600" persistent>
			<v-card>
				<v-toolbar dense flat>
					<span>{{nomeCliente ? `QR code de ${nomeCliente}` : 'Novo QR code'}}</span>
					<v-spacer></v-spacer>
					<v-icon @click="qrcodePopup = false">mdi-close</v-icon>
				</v-toolbar>
				<v-divider></v-divider>
				<v-card-text class="text-center pa-3">
					<img :src="qrcodeUrl" style="max-width: 100%">
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon @click="refresh()" v-on="on" v-bind="attrs">
								<v-icon>mdi-refresh</v-icon>
							</v-btn>
						</template>
						<span>Atualizar QR code</span>
					</v-tooltip>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon @click="download" v-on="on" v-bind="attrs">
								<v-icon>mdi-download</v-icon>
							</v-btn>
						</template>
						<span>Baixar QR code</span>
					</v-tooltip>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon @click="clientePopup = true" v-if="$can('clientes:atualizar')" :disabled="!!nomeCliente" v-on="on" v-bind="attrs">
								<v-icon>mdi-face</v-icon>
							</v-btn>
						</template>
						<span>Associar cliente</span>
					</v-tooltip>
					<v-spacer></v-spacer>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog width="300" v-model="removeDialog" persistent>
			<v-card :loading="removeLoading">
				<v-container>Deseja remover este qrcode?</v-container>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn color="secondary" @click="remover">Sim</v-btn>
					<v-btn color="error" @click="removeDialog = false">Não</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog width="600" v-model="dialog" persistent scrollable>
			<v-card :loading="dialogLoading">
				<v-toolbar flat>
					<span>{{ obj.id ? "Editar" : "Adicionar" }} Cliente</span>
					<v-spacer></v-spacer>
					<v-icon @click="dialog = false">mdi-close</v-icon>
				</v-toolbar>
				<v-divider></v-divider>
				<v-card-text>
					<Cliente v-model="obj" ref="form"></Cliente>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn color="secondary" @click="salvar">Salvar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import {v4 as uuidv4} from 'uuid';
import eventBus from '../config/eventBus';
import events from '../config/events';
import JSZip from 'jszip';
import Filtro from '../components/Filtro';
import qrRender from '../config/qrRender';
import Cliente from '../components/Cliente.vue';

export default {
	components: {
		Filtro,
		Cliente
	},
	data() {
		return {
			code: '',
			clientePopup: false,
			clientes: [],
			qsCliente: '',
			clienteId: null,
			loadingClientes: false,
			loadingPopupCliente: false,
			qrcodesGerados: [],
			removeDialog: false,
			dialog: false,
			dialogLoading: false,
			fab: false,
			qrcodeUrl: null,
			removeLoading: false,
			obj: {},
			headers: [
				{ text: 'Nome', value: 'nome', sortable: false, filtro: 'text' },
				{ text: 'Endereco', value: 'endereco', sortable: false },
				{ text: 'Telefone', value: 'telefonePrincipal', sortable: false },
				{ text: 'Data de Cadastro', value: 'dataPrimeiraLeitura', sortable: false },
				{ text: 'Valor da última compra', value: 'valorUltimaCompra', sortable: false },
				{ text: 'Data da última compra', value: 'dataUltimaCompra', sortable: false },
				{ text: 'Frequência das leituras', value: 'frequenciaVisita', sortable: false },
				{ text: 'Frequência das compras', value: 'media', sortable: false },
				{ text: 'Rotas', value: 'rotas', sortable: false, filtro: 'autocomplete', action: this.$rotasService.find, field: 'nome' },
				{ text: 'Qrcode Entregue', value: 'qrcodeEntregue', sortable: false },
				{ text: '', value: 'actions', sortable: false, align: 'right', width: 148 }
			],
			itemsLenght: 0,
			loading: false,
			options: {},
			qrcodePopup: false,
			nomeCliente: null,
			rotas: [],
			frequenciaVisita: 0,
			cliente: {
				rotas: []
			},
			qsRota: '',
			rotasSearch: [],
			loadingRota: false,
			rotaId: null,
			quantidadeQrcodes: 1,
			downloadPopup: false,
			downloadLoading: false,
			where: {},
			estabelecimento: null,
			artes: [],
			arteId: null
		};
	},
	async created() {
		this.carregarQrcodesGerados();
		eventBus.$on(events.REFRESH, this.carregarQrcodesGerados);
		eventBus.$on(events.ESTABELECIMENTOS_ATUALIZADOS, this.carregarQrcodesGerados);
	},
	destroyed(){
		eventBus.$off(events.REFRESH, this.carregarQrcodesGerados);
		eventBus.$off(events.ESTABELECIMENTOS_ATUALIZADOS, this.carregarQrcodesGerados);
	},
	methods: {
		async carregarArtes() {
			try {
				const result = await this.$estabelecimentosService.find(1, {
					in_id: this.$estabelecimentos().map(x => x.id).join(',') || null
				});
				this.artes = result.docs;
			}
			catch(err) {
				console.log(err);
			}
			this.loading = false;
		},
		filtrar(values) {
			this.where = {
				...(values.nome ? { iLike_nome: `%${values.nome}%` } : {}),
				...(values.rotas ? { contains_rotas: values.rotas } : {})
			};
			this.carregarQrcodesGerados(1);
		},
		async marcarComoEntregue(entregue, clienteId) {
			this.loading = true;
			try {
				await this.$clientesService.update(clienteId, {
					qrcodeEntregue: entregue
				});
			}
			catch(err) {
				console.log(err);
			}
			this.loading = false;
		},
		async remover() {
			this.obj.qrcode = null;
			this.obj.rotas = [];
			this.loading = true;
			this.removeDialog = false;
			try {
				await this.$clientesService.update(this.obj.id, this.obj);
				this.carregarQrcodesGerados(this.page);
				this.loading = false;
			}
			catch(err) {
				this.loading = false;
				console.log(err);
			}
		},
		removeRota(id) {
			this.cliente.rotas = this.cliente.rotas.filter(r => r != id);
		},
		addRota(id){
			const rota = this.rotasSearch.find(rota => rota.id == id);
			if(rota) {
				if(this.cliente.rotas.find(r => r == id)) {
					this.$alert('Rota já adicionada');
				}
				else {
					if(!this.rotas.find(r => r.id == id)) {
						this.rotas.push(rota);
					}
					this.cliente.rotas.push(id);
				}
				this.$refs.autRota.blur();
				this.$nextTick(() => {
					this.rotaId = null;
				});
			}
		},
		async salvar() {
			if (this.$refs.form.validate()) {
				this.dialogLoading = true;
				try {
					await this.$clientesService.update(this.obj.id, this.obj);
					this.carregarQrcodesGerados(this.page);
				}
				catch (err) {
					console.log(err);
				}
				this.dialog = false;
				this.dialogLoading = false;
			}
		},
		async carregarRotas() {
			this.loadingRota = true;
			try {
				this.rotasSearch = (await this.$rotasService.find(1, {
					...(this.qsRota ? { iLike_nome: `%${this.qsRota}%` } : {}),
					in_estabelecimentoId: this.$estabelecimentos().map(x => x.id).join(',') || null
				})).docs;
			}
			catch(err){
				console.log(err);
			}
			this.loadingRota = false;
		},
		selecionarCliente(id){
			this.cliente = this.clientes.find(c => c.id == id);
			this.frequenciaVisita = this.cliente.frequenciaVisita;
		},
		getRotas(ids = []) {
			return ids.map(id => {
				const rotaEncontrada = this.rotas.find(rota => rota.id == id);
				return rotaEncontrada ? rotaEncontrada.nome : '[REMOVIDA]';
			}).join();
		},
		async gerarMultiplosQrcodes(){
			this.downloadLoading = true;
			const zip = new JSZip();
			for(let i = 0; i < this.quantidadeQrcodes; i++) {
				const code = uuidv4();
				const url = (await this.render(code)).replace(/^data:image\/png;base64,/, '');
				const fileName = `${code}.png`;
				zip.file(fileName, url, { base64: true });
			}
			const blob = await zip.generateAsync({type: 'blob'});
			const a = document.createElement('a');
			const url = URL.createObjectURL(blob);
			a.href = url;
			a.download = 'qrcodes.zip';
			a.click();
			URL.revokeObjectURL(url);
			this.downloadPopup = false;
			this.downloadLoading = false;

			// Atualiza o número atual do QrCode
			const arte = this.artes.find(a => a.id == this.arteId);
			await this.$estabelecimentosService.update(arte.id, { numeroAtual: arte.numeroAtual });
		},
		async refresh(code = null, nomeCliente = null) {
			this.code = code || uuidv4();
			this.qrcodePopup = true;
			this.qrcodeUrl = await this.render(this.code);
			if(nomeCliente) {
				this.nomeCliente = nomeCliente;
			}
			else {
				this.nomeCliente = null;
			}
		},
		async render(code){
			const arte = this.artes.find(a => a.id == this.arteId);
			if (arte.numerar && !isNaN(arte.numeroAtual)) arte.numeroAtual += 1
			return await qrRender({
				img: `${this.$store.state.endpoint}/uploads/${arte.image}`,
				logoImg: `${this.$store.state.endpoint}/uploads/${arte.logoImage}`,
				code,
				...arte
			});
		},
		download() {
			const link = document.createElement('a');
			link.download = `${this.nomeCliente || this.code}.png`;
			link.href = this.qrcodeUrl;
			link.click();
		},
		fecharClientePopup() {
			this.clientePopup = false;
			this.$refs.clienteForm.reset();
			this.clienteId = null;
			this.cliente = {rotas: []};
			this.frequenciaVisita = 0;
		},
		resetForm() {
			if (this.$refs.form) {
				this.$refs.form.reset();
			}
		},
		openEditDialog(obj) {
			obj.endereco = obj.endereco || {};
			this.resetForm();
			this.$nextTick(() => {
				this.obj = JSON.parse(JSON.stringify(obj));
				this.dialog = true;
			});
		},
		async carregarClientes(){
			this.loadingClientes = true;
			try {
				this.clientes = (await this.$clientesService.find(1, {
					...(this.qsCliente ? { iLike_nome: `%${this.qsCliente}%` } : {}),
					dataPrimeiraLeitura: true
				})).docs;
			}
			catch(err){
				console.log(err);
			}
			this.loadingClientes = false;
		},
		async associarCliente(){
			const cliente = this.clientes.find(c => c.id == this.clienteId);
			cliente.qrcode = this.code;
			cliente.frequenciaVisita = this.frequenciaVisita;
			cliente.rotas = this.cliente.rotas;
			cliente.qrcodeEntregue = false;
			this.loadingPopupCliente = true;
			try {
				await this.$clientesService.update(this.clienteId, cliente);
				this.fecharClientePopup();
				this.clienteId = null;
				this.carregarQrcodesGerados(1);
				this.qrcodePopup = false;
			}
			catch(err) {
				console.log(err);
			}
			this.loadingPopupCliente = false;
		},
		async carregarQrcodesGerados(page = 1){
			this.loading = true;
			try {
				await this.carregarArtes();
				const result = await this.$clientesService.find(page, {
					in_estabelecimentoId: this.$estabelecimentos().map(x => x.id).join(',') || null,
					not_qrcode: 'null',
					dataPrimeiraLeitura: true,
					...this.where
				});
				this.qrcodesGerados = result.docs;
				const rotasIds = this.qrcodesGerados.reduce((acc, item) => {
					for(const rotaId of item.rotas) {
						if(!acc.includes(rotaId)) {
							acc.push(rotaId);
						}
					}
					return acc;
				}, []);
				this.rotas = (await this.$rotasService.find(1, {
					paginate: 1000,
					...(rotasIds.length ? { in_id: rotasIds.join() } : {})
				})).docs;
				this.options.page = result.page;
				this.itemsLenght = result.total;
			}
			catch(err) {
				console.log(err);
			}
			this.loading = false;
		},
		openRemoveDialog(obj){
			this.obj = JSON.parse(JSON.stringify(obj));
			this.removeDialog = true;
		}
	},
	watch: {
		qsCliente(v) {
			if(v) {
				this.carregarClientes();
			}
		},
		options(v) {
			this.carregarQrcodesGerados(v.page);
		},
		qsRota(){
			this.carregarRotas();
		}
	},
	computed: {
		rotasChip() {
			return this.cliente.rotas.map(id => {
				return this.rotas.find(rota => rota.id == id) || { id, nome: '[REMOVIDA]' };
			});
		}
	}
};
</script>