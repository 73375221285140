<template>
	<v-form ref="form">
		<v-select label="Estabelecimento" :rules="[rules.required]" v-model="value.estabelecimentoId" :items="$estabelecimentos(this.value.estabelecimento ? JSON.parse(JSON.stringify(this.value.estabelecimento)) : null)" item-text="nomeFantasia" item-value="id"></v-select>
		<v-text-field label="Nome" v-model="value.nome" :rules="[rules.required]"></v-text-field>
		<v-text-field label="Placa" v-model="value.placa"></v-text-field>
		<v-text-field label="Marca" v-model="value.marca"></v-text-field>
		<v-text-field label="Cidade" v-model="value.cidade"></v-text-field>
		<v-text-field label="KM inicial" v-model="value.kmInicial" type="number" min="0"></v-text-field>
		<v-select label="Tipo" v-model="value.tipo" :items="tiposVeiculo"></v-select>
		<v-select label="Situação" v-model="value.situacao" :items="situacoesVeiculo"></v-select>
		<v-switch label="Veículo Próprio" v-model="value.proprio"></v-switch>
	</v-form>
</template>

<script>
import rules from '../config/rules';

export default {
	props: {
		value: {
			type: Object,
			default(){
				return {};
			}
		}
	},
	data(){
		return {
			rules,
			tiposVeiculo: [],
			situacoesVeiculo: []
		};
	},
	created(){
		this.tiposVeiculo = Object.values(this.$enum().TIPO_VEICULO);
		this.situacoesVeiculo = Object.values(this.$enum().SITUACAO_VEICULO);
	},
	methods: {
		validate(){
			return this.$refs.form.validate();
		},
		reset(){
			this.$refs.form.reset();
		}
	}
};
</script>