import VueRouter from 'vue-router';
import Vue from 'vue';
import Teleatendimento from '../pages/Teleatendimento.vue';
import Login from '../pages/Login.vue';
import Planos from '../pages/Planos.vue';
import Estabelecimentos from '../pages/Estabelecimentos.vue';
import Usuarios from '../pages/Usuarios.vue';
import Produtos from '../pages/Produtos.vue';
import Pedidos from '../pages/Pedidos.vue';
import Clientes from '../pages/Clientes.vue';
import Veiculos from '../pages/Veiculos.vue';
import Entregadores from '../pages/Entregadores.vue';
import ValoresNegociado from '../pages/ValoresNegociado.vue';
import Erros from '../pages/Erros.vue';
import Scripts from '../pages/Scripts';
import TaxasTransacao from '../pages/TaxasTransacao.vue';
import PedidoPortaria from '../pages/PedidoPortaria.vue';
import Administracao from '../pages/Administracao.vue';
import GeradorQrcode from '../pages/GeradorQrcode';
import QRCodes from '../pages/QRCodes.vue';
import Home from '../pages/Home.vue';
import ArteQrcode from '../pages/ArteQrcode.vue';
import ErrosSincronizacao from '../pages/ErrosSincronizacao.vue';
import ErrosSincronizacaoVN from '../pages/ErrosSincronizacaoVN.vue';
import Rotas from '../pages/Rotas.vue';
import Mapleads from '../pages/Mapleads';
import RelatorioQrcodesLidos from '../pages/RelatorioQrcodesLidos.vue';

Vue.use(VueRouter);

export default new VueRouter({
	routes: [
		{ path: '/', component: Home, name: 'Home' },
		{ path: '/teleatendimento', component: Teleatendimento, name: 'Teleatendimento' },
		{ path: '/login', component: Login, name: 'Login' },
		{ path: '/planos', component: Planos, name: 'Planos' },
		{ path: '/estabelecimentos', component: Estabelecimentos, name: 'Estabelecimentos' },
		{ path: '/usuarios', component: Usuarios, name: 'Usuários' },
		{ path: '/produtos', component: Produtos, name: 'Produtos' },
		{ path: '/pedidos', component: Pedidos, name: 'Pedidos' },
		{ path: '/clientes', component: Clientes, name: 'Clientes' },
		{ path: '/veiculos', component: Veiculos, name: 'Veículos' },
		{ path: '/entregadores', component: Entregadores, name: 'Entregadores' },
		{ path: '/valoresNegociado', component: ValoresNegociado, name: 'Valores Negociado' },
		{ path: '/erros', component: Erros, name: 'Erros' },
		{ path: '/scripts', component: Scripts, name: 'Scripts' },
		{ path: '/taxasTransacao', component: TaxasTransacao, name: 'Taxas de Transação' },
		{ path: '/pedidoPortaria', component: PedidoPortaria, name: 'Pedido Portaria' },
		{ path: '/administracao', component: Administracao, name: 'PAINEL DE MONITORAMENTO' },
		{ path: '/geradorqrcode', component: GeradorQrcode, name: 'Gerador de QR code' },
		{ path: '/qrcodes', component: QRCodes, name: 'QR codes' },
		{ path: '/arteqrcode', component: ArteQrcode, name: 'Arte do Qrcode' },
		{ path: '/errosSincronizacao', component: ErrosSincronizacao, name: 'Erros de Sincronização de Pedidos' },
		{ path: '/errosSincronizacaoVN', component: ErrosSincronizacaoVN, name: 'Erros de Sincronização de Valores Negociados' },
		{ path: '/rotas', component: Rotas, name: 'Rotas' },
		{ path: '/mapleads', component: Mapleads, name: 'Mapleads' },
		{ path: '/relatorioQrcodesLidos', component: RelatorioQrcodesLidos, name: 'Qrcodes Lidos' }
	]
});