<template>
	<v-expansion-panels class="mb-3">
		<v-expansion-panel>
			<v-expansion-panel-header>Buscar</v-expansion-panel-header>
			<v-divider></v-divider>
			<v-expansion-panel-content>
				<v-form>
					<v-row>
						<template v-for="(item, i) in items">
							<template v-if="item.filtro">
								<template v-if="item.filtro == 'date'">
									<v-col cols="12" sm="6" md="4" lg="3" xl="2" :key="`start${i}`">
										<Datepicker hide-details outlined dense v-model="filtro[item.value].start" :label="`${item.text} (início)`" @input="dateInput"></Datepicker>
									</v-col>
									<v-col cols="12" sm="6" md="4" lg="3" xl="2" :key="`end${i}`">
										<Datepicker hide-details outlined dense v-model="filtro[item.value].end" :label="`${item.text} (fim)`" @input="dateInput"></Datepicker>
									</v-col>
								</template>
								<v-col v-else cols="12" sm="6" md="4" lg="3" xl="2" :key="i">
									<v-text-field v-if="item.filtro == 'text'" dense outlined hide-details :label="item.text" v-model="filtro[item.value]"></v-text-field>
									<v-select clearable v-if="item.filtro == 'select'" :items="item.options" dense outlined hide-details :label="item.text" v-model="filtro[item.value]"></v-select>
									<v-checkbox indeterminate v-if="item.filtro == 'boolean'" dense hide-details :label="item.text" v-model="filtro[item.value]"></v-checkbox>
									<v-autocomplete clearable @keydown="update(item.value)" v-if="item.filtro == 'autocomplete'" :items="itens[item.value]" :search-input.sync="qs[item.value]" dense outlined hide-details :label="item.text" v-model="filtro[item.value]" :item-text="item.field" item-value="id"></v-autocomplete>
								</v-col>
							</template>
						</template>
					</v-row>
				</v-form>
				<v-btn color="secondary" @click="filtrar">
					<v-icon>mdi-magnify</v-icon>
				</v-btn>
				<v-btn color="secondary" @click="limpar">
					<v-icon>mdi-eraser</v-icon>
				</v-btn>
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
</template>
<script>
import Datepicker from './Datepicker';

export default {
	components: {
		Datepicker
	},
	props: {
		items: {
			type: Array,
			default() {
				return [];
			}
		}
	},
	data(){
		return {
			field: '',
			searchCount: 0,
			filtro: {},
			qs: {},
			itens: {}
		};
	},
	created(){
		this.limpar();
	},
	methods: {
		update(value) {
			this.field = value;
			this.searchCount++;
		},
		limpar(){
			this.filtro = {};
			for( const item of this.items) {
				if(item.filtro == 'date') {
					this.filtro[item.value] = {};
				}
				else if(item.filtro == 'autocomplete') {
					this.itens[item.value] = [];
					this.qs[item.value] = '';
					this.populateItems(item.value);
				}
			}
		},
		filtrar(){
			const _filtro = JSON.parse(JSON.stringify(this.filtro));
			this.$emit('filtro', _filtro);
		},
		dateInput(){
			this.$forceUpdate();
		},
		async populateItems(field) {
			const item = this.items.find(i => i.value == field);
			this.itens[field] = (await item.action(1, {
				...(this.qs[field] ? { [`iLike_${item.field}`]: `%${this.qs[field]}%` } : {}),
				in_estabelecimentoId: this.$estabelecimentos().map(x => x.id).join(',') || null
			})).docs;
			this.$forceUpdate();
		}
	},
	watch: {
		searchCount() {
			this.populateItems(this.field);
		}
	}
};
</script>