import Vuex from 'vuex';
import Vue from 'vue';
import axios from 'axios';
import eventBus from '../config/eventBus';
import events from '../config/events';

Vue.use(Vuex);

// eslint-disable-next-line no-undef
// const endpoint = 'https://search.vendergas.com.br:8080';
const endpoint = process.env.NODE_ENV == 'development' ? 'http://localhost:8080' : 'https://search.vendergas.com.br:8080';

export default new Vuex.Store({
	state: {
		error: false,
		errorMessage: '',
		success: false,
		successMessage: '',
		info: false,
		infoMessage: '',
		loginData: localStorage.loginData ? JSON.parse(localStorage.loginData) : null,
		estabelecimentos: localStorage.estabelecimentos ? JSON.parse(localStorage.estabelecimentos) : [],
		enum: {},
		routes: [],
		endpoint,
		loading: false,
		axios: localStorage.loginData ? axios.create({
			baseURL: endpoint,
			headers: { Authorization: JSON.parse(localStorage.loginData).token }
		}) : axios.create({ baseURL: endpoint })
	},
	mutations: {
		loginData(state, value) {
			localStorage.clear();
			state.estabelecimentos = [];
			eventBus.$emit(events.LIMPAR_ESTABELECIMENTOS);
			if (value) {
				localStorage.loginData = JSON.stringify(value);
				state.loginData = value;
				state.axios = axios.create({
					baseURL: endpoint,
					headers: { Authorization: value.token }
				});
				eventBus.$emit(events.USUARIO_LOGADO);
			}
			else {
				state.loginData = null;
				state.axios = axios.create({ baseURL: endpoint });
				eventBus.$emit(events.USUARIO_DESLOGADO);
			}
		},
		error(state, value) {
			if (value) {
				state.error = true;
				state.errorMessage = value;
			}
			else {
				state.error = false;
				state.errorMessage = '';
			}
		},
		success(state, value) {
			if (value) {
				state.success = true;
				state.successMessage = value;
			}
			else {
				state.success = false;
				state.successMessage = '';
			}
		},
		info(state, value) {
			if (value) {
				state.info = true;
				state.infoMessage = value;
			}
			else {
				state.info = false;
				state.infoMessage = '';
			}
		},
		estabelecimentos(state, value) {
			state.estabelecimentos = value;
			localStorage.estabelecimentos = JSON.stringify(value);
		},
		enum(state, value) {
			state.enum = value;
		},
		routes(state, value) {
			state.routes = value;
		},
		usuario(state, value) {
			state.loginData.usuario = value;
			localStorage.loginData = JSON.stringify(state.loginData);
		},
		loading(state, value) {
			state.loading = value;
		}
	}
});