<template>
	<v-app :style="{ background: $token() ? '#f2f2f2' : 'radial-gradient(ellipse at center,#46a39b 0,#4a609b 100%) !important' }">
		<audio src="~/assets/audio/error.mp3" ref="errorSound" loop></audio>
		<v-app-bar clipped-right v-if="$token() && !$route.query.token" color="primary" app dark>
			<v-app-bar-nav-icon v-if="$admin() || config.qrcode" @click="menuDrawer = !menuDrawer"></v-app-bar-nav-icon>
			<v-btn v-if="$admin() || config.qrcode" icon @click="refresh">
				<v-icon>mdi-refresh</v-icon>
			</v-btn>
			<v-toolbar-title>{{this.$vuetify.breakpoint.mdAndUp || this.$route.name != 'Teleatendimento' ? $route.name : 'App Venda'}}</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-chip-group show-arrows width="100%" v-if="$vuetify.breakpoint.mdAndUp">
				<v-chip v-for="estabelecimento in estabelecimentosSelecionados" close color="secondary" :key="estabelecimento.id" @click:close="removerSelecao(estabelecimento)">{{estabelecimento.nomeFantasia}}</v-chip>
			</v-chip-group>
			<v-btn icon @click="estabelecimentosDrawer = !estabelecimentosDrawer" v-if="$can('estabelecimentos:listar')">
				<v-icon>mdi-domain-plus</v-icon>
			</v-btn>
			<v-btn icon style="margin-right: 5px;" @click="imprimir" v-if="showPrintButton">
				<v-icon>mdi-printer</v-icon>
			</v-btn>
			<v-btn icon style="margin-right: 5px;" @click="exportExcel" v-if="showExcelButton">
				<v-icon>mdi-file-excel</v-icon>
			</v-btn>
			<v-menu bottom :close-on-content-click="false">
				<template v-slot:activator="{on}">
					<v-btn fab small color="secondary" v-on="on">
						<span>{{$usuario().nome | iniciais}}</span>
					</v-btn>
				</template>
				<v-card>
					<v-list dense nav>
						<v-list-item>
							<v-list-item-avatar>
								<v-avatar color="secondary" class="white--text">
									<span>{{$usuario().nome | iniciais}}</span>
								</v-avatar>
							</v-list-item-avatar>
							<v-list-item-content>
								<v-list-item-title>{{$usuario().nome}}</v-list-item-title>
								<v-list-item-subtitle>{{$usuario().email}}</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
						<v-divider></v-divider>
						<v-list-item @click="logout">
							<v-list-item-icon>
								<v-icon>mdi-power</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>Sair</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-card>
			</v-menu>
		</v-app-bar>
		<v-navigation-drawer v-if="$token() && !$route.query.token" app v-model="menuDrawer" temporary>
			<v-list dense nav>
				<template v-for="(item, i) in menu">
					<v-list-item :key="i" :to="item.route" v-if="$can(item.permission) && !item.hide">
						<v-list-item-icon>
							<v-icon>{{item.icon}}</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>{{item.label}}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-divider v-if="item.divider" :key="`divider${i}`"></v-divider>
				</template>
			</v-list>
		</v-navigation-drawer>
		<v-content>
			<router-view v-if="$enum().TIPO_ESTOQUE && !$route.query.token"></router-view>
			<v-container fill-height v-else>
				<v-layout fill-height align-center justify-center>
					<v-progress-circular indeterminate :color="$token() ? 'primary' : 'white'"></v-progress-circular>
				</v-layout>
			</v-container>
		</v-content>
		<v-navigation-drawer v-if="$token() && !$route.query.token" app temporary right v-model="estabelecimentosDrawer">
			<template v-slot:prepend>
				<v-sheet color="primary" dark class="pa-3" tile>
					<v-text-field dense @input="carregarEstabelecimentos(1)" v-model="qs" hide-details solo-inverted single-line prepend-inner-icon="mdi-magnify" label="Buscar" flat></v-text-field>
				</v-sheet>
			</template>
			<v-sheet v-if="loading" class="pa-3" tile>
			<v-progress-linear indeterminate></v-progress-linear>
			</v-sheet>
			<v-list v-else>
				<v-list-item @click="toggleTodos()">
					<v-list-item-action>
						<v-checkbox readonly v-model="selecionarTodos"></v-checkbox>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>Selecionar todos</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-divider></v-divider>
				<template v-for="estabelecimento in estabelecimentos">
					<v-list-item :key="`item-` + estabelecimento.id" @click="selecionarEstabelecimento(estabelecimento)">
						<v-list-item-action>
							<v-checkbox readonly v-model="estabelecimento.selecionado"></v-checkbox>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>{{estabelecimento.nomeFantasia}}</v-list-item-title>
							<v-list-item-subtitle>{{estabelecimento.endereco | endereco}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</template>
			</v-list>
			<template v-slot:append v-if="pagesEstabelecimentos > 1">
				<v-sheet tile>
					<v-row no-gutters class="pa-2">
						<v-col>
							<v-btn text block :disabled="pageEstabelecimentos == 1 || loading" @click="carregarEstabelecimentos(pageEstabelecimentos - 1)">
								<v-icon>mdi-chevron-left</v-icon>
							</v-btn>
						</v-col>
						<v-col>
							<v-btn text block :disabled="pageEstabelecimentos == pagesEstabelecimentos || loading" @click="carregarEstabelecimentos(pageEstabelecimentos + 1)">
								<v-icon>mdi-chevron-right</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-sheet>
			</template>
		</v-navigation-drawer>
		<v-footer app :color="$dev ? 'red lighten-5' : 'blue lighten-5'"><v-spacer></v-spacer>&copy; {{config.qrcode ? 'QR Code App' : 'Vendergás'}} {{year}} | v{{packageJSON.version}}<v-spacer></v-spacer></v-footer>
		<v-dialog v-model="$store.state.error" width="300" persistent>
			<v-card>
				<v-alert type="error" tile class="ma-0" icon="mdi-alert-circle">
					{{$store.state.errorMessage}}
				</v-alert>
				<v-card-actions>
					<v-btn color="secondary" @click="$store.commit('error', null)">Fechar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="$store.state.success" width="300" persistent>
			<v-card>
				<v-alert type="success" tile class="ma-0">
					{{$store.state.successMessage}}
				</v-alert>
				<v-card-actions>
					<v-btn color="secondary" @click="$store.commit('success', null)">Fechar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="$store.state.info" width="300" persistent>
			<v-card>
				<v-alert type="info" tile class="ma-0">
					{{$store.state.infoMessage}}
				</v-alert>
				<v-card-actions>
					<v-btn color="secondary" @click="$store.commit('info', null)">Fechar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="notificationPopup" width="300" persistent>
			<v-card>
				<v-alert :type="notificationType" tile class="ma-0">
					{{notificationMessage}}
				</v-alert>
				<v-card-actions>
					<v-btn color="secondary" @click="closeNotification">Fechar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="$store.state.loading" width="300" persistent>
			<v-card>
				<v-container>
					<v-progress-linear indeterminate></v-progress-linear>
				</v-container>
			</v-card>
		</v-dialog>
	</v-app>
</template>

<script>
/* eslint-disable no-empty */
import eventBus from './config/eventBus';
import events from './config/events';
import config from './config/config';
import packageJSON from './package.json';

export default {
	data(){
		return  {
			estabelecimentos: [],
			estabelecimentosSelecionados: this.$estabelecimentos(),
			showExcelButton: null,
			loading: false,
			menuDrawer: false,
			estabelecimentosDrawer: false,
			selecionarTodos: false,
			pageEstabelecimentos: 1,
			pagesEstabelecimentos: 1,
			config,
			qs: '',
			menu: [],
			packageJSON,
			manifest: {},
			notificationPopup: false,
			notificationType: 'info',
			notificationMessage: '',
			showPrintButton: false
		};
	},
	async created(){
		const favicon = document.createElement('link');
		favicon.rel='icon';
		favicon.type='image/png';
		const manifest = document.createElement('link');
		manifest.rel = 'manifest';
		if(config.qrcode) {
			document.title = 'QR Code App';
			manifest.href = `${this.$store.state.endpoint}/utils/manifest?qrcode=true`;
			favicon.href = `${this.$store.state.endpoint}/icons-qrcode/icon-512x512.png`;
		}
		else {
			document.title = 'Teleatendimento Vendergás';
			manifest.href = `${this.$store.state.endpoint}/utils/manifest`;
			favicon.href = `${this.$store.state.endpoint}/icons-vendergas/icon-512x512.png`;
		}
		document.head.appendChild(manifest);
		document.head.appendChild(favicon);

		this.manifest = await this.$utilsService.manifest();
		const _enum = await this.$utilsService.enum();
		const routes = await this.$utilsService.routes();
		this.$store.commit('enum', _enum);
		this.$store.commit('routes', routes);

		if(this.$route.query.token){
			this.vglogar();
		}
		else {
			if(this.$route.name == 'Login' && this.$token()) {
				this.$home();
			}
			else if(this.$route.name != 'Login' && !this.$token()) {
				this.$router.push('/login');
			}

			if(this.$token() && this.$can('estabelecimentos:listar')) {
				this.carregarEstabelecimentos(1, true);
				this.getMenus();
				this.atualizarPerfil();
			}
		}
		
		eventBus.$on(events.USUARIO_LOGADO, () => {
			if(this.$can('estabelecimentos:listar')) {
				this.carregarEstabelecimentos(1, true);
				this.getMenus();
			}
		});

		eventBus.$on(events.LIMPAR_ESTABELECIMENTOS, () => {
			this.estabelecimentos = [];
			this.estabelecimentosSelecionados = [];
			console.log('LIMPANDO ESTABELECIMENTOS');
		});

		eventBus.$on(events.ATUALIZAR_ESTABELECIMENTOS_LIST, () => {
			this.estabelecimentosSelecionados = this.$estabelecimentos();
		});

		eventBus.$on(events.SHOW_PRINT_BUTTON, (value) => {
			this.showPrintButton = value;
		});

		eventBus.$on(events.SHOW_EXCEL_BUTTON, (value) => {
			this.showExcelButton = value;
		});
		//Eventos do Socket.io
		this.$socket().on(events.DESLOGAR, () => {
			this.logout();
		});

		this.$socket().on(events.SCRIPT_LOG, (data) => {
			console.log(data);
		});

		this.$socket().on(events.SCRIPT_ERROR, (data) => {
			console.error(data);
		});

		this.$socket().on(events.SCRIPT_END, (code) => {
			if(code == 0) {
				this.$success('Um script foi finalizado com sucesso.');
			}
			else if(code) {
				this.$alert('Um script foi finalizado com erro.');
			}
			else {
				this.$info('Um script foi interrompido.');
			}
		});

		this.$socket().on(events.NOTIFICATION, (type, message, sound) => {
			if(this.$refs.errorSound.paused && sound) {
				this.$refs.errorSound.play();
			}
			this.notificationType = type || 'info';
			this.notificationMessage = message;
			this.notificationPopup = true;
		});

		window.sql = (sql) => {
			this.$scriptsService.runSQL(sql).then(result => {
				console.table(result);
			}).catch(err => {
				console.error(err.response ? err.response.data.error : err.message);
			});
		};
	},
	methods: {
		getMenus(){
			if(this.$entregador()) {
				this.menu = [
					{
						label: 'QR codes',
						icon: 'mdi-qrcode-scan',
						route: '/qrcodes'
					}
				].sort((a, b) => a.label > b.label ? 1 : -1);
			}
			else {
				this.menu = [
					{
						label: 'PAINEL DE MONITORAMENTO',
						icon: 'mdi-view-dashboard',
						route: '/administracao',
						permission: 'qrcodesLido:listar',
						hide: !config.qrcode
					},
					{
						label: 'Arte do Qrcode',
						icon: 'mdi-image-edit',
						route: '/arteqrcode',
						permission: 'arteqrcode:listar',
						hide: !config.qrcode
					},
					{
						label: 'Gerador de QR code',
						icon: 'mdi-qrcode-edit',
						route: '/geradorqrcode',
						permission: 'rotas:listar',
						hide: !config.qrcode
					},
					{
						label: 'Teleatendimento',
						icon: 'mdi-send',
						route: '/teleatendimento',
						permission: 'pedidos:cadastrar',
						hide: config.qrcode
					},
					{
						label: 'Pedido Portaria',
						icon: 'mdi-send',
						route: '/pedidoPortaria',
						permission: 'pedidos:cadastrar',
						hide: config.qrcode
					},
					{
						label: 'Planos',
						icon: 'mdi-crown',
						route: '/planos',
						permission: 'planos:listar',
						//hide: config.qrcode
					},
					{
						label: 'Estabelecimentos',
						icon: 'mdi-domain',
						route: '/estabelecimentos',
						permission: 'estabelecimentos:listar'
					},
					{
						label: 'Usuários',
						icon: 'mdi-account-group',
						route: '/usuarios',
						permission: 'usuarios:listar'
					},
					{
						label: 'Clientes',
						icon: 'mdi-face',
						route: '/clientes',
						permission: 'clientes:listar'
					},
					{
						label: 'Produtos',
						icon: 'mdi-package',
						route: '/produtos',
						permission: 'produtos:listar',
						//hide: config.qrcode
					},
					{
						label: 'Valores Negociados',
						icon: 'mdi-handshake',
						route: '/valoresNegociado',
						permission: 'valoresNegociado:listar',
						hide: true
					},
					{
						label: 'Veículos',
						icon: 'mdi-truck',
						route: '/veiculos',
						permission: 'veiculos:listar',
						hide: true
					},
					{
						label: 'Entregadores',
						icon: 'mdi-account-arrow-right',
						route: '/entregadores',
						permission: 'entregadores:listar'
					},
					{
						label: 'Pedidos',
						icon: 'mdi-cart-arrow-right',
						route: '/pedidos',
						permission: 'pedidos:listar',
						//hide: config.qrcode
					},
					{
						label: 'Erros',
						icon: 'mdi-alert-circle',
						route: '/erros',
						permission: 'erros:listar',
						//hide: config.qrcode
					},
					{
						label: 'Erros Sinc Pedidos',
						icon: 'mdi-alert-circle',
						route: '/errosSincronizacao',
						permission: 'errosSincronizacao:listar',
						//hide: config.qrcode
					},
					{
						label: 'Erros Sinc Valores Negociados',
						icon: 'mdi-alert-circle',
						route: '/errosSincronizacaoVN',
						permission: 'errosSincronizacaoVN:listar',
						//hide: config.qrcode
					},
					{
						label: 'Scripts',
						icon: 'mdi-console',
						route: '/scripts',
						permission: 'scripts:listar',
						//hide: config.qrcode
					},
					{
						label: 'Taxas de Transação',
						icon: 'mdi-label-percent',
						route: '/taxasTransacao',
						permission: 'taxasTransacao:listar',
						hide: true
					},
					{
						label: 'Rotas',
						icon: 'mdi-routes',
						route: '/rotas',
						permission: 'rotas:listar',
						hide: !config.qrcode
					},
					{
						label: 'Mapleads',
						icon: 'mdi-map-marker',
						route: '/mapleads',
						permission: 'qrcodesLido:listar',
						hide: !config.qrcode
					},
					{
						label: 'Relatório de Qrcodes Lidos',
						icon: 'mdi-qrcode-scan',
						route: '/relatorioQrcodesLidos',
						permission: 'qrcodesLido:listar',
						hide: !config.qrcode
					}
				].sort((a, b) => a.label > b.label ? 1 : -1);
			}
		},
		selecionarEstabelecimento(estabelecimento) {
			estabelecimento.selecionado = !estabelecimento.selecionado;
			this.qs = '';
		},
		logout(){
			this.$store.commit('loginData', null);
			this.$router.push('/login');
		},
		removerSelecao(est){
			const estab = this.estabelecimentos.find(x => x.id == est.id);
			if(estab) {
				estab.selecionado = false;
			}
			this.estabelecimentosSelecionados = this.estabelecimentosSelecionados.filter(x => x.id != est.id);
		},
		setMousePos(e){
			console.log(e);
		},
		closeNotification(){
			this.notificationPopup = false;
			if(!this.$refs.errorSound.paused) {
				this.$refs.errorSound.pause();
				this.$refs.errorSound.currentTime = 0;
			}
		},
		async carregarEstabelecimentos(page, isInit){
			this.loading = true;
			try {
				const result = await this.$estabelecimentosService.find(page, {
					...(this.qs ? { iLike_nomeFantasia: `%${this.qs}%` } : {})
				});
				this.estabelecimentos = result.docs.map(x => {
					x.selecionado = this.$estabelecimentos().some(y => y.id == x.id) || (isInit && x.id == this.$usuario().matrizId);
					return x;
				});
				this.pagesEstabelecimentos = result.pages;
				this.pageEstabelecimentos = result.page;
			}
			catch(err){
				console.log(err);
			}
			this.loading = false;
		},
		toggleTodos(){
			this.selecionarTodos = !this.selecionarTodos;
			if(this.selecionarTodos) {
				this.estabelecimentos = this.estabelecimentos.map(est => {
					est.selecionado = true;
					return est;
				});
			}
			else {
				this.estabelecimentos = this.estabelecimentos.map(est => {
					est.selecionado = false;
					return est;
				});
			}
			this.qs = '';
			this.carregarEstabelecimentos(1);
		},
		refresh(){
			eventBus.$emit(events.REFRESH);
		},
		async atualizarPerfil(){
			const perfil = await this.$usuariosService.perfil();
			this.$usuario(perfil);
		},
		async vglogar(){
			try {
				const loginData = await this.$usuariosService.vgauth(this.$route.query.token);
				this.$store.commit('loginData', loginData);
				this.$router.push(this.$route.path);
			}
			catch(err){
				console.log(err);
			}
		},
		imprimir() {
			eventBus.$emit(events.IMPRIMIR);
		},
		exportExcel() {
			eventBus.$emit(events.EXPORT_EXCEL);
		}
	},
	watch: {
		estabelecimentosSelecionados: {
			deep: true,
			handler(){
				this.$store.commit('estabelecimentos', this.estabelecimentosSelecionados);
				eventBus.$emit(events.ESTABELECIMENTOS_ATUALIZADOS);
			}
		},
		estabelecimentos: {
			deep: true,
			handler(){
				if(this.estabelecimentos.some(est => !est.selecionado)){
					this.selecionarTodos = false;
				}
				if(this.estabelecimentos.length && this.estabelecimentos.every(est => est.selecionado)){
					this.selecionarTodos = true;
				}
				for(const estabelecimento of this.estabelecimentos) {
					if(estabelecimento.selecionado && !this.estabelecimentosSelecionados.find(est => est.id == estabelecimento.id)) {
						this.estabelecimentosSelecionados.push(estabelecimento);
					}
					else if(!estabelecimento.selecionado){
						this.estabelecimentosSelecionados = this.estabelecimentosSelecionados.filter(est => est.id != estabelecimento.id);
					}
				}
			}
		}
	},
	computed: {
		year(){
			return new Date().getFullYear();
		}
	}
};
</script>