import store from './store';
import io from 'socket.io-client';
import events from './events';
import eventBus from './eventBus';

let socket = null;

export default () => {
	if (!socket) {
		socket = io.connect(store.state.endpoint);
	}
	const connect = (msg) => {
		if (localStorage.loginData) {
			const { token } = JSON.parse(localStorage.loginData);
			if (token) {
				if (socket.disconnected) {
					socket = io.connect(store.state.endpoint);
				}
				socket.emit(events.INIT_SOCKET, token, msg);
			}
		}
	};
	socket.on('connect', () => {
		connect('conexão');
	});
	eventBus.$on(events.USUARIO_LOGADO, () => {
		connect('login');
	});
	eventBus.$on(events.USUARIO_DESLOGADO, () => {
		socket.disconnect();
	});
	return socket;
};