<template>
	<v-container fluid>
		<v-data-table group-by="estabelecimento.nomeFantasia" :server-items-length="itemsLength" :loading="loading" :items="items" :headers="headers" :footer-props="{ itemsPerPageOptions: [25] }" :options.sync="options">
			<template v-slot:[`group.header`]="{ group, toggle }">
				<td colspan="6" style="cursor:pointer" @click="toggle">{{group != 'undefined' ? group : 'Sem estabelecimento'}}</td>
			</template>
			<template v-slot:[`item.id`]="{ item }">
				<v-chip small :color="item.sincronizado ? 'green' : 'red'" dark>{{item.id}}</v-chip>
			</template>
			<template v-slot:[`item.tipoEstoque`]="{ item }">
				<template v-if="item.produto.estoqueUnico">Estoque Único</template>
				<template v-else>{{item.tipoEstoque}}</template>
			</template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-btn icon @click="openEditDialog(item)" v-if="$can('valoresNegociado:atualizar')">
					<v-icon>mdi-pencil</v-icon>
				</v-btn>
				<v-btn icon @click="sincronizar(item)" :loading="syncLoading == item.id" v-if="$can('pedidos:sincronizar') && !item.sincronizado" :disabled="syncLoading">
					<v-icon>mdi-sync</v-icon>
				</v-btn>
				<v-btn icon @click="openRemoveDialog(item)" v-if="$can('valoresNegociado:remover')">
					<v-icon>mdi-delete</v-icon>
				</v-btn>
			</template>
		</v-data-table>
		<div style="height: 80px"></div>
		<v-btn color="primary" fab dark fixed bottom right persistent @click="openAddDialog" v-if="$can('valoresNegociado:cadastrar')">
			<v-icon>mdi-plus</v-icon>
		</v-btn>
		<v-dialog width="600" v-model="dialog" persistent scrollable>
			<v-card :loading="dialogLoading">
				<v-toolbar flat>
					<span>{{obj.id ? 'Editar' : 'Adicionar'}} Valor Negociado</span>
					<v-spacer></v-spacer>
					<v-icon @click="dialog = false">mdi-close</v-icon>
				</v-toolbar>
				<v-divider></v-divider>
				<v-card-text>
					<ValorNegociado v-model="obj" ref="form"></ValorNegociado>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn color="secondary" @click="salvar">Salvar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog width="300" v-model="removeDialog" persistent>
			<v-card :loading="removeLoading">
				<v-container>Deseja remover este valor negociado?</v-container>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn color="secondary" @click="remover">Sim</v-btn>
					<v-btn color="error" @click="removeDialog = false">Não</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
/* eslint-disable no-empty */
import eventBus from '../config/eventBus';
import events from '../config/events';
import ValorNegociado from '../components/ValorNegociado';

export default {
	components: {
		ValorNegociado
	},
	data(){
		return {
			items: [],
			loading: false,
			options: {},
			itemsLength: 0,
			dialog: false,
			removeDialog: false,
			dialogLoading: false,
			removeLoading: false,
			syncLoading: false,
			obj: {},
			c: 0,
			headers: [
				{ text: 'Nº', value: 'id', sortable: false },
				{ text: 'Cliente', value: 'cliente.nome', sortable: false },
				{ text: 'Produto', value: 'produto.nome', sortable: false },
				{ text: 'Tipo de Estoque', value: 'tipoEstoque', sortable: false },
				{ text: 'Valor', value: 'valor', sortable: false },
				{ text: '', value: 'actions', sortable: false, align: 'right', width: 148 }
			]
		};
	},
	created(){
		eventBus.$on(events.REFRESH, this.carregar);
		eventBus.$on(events.ESTABELECIMENTOS_ATUALIZADOS, this.carregar);
	},
	destroyed(){
		eventBus.$off(events.REFRESH, this.carregar);
		eventBus.$off(events.ESTABELECIMENTOS_ATUALIZADOS, this.carregar);
	},
	methods: {
		async sincronizar(obj){
			this.syncLoading = obj.id;
			try {
				await this.$valoresNegociadoService.sincronizar(obj.id);
				this.carregar(this.options.page);
			}
			catch(e) {}
			this.syncLoading = false;
		},
		async carregar(page) {
			this.loading = true;
			try {
				const result = await this.$valoresNegociadoService.find(page, {
					in_estabelecimentoId: this.$estabelecimentos().map(x => x.id).join(',') || null
				});
				this.items = result.docs;
				this.options.page = result.page;
				this.itemsLength = result.total;
			}
			catch(err){
				console.log(err);
			}
			this.loading = false;
		},
		async salvar(){
			if(this.$refs.form.validate()) {
				this.dialogLoading = true;
				try {
					if(this.obj.id) {
						await this.$valoresNegociadoService.update(this.obj.id, this.obj);
						this.carregar(this.options.page);
					}
					else {
						await this.$valoresNegociadoService.create(this.obj);
						this.carregar(1);
					}
				}
				catch(err){
					console.log(err);
				}
				this.dialog = false;
				this.dialogLoading = false;
			}
		},
		async remover(){
			this.removeLoading = true;
			try {
				await this.$valoresNegociadoService.remove(this.obj.id);
				this.carregar(this.items.length == 1 ? this.options.page - 1 : this.options.page);
			}
			catch(e) {}
			this.removeDialog = false;
			this.removeLoading = false;
		},
		openEditDialog(obj){
			this.resetForm();
			this.$nextTick(() => {
				this.obj.c = ++this.c;
				this.obj = JSON.parse(JSON.stringify(obj));
				this.dialog = true;
			});
		},
		openAddDialog(){
			this.resetForm();
			this.$nextTick(() => {
				this.obj = {
					c: ++this.c,
					tipoEstoque: this.$enum().TIPO_ESTOQUE.ESTOQUE_CHEIO
				};
				this.dialog = true;
			});
		},
		openRemoveDialog(obj){
			this.obj = JSON.parse(JSON.stringify(obj));
			this.removeDialog = true;
		},
		resetForm(){
			if(this.$refs.form){
				this.$refs.form.reset();
			}
		}
	},
	watch: {
		options(v) {
			this.carregar(v.page);
		}
	}
};
</script>