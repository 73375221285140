import Vue from 'vue';
import Vuetify from 'vuetify';
import i18n from './i18n';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: '#0E0554',
				secondary: '#39D4B9',
				terciary: '#5867dd',
				secondaryDark: '#008080',
				accent: '#FF0000'
			}
		}
	},
	lang: {
		t: (key, ...params) => i18n.t(key, params)
	}
});