<template>
	<div>
		<v-text-field :disabled="loading" label="CEP" type="number" v-model="value.cep" :rules="[rules.cep]"></v-text-field>
		<v-select :disabled="loading" label="UF" v-model="value.uf" :items="ufs"></v-select>
		<v-autocomplete label="Cidade" :items="cidades" :filter="filter" item-value="id" item-text="nome" v-model="value.ibge"></v-autocomplete>
		<v-text-field :disabled="loading" label="Bairro" v-model="value.bairro"></v-text-field>
		<v-text-field :disabled="loading" label="Endereço" v-model="value.logradouro"></v-text-field>
		<v-text-field :disabled="loading" label="Complemento" v-model="value.complemento"></v-text-field>
		<v-text-field :disabled="loading" type="number" min="0" step="1" label="Número" v-model="value.numero"></v-text-field>
		<v-text-field readonly type="number" label="Código do Município" v-model="value.ibge"></v-text-field>
		<v-textarea rows="2" label="Observação" v-model="value.observacao"></v-textarea>
	</div>
</template>

<script>
import rules from '../config/rules';

export default {
	props: {
		value: {
			type: Object,
			default(){
				return {};
			}
		}
	},
	data(){
		return {
			rules,
			ufs: [],
			cidades: [],
			loading: false
		};
	},
	created() {
		this.ufs = Object.keys(this.$enum().UF);
		if(this.value.uf) {
			this.carregarCidades(this.value.uf);
		}
	},
	methods: {
		filter(item, query) {
			return this.$simplify(item.nome).includes(this.$simplify(query));
		},
		async carregarCidades(uf){
			const codUf = this.$enum().UF[uf];
			this.cidades = (await this.$utilsService.cidades(codUf)).map(x => ({
				id: x.id.toString(),
				nome: x.nome
			}));
		}
	},
	watch: {
		value: {
			deep: true,
			handler(v){
				this.$emit('input', v);
			}
		},
		async 'value.cep'(v){
			if(v && rules.cep(v) === true && !this.value.ibge){
				const viacep = await this.$utilsService.viacep(v);
				this.value.logradouro = viacep.logradouro;
				this.value.complemento = viacep.complemento;
				this.value.bairro = viacep.bairro;
				this.value.uf = viacep.uf;
				this.value.ibge = viacep.ibge;
				this.$emit('input', {...this.value});
			}
		},
		'value.uf'(v){
			if(v) {
				this.carregarCidades(v);
			}
		},
		'value.ibge'(v){
			if(v){
				this.value.localidade = (this.cidades.find(x => x.id == v) || {}).nome;
			}
		}
	}
};
</script>