<template>
	<v-row>
		<v-col
			cols="12"
			md="6"
			v-if="((usuario && $can('usuarios:associar_estabelecimento')) || (entregador && $can('entregadores:associar_estabelecimento')) || (cliente && $can('clientes:associar_estabelecimento'))) && $can('estabelecimentos:listar')"
		>
			<v-card :loading="loading">
				<v-sheet class="pa-2">
					<v-text-field
						prepend-inner-icon="mdi-magnify"
						solo
						hide-details
						dense
						placeholder="Buscar"
						@input="carregarEstabelecimentos"
						v-model="qs"
					></v-text-field>
				</v-sheet>
				<v-divider></v-divider>
				<v-list dense nav v-if="estabelecimentos.length">
					<v-list-item
						@click="toggle(estabelecimento)"
						v-for="estabelecimento of estabelecimentos"
						:key="estabelecimento.id"
					>
						<v-list-item-action
							v-if="((usuario && $can('usuarios:remover_associacao') || (entregador && $can('entregadores:remover_associacao')) || (cliente && $can('clientes:remover_associacao'))) && estabelecimento.selecionado) || !estabelecimento.selecionado"
						>
							<v-checkbox readonly v-model="estabelecimento.selecionado"></v-checkbox>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>{{estabelecimento.nomeFantasia}}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-subheader v-else>...</v-subheader>
			</v-card>
		</v-col>
		<v-col cols="12" md="6">
			<v-chip-group column>
				<v-chip
					color="secondary"
					:close="(usuario && $can('usuarios:remover_associacao')) || (entregador && $can('entregadores:remover_associacao')) || (cliente && $can('clientes:remover_associacao'))"
					v-for="associacao in associacoes"
					:key="associacao.id"
					@click:close="remove(associacao)"
				>{{associacao.nomeFantasia | limitchars}}</v-chip>
			</v-chip-group>
		</v-col>
	</v-row>
</template>

<script>
export default {
	props: ['usuario', 'entregador', 'cliente'],
	data() {
		return {
			estabelecimentos: [],
			associacoes: [],
			loading: false,
			searchLoading: false,
			service: null,
			id: null,
			qs: ''
		};
	},
	created() {
		this.init();
	},
	methods: {
		init(){
			this.qs = null;
			if (this.usuario) {
				this.service = this.$usuariosService;
				this.id = this.usuario.id;
			} else if (this.entregador) {
				this.service = this.$entregadoresService;
				this.id = this.entregador.id;
			} else if (this.cliente) {
				this.service = this.$clientesService;
				this.id = this.cliente.id;
			}
			this.carregarAssociacoes();
			this.carregarEstabelecimentos();
		},
		async carregarEstabelecimentos() {
			if (this.$can('estabelecimentos:listar')) {
				this.loading = true;
				try {
					this.estabelecimentos = (
						await this.$estabelecimentosService.find(1, {
							...(this.qs
								? { iLike_nomeFantasia: `%${this.qs}%` }
								: {}),
							paginate: 5
						})
					).docs.map(x => {
						x.selecionado = this.associacoes.some(
							y => x.id == y.id
						);
						return x;
					});
				} catch (err) {
					console.log(err);
				}
				this.loading = false;
			}
		},
		async carregarAssociacoes() {
			this.loading = true;
			try {
				this.associacoes = await this.service.listEstabelecimentos(this.id);
				for (const estabelecimento of this.estabelecimentos) {
					if (
						this.associacoes.some(x => x.id == estabelecimento.id)
					) {
						estabelecimento.selecionado = true;
					} else {
						estabelecimento.selecionado = false;
					}
				}
			} catch (err) {
				console.log(err);
			}
			this.loading = false;
		},
		async toggle(item) {
			if (
				(
					(
						(this.usuario && this.$can('usuarios:remover_associacao')) ||
						(this.entregador && this.$can('entregadores:remover_associacao')) ||
						(this.cliente && this.$can('clientes:remover_associacao'))
					) &&
					item.selecionado
				) ||
				!item.selecionado
			) {
				if (!item.selecionado) {
					this.qs = null;
					this.loading = true;
					try {
						await this.service.addEstabelecimento(this.id, item.id);
						this.carregarAssociacoes();
					} catch (err) {
						console.log(err);
					}
					this.loading = false;
				} else {
					this.remove(item);
				}
			}
		},
		async remove(item) {
			this.loading = true;
			try {
				await this.service.removeEstabelecimento(this.id, item.id);
				this.carregarAssociacoes();
			} catch (err) {
				console.log(err);
			}
			this.loading = false;
		},
		reset() {
			this.qs = null;
			this.associacoes = [];
			this.estabelecimentos = [];
		}
	},
	watch: {
		'usuario.c'() {
			if ((this.usuario || {}).id) {
				this.init();
			}
		},
		'entregador.c'() {
			if ((this.entregador || {}).id) {
				this.init();
			}
		},
		'cliente.c'() {
			if ((this.cliente || {}).id) {
				this.init();
			}
		}
	}
};
</script>