<template>
	<v-form ref="form">
		<fieldset class="vg-fieldset">
			<legend>Informações Gerais</legend>
			<v-select :disabled="value.disableEst" label="Estabelecimento" :rules="[rules.required]" v-model="value.estabelecimentoId" :items="$estabelecimentos(this.value.estabelecimento ? JSON.parse(JSON.stringify(this.value.estabelecimento)) : null)" item-text="nomeFantasia" item-value="id"></v-select>
			<v-text-field label="Nome" v-model="value.nome" :rules="[rules.required]"></v-text-field>
			<v-text-field label="Email" :rules="[rules.email]" v-model="value.email"></v-text-field>
			<v-select label="Tipo" :items="tiposPessoa" v-model="value.tipoPessoa"></v-select>
			<v-text-field v-if="value.tipoPessoa == $enum().TIPO_PESSOA.PESSOA_FISICA" type="number" label="CPF" v-model="value.cpf"></v-text-field>
			<v-text-field v-if="value.tipoPessoa == $enum().TIPO_PESSOA.PESSOA_JURIDICA" type="number" label="CNPJ" v-model="value.cnpj"></v-text-field>
			<v-text-field v-if="value.tipoPessoa == $enum().TIPO_PESSOA.PESSOA_JURIDICA" label="Razão Social" v-model="value.razaoSocial"></v-text-field>
			<v-text-field v-if="value.tipoPessoa == $enum().TIPO_PESSOA.PESSOA_JURIDICA" type="number" label="Inscrição Estadual" v-model="value.inscricaoEstadual"></v-text-field>
			<v-text-field v-if="value.tipoPessoa == $enum().TIPO_PESSOA.PESSOA_JURIDICA" type="number" label="Incrição Municipal" v-model="value.inscricaoMunicipal"></v-text-field>
			<v-select label="Perfil" v-model="value.perfil" :items="perfis"></v-select>
			<Datepicker label="Aniversário" v-model="value.aniversario"></Datepicker>
			<v-text-field label="Prazo de Vencimento" type="number" min="0" step="1" v-model="value.prazoVencimento"></v-text-field>
			<v-select label="Tipo CFOP" :items="tiposCFOP" v-model="value.tipoCFOP"></v-select>
			<v-text-field v-if="config.qrcode" type="number" label="Valor" v-model="value.valorCompra" min="0" step="0.01"></v-text-field>
			<v-text-field v-if="config.qrcode" type="number" label="Frequência de leitura" v-model="value.frequenciaVisita"></v-text-field>
		</fieldset>
		<fieldset class="vg-fieldset">
			<legend>Telefones</legend>
			<span>* Clique na tag para definir o telefone principal</span>
			<v-chip-group column active-class="primary" v-model="value.telefonePrincipal">
				<v-chip close v-for="phone in value.telefones" :key="phone" @click:close="removePhone(phone)" :value="phone">{{phone}}</v-chip>
			</v-chip-group>
			<v-text-field append-outer-icon="mdi-plus" type="number" step="1" :label="`Novo telefone`" :rules="[rules.telefoneRepetido]" v-model="telefone" hint="Precione ENTER para inserir" @keydown.enter="pushPhone" @click:append-outer="pushPhone"></v-text-field>
		</fieldset>
		<fieldset class="vg-fieldset">
			<legend>Endereço</legend>
			<Endereco ref="endereco" v-model="value.endereco"></Endereco>
		</fieldset>
		<fieldset class="vg-fieldset" v-if="config.qrcode">
			<legend>Rotas</legend>
			<v-chip-group column>
				<v-chip @click:close="removeRota(rota.id)" close v-for="rota in rotasChip" :key="rota.id">{{rota.nome}}</v-chip>
			</v-chip-group>
			<v-autocomplete ref="autRota" @change="addRota" no-data-text="Digite para buscar uma rota" :items="rotasSearch" item-value="id" item-text="nome" :search-input.sync="qsRota" v-model="rotaId" label="Adicionar rota"></v-autocomplete>
		</fieldset>
	</v-form>
</template>

<script>
import Endereco from '../components/Endereco';
import Datepicker from '../components/Datepicker';
import rules from '../config/rules';
import config from '../config/config';

export default {
	components: {
		Endereco,
		Datepicker
	},
	props: {
		value: {
			type: Object,
			default(){
				return {};
			}
		}
	},
	data(){
		return {
			config,
			tiposPessoa: [],
			perfis: [],
			tiposCFOP: [],
			telefone: '',
			qsRota: '',
			rotasSearch: [],
			loadingRota: false,
			rotaId: null,
			rotasTotais: [],
			rules: {
				...rules,
				telefoneRepetido: v => !this.value.telefones.includes(v) || 'Telefone repetido'
			}
		};
	},
	created(){
		this.tiposCFOP = Object.values(this.$enum().TIPO_CFOP);
		this.tiposPessoa = Object.values(this.$enum().TIPO_PESSOA);
		this.perfis = Object.values(this.$enum().PERFIL_CLIENTE);
	},
	methods: {
		validate(){
			return this.$refs.form.validate();
		},
		reset(){
			this.$refs.form.reset();
		},
		pushPhone(){
			if(this.telefone && !this.value.telefones.includes(this.telefone)) {
				this.value.telefones.push(this.telefone);
				this.telefone = '';
			}
		},
		removePhone(phone){
			this.value.telefones = this.value.telefones.filter(x => x != phone);
		},
		async carregarRotas() {
			this.loadingRota = true;
			try {
				this.rotasSearch = (await this.$rotasService.find(1, {
					...(this.qsRota ? { iLike_nome: `%${this.qsRota}%` } : {}),
					in_estabelecimentoId: this.$estabelecimentos().map(x => x.id).join(',') || null
				})).docs;
				for(const rota of this.rotasSearch) {
					if(!this.rotasTotais.find(r => r.id == rota.id)) {
						this.rotasTotais.push(rota);
					}
				}
			}
			catch(err){
				console.log(err);
			}
			this.loadingRota = false;
		},
		removeRota(id) {
			this.value.rotas = this.value.rotas.filter(r => r != id);
		},
		addRota(id){
			const rota = this.rotasSearch.find(rota => rota.id == id);
			if(rota) {
				if(this.value.rotas.find(r => r == id)) {
					this.$alert('Rota já adicionada');
				}
				else {
					this.value.rotas.push(id);
				}
				this.$refs.autRota.blur();
				this.$nextTick(() => {
					this.rotaId = null;
				});
			}
		}
	},
	watch: {
		qsRota(){
			this.carregarRotas();
		}
	},
	computed: {
		rotasChip() {
			return this.value.rotas.map(id => {
				return this.rotasTotais.find(rota => rota.id == id) || { id, nome: '[REMOVIDA]' };
			});
		}
	}
};
</script>