<template>
	<v-form ref="form">
		<v-select label="Estabelecimento" :rules="[rules.required]" v-model="value.estabelecimentoId" :items="$estabelecimentos(this.value.estabelecimento ? JSON.parse(JSON.stringify(this.value.estabelecimento)) : null)" item-text="nomeFantasia" item-value="id"></v-select>
		<v-select @change="changeTipo" label="Tipo" v-model="value.tipo" :items="tiposTaxa" item-text="nome" item-value="id"></v-select>
		<v-text-field label="Bandeira" v-model="value.nome"></v-text-field>
		<template v-if="value.tipo == $enum().TIPO_TAXA.CARTAO">
			<v-text-field label="Taxa Crédito" v-model="value.taxaCredito" type="number" step="0.01" min="0"></v-text-field>
			<v-text-field label="Taxa Débito" v-model="value.taxaDebito" type="number" step="0.01" min="0"></v-text-field>
		</template>
		<v-text-field v-else label="Taxa Única" v-model="value.taxaUnica" type="number" step="0.01" min="0"></v-text-field>
		<v-switch label="Utilizar porcentagem" v-model="value.porcentagem"></v-switch>
		<v-text-field label="Dias pra receber" v-model="value.prazo" type="number" min="0"></v-text-field>
	</v-form>
</template>

<script>
import rules from '../config/rules';

export default {
	props: {
		value: {
			type: Object,
			default(){
				return {};
			}
		}
	},
	data(){
		return {
			rules,
			tiposTaxa: []
		};
	},
	created(){
		this.tiposTaxa = Object.values(this.$enum().TIPO_TAXA);
	},
	methods: {
		validate(){
			return this.$refs.form.validate();
		},
		reset(){
			this.$refs.form.reset();
		},
		changeTipo(v){
			if(v == this.$enum().TIPO_TAXA.CARTAO) {
				this.value.taxaUnica = null;
			}
			else {
				this.value.taxaCredito = null;
				this.value.taxaDebito = null;
			}
		}
	}
};
</script>