<template>
	<v-form ref="form">
		<v-select label="Estabelecimento" :rules="[rules.required]" v-model="value.estabelecimentoId" :items="$estabelecimentos(this.value.estabelecimento ? JSON.parse(JSON.stringify(this.value.estabelecimento)) : null)" item-text="nomeFantasia" item-value="id"></v-select>
		<v-text-field label="Nome" v-model="value.nome" :rules="[rules.required]"></v-text-field>
	</v-form>
</template>

<script>
import rules from '../config/rules';

export default {
	props: {
		value: {
			type: Object,
			default(){
				return {};
			}
		}
	},
	data(){
		return {
			rules
		};
	},
	methods: {
		validate(){
			return this.$refs.form.validate();
		},
		reset(){
			this.$refs.form.reset();
		}
	}
};
</script>