import axios from '../config/axios';
const PATH = '/erros';

export default {
	async find(page = 1, params) {
		const response = await axios().get(PATH, {
			params: {
				page,
				...params
			}
		});
		return response.data;
	},
	async remove(id) {
		const response = await axios().delete(`${PATH}/${id}`);
		return response.data;
	}
};