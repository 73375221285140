<template>
	<v-container fluid>
		<v-data-table group-by="estabelecimento.nomeFantasia" :server-items-length="itemsLength" :loading="loading" :items="items" :headers="headers" :footer-props="{ itemsPerPageOptions: [25] }" :options.sync="options">
			<template v-slot:[`group.header`]="{ group, toggle }">
				<td colspan="14" style="cursor:pointer" @click="toggle">{{group != 'undefined' ? group : 'Sem estabelecimento'}}</td>
			</template>
			<template v-slot:[`item.id`]="{ item }">
				<v-chip small :color="item.sincronizado ? 'green' : 'red'" dark>{{item.id}}</v-chip>
			</template>
			<template v-slot:[`item.data`]="{ item }">{{item.data | datetime}}</template>
			<template v-slot:[`item.clienteEndereco`]="{ item }">{{(item.cliente || {}).endereco | endereco}}</template>
			<template v-slot:[`item.pedidoItems`]="{ item }">{{item.pedidoItems | produtos}}</template>
			<template v-slot:[`item.dataEntrega`]="{ item }">{{item.dataEntrega | datetime}}</template>
			<template v-slot:[`item.createdAt`]="{ item }">{{item.createdAt | datetime}}</template>
			<template v-slot:[`item.parcelamento`]="{ item }">{{item.parcelamento || 1}}x</template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-btn icon @click="openEditDialog(item)" v-if="$can('pedidos:atualizar')">
					<v-icon>mdi-pencil</v-icon>
				</v-btn>
				<v-btn icon @click="sincronizar(item)" :loading="syncLoading == item.id" v-if="$can('pedidos:sincronizar') && !item.sincronizado" :disabled="syncLoading">
					<v-icon>mdi-sync</v-icon>
				</v-btn>
				<v-btn icon @click="openRemoveDialog(item)" v-if="$can('pedidos:remover')">
					<v-icon>mdi-delete</v-icon>
				</v-btn>
			</template>
		</v-data-table>
		<v-dialog width="300" v-model="removeDialog" persistent>
			<v-card :loading="removeLoading">
				<v-container>Deseja remover este pedido?</v-container>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn color="secondary" @click="remover">Sim</v-btn>
					<v-btn color="error" @click="removeDialog = false">Não</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialog" persistent scrollable>
			<v-card>
				<v-toolbar flat>
					<span>Editar Pedido</span>
					<v-spacer></v-spacer>
					<v-icon @click="dialog = false">mdi-close</v-icon>
				</v-toolbar>
				<v-divider></v-divider>
				<v-card-text>
					<Teleatendimento :pedido="obj" ref="form"></Teleatendimento>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
/* eslint-disable no-empty */
import eventBus from '../config/eventBus';
import events from '../config/events';
import Teleatendimento from '../pages/Teleatendimento';

export default {
	components: {
		Teleatendimento
	},
	data(){
		return {
			items: [],
			loading: false,
			options: {},
			itemsLength: 0,
			removeDialog: false,
			removeLoading: false,
			dialog: false,
			syncLoading: false,
			obj: {},
			c: 0,
			headers: [
				{ text: 'Nº', value: 'id', sortable: false },
				{ text: 'Status', value: 'status', sortable: false },
				{ text: 'Início do Pedido', value: 'data', sortable: false },
				{ text: 'Cliente', value: 'cliente.nome', sortable: false },
				{ text: 'Tel. Cliente', value: 'cliente.telefonePrincipal', sortable: false },
				{ text: 'End. Cliente', value: 'clienteEndereco', sortable: false },
				{ text: 'Produtos', value: 'pedidoItems', sortable: false },
				{ text: 'Entregador', value: 'entregador.nome', sortable: false },
				{ text: 'Atendido por', value: 'usuario.nome', sortable: false },
				{ text: 'Data da Entrega', value: 'dataEntrega', sortable: false },
				{ text: 'Data do Pedido', value: 'createdAt', sortable: false },
				{ text: 'Valor Total', value: 'valorTotal', sortable: false },
				{ text: 'Parcelamento', value: 'parcelamento', sortable: false },
				{ text: '', value: 'actions', sortable: false, align: 'right', width: 148 }
			]
		};
	},
	created(){
		eventBus.$on(events.REFRESH, this.carregar);
		eventBus.$on(events.ESTABELECIMENTOS_ATUALIZADOS, this.carregar);
	},
	destroyed(){
		eventBus.$off(events.REFRESH, this.carregar);
		eventBus.$off(events.ESTABELECIMENTOS_ATUALIZADOS, this.carregar);
	},
	methods: {
		pedidoAtualizado(){
			this.dialog = false;
			this.carregar(this.options.page);
		},
		async carregar(page) {
			this.loading = true;
			try {
				const result = await this.$pedidosService.find(page, {
					in_estabelecimentoId: this.$estabelecimentos().map(x => x.id).join(',') || null
				});
				this.items = result.docs;
				this.options.page = result.page;
				this.itemsLength = result.total;
			}
			catch(err){
				console.log(err);
			}
			this.loading = false;
		},
		async remover(){
			this.removeLoading = true;
			try {
				await this.$pedidosService.remove(this.obj.id);
				this.carregar(this.items.length == 1 ? this.options.page - 1 : this.options.page);
			}
			catch(e) {}
			this.removeDialog = false;
			this.removeLoading = false;
		},
		openRemoveDialog(obj){
			this.obj = JSON.parse(JSON.stringify(obj));
			this.removeDialog = true;
		},
		openEditDialog(obj){
			obj.c = ++this.c;
			this.obj = obj;
			this.dialog = true;
		},
		resetForm(){
			if(this.$refs.form){
				this.$refs.form.reset();
			}
		},
		async sincronizar(obj){
			this.syncLoading = obj.id;
			try {
				await this.$pedidosService.sincronizar(obj.id);
				this.carregar(this.options.page);
			}
			catch(e) {}
			this.syncLoading = false;
		}
	},
	watch: {
		options(v) {
			this.carregar(v.page);
		}
	}
};
</script>