import axios from '../config/axios';
import _axios from 'axios';
import config from '../config/config';
const PATH = '/utils';

export default {
	async routes() {
		const response = await axios().get(`${PATH}/routes`);
		return response.data;
	},
	async enum() {
		const response = await axios().get(`${PATH}/enum`);
		return response.data;
	},
	async searchAddress(query, prox) {
		const response = await axios().get(`${PATH}/search_address`, {
			params: {
				query,
				prox
			}
		});
		return response.data;
	},
	async viacep(cep) {
		const response = await _axios.get(`https://viacep.com.br/ws/${cep}/json`);
		return response.data;
	},
	async cidades(ufId) {
		const response = await _axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufId}/municipios`);
		return response.data;
	},
	async manifest() {
		const response = await axios().get(`${PATH}/manifest`, {
			params: {
				...(config.qrcode ? { qrcode: true } : {})
			}
		});
		return response.data;
	}
};