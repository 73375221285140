import axios from '../config/axios';
const PATH = '/usuarios';

export default {
	async create(body) {
		const response = await axios().post(PATH, body);
		return response.data;
	},
	async find(page = 1, params) {
		const response = await axios().get(PATH, {
			params: {
				page,
				...params
			}
		});
		return response.data;
	},
	async update(id, body) {
		const response = await axios().put(`${PATH}/${id}`, body);
		return response.data;
	},
	async remove(id) {
		const response = await axios().delete(`${PATH}/${id}`);
		return response.data;
	},
	async auth(email, senha, entregador) {
		const response = await axios().post(`${PATH}/auth`, {
			email,
			senha,
			entregador
		});
		return response.data;
	},
	async vgauth(token) {
		const response = await axios().post(`${PATH}/vgauth`, {
			token
		});
		return response.data;
	},
	async perfil() {
		const response = await axios().get(`${PATH}/perfil`);
		return response.data;
	},
	async listEstabelecimentos(id) {
		const response = await axios().get(`${PATH}/${id}/estabelecimentos`);
		return response.data;
	},
	async addEstabelecimento(id, idEstabelecimento) {
		const response = await axios().post(`${PATH}/${id}/estabelecimentos`, {
			id: idEstabelecimento
		});
		return response.data;
	},
	async removeEstabelecimento(id, idEstabelecimento) {
		const response = await axios().delete(`${PATH}/${id}/estabelecimentos/${idEstabelecimento}`);
		return response.data;
	}
};