<template>
	<v-container fluid>
		<v-data-table group-by="estabelecimento.nomeFantasia" :server-items-length="itemsLength" :loading="loading" :items="items" :headers="headers" :footer-props="{ itemsPerPageOptions: [25] }" :options.sync="options">
			<template v-slot:[`group.header`]="{ group, toggle }">
				<td colspan="8" style="cursor:pointer" @click="toggle">{{group != 'undefined' ? group : 'Sem estabelecimento'}}</td>
			</template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-btn icon @click="openEditDialog(item)" v-if="$can('veiculos:atualizar')">
					<v-icon>mdi-pencil</v-icon>
				</v-btn>
				<v-btn icon @click="openRemoveDialog(item)" v-if="$can('veiculos:remover')">
					<v-icon>mdi-delete</v-icon>
				</v-btn>
			</template>
		</v-data-table>
		<div style="height: 80px"></div>
		<v-btn color="primary" fab dark fixed bottom right persistent @click="openAddDialog" v-if="$can('veiculos:cadastrar')">
			<v-icon>mdi-plus</v-icon>
		</v-btn>
		<v-dialog width="600" v-model="dialog" persistent scrollable>
			<v-card :loading="dialogLoading">
				<v-toolbar flat>
					<span>{{obj.id ? 'Editar' : 'Adicionar'}} Veículo</span>
					<v-spacer></v-spacer>
					<v-icon @click="dialog = false">mdi-close</v-icon>
				</v-toolbar>
				<v-divider></v-divider>
				<v-card-text>
					<Veiculo v-model="obj" ref="form"></Veiculo>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn color="secondary" @click="salvar">Salvar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog width="300" v-model="removeDialog" persistent>
			<v-card :loading="removeLoading">
				<v-container>Deseja remover este veículo?</v-container>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn color="secondary" @click="remover">Sim</v-btn>
					<v-btn color="error" @click="removeDialog = false">Não</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
/* eslint-disable no-empty */
import eventBus from '../config/eventBus';
import events from '../config/events';
import Veiculo from '../components/Veiculo';

export default {
	components: {
		Veiculo
	},
	data(){
		return {
			items: [],
			loading: false,
			options: {},
			itemsLength: 0,
			dialog: false,
			removeDialog: false,
			dialogLoading: false,
			removeLoading: false,
			obj: {},
			headers: [
				{ text: 'Nº', value: 'id', sortable: false },
				{ text: 'Nome', value: 'nome', sortable: false },
				{ text: 'Marca', value: 'marca', sortable: false },
				{ text: 'Placa', value: 'placa', sortable: false },
				{ text: 'Tipo', value: 'tipo', sortable: false },
				{ text: 'Situação', value: 'situacao', sortable: false },
				{ text: 'Cidade', value: 'cidade', sortable: false },
				{ text: '', value: 'actions', sortable: false, align: 'right', width: 148 }
			]
		};
	},
	created(){
		eventBus.$on(events.REFRESH, this.carregar);
		eventBus.$on(events.ESTABELECIMENTOS_ATUALIZADOS, this.carregar);
	},
	destroyed(){
		eventBus.$off(events.REFRESH, this.carregar);
		eventBus.$off(events.ESTABELECIMENTOS_ATUALIZADOS, this.carregar);
	},
	methods: {
		async carregar(page) {
			this.loading = true;
			try {
				const result = await this.$veiculosService.find(page, {
					in_estabelecimentoId: this.$estabelecimentos().map(x => x.id).join(',') || null
				});
				this.items = result.docs;
				this.options.page = result.page;
				this.itemsLength = result.total;
			}
			catch(err){
				console.log(err);
			}
			this.loading = false;
		},
		async salvar(){
			if(this.$refs.form.validate()) {
				this.dialogLoading = true;
				try {
					if(this.obj.id) {
						await this.$veiculosService.update(this.obj.id, this.obj);
						this.carregar(this.options.page);
					}
					else {
						await this.$veiculosService.create(this.obj);
						this.carregar(1);
					}
				}
				catch(err){
					console.log(err);
				}
				this.dialog = false;
				this.dialogLoading = false;
			}
		},
		async remover(){
			this.removeLoading = true;
			try {
				await this.$veiculosService.remove(this.obj.id);
				this.carregar(this.items.length == 1 ? this.options.page - 1 : this.options.page);
			}
			catch(e) {}
			this.removeDialog = false;
			this.removeLoading = false;
		},
		openEditDialog(obj){
			this.resetForm();
			this.$nextTick(() => {
				this.obj = JSON.parse(JSON.stringify(obj));
				this.dialog = true;
			});
		},
		openAddDialog(){
			this.resetForm();
			this.$nextTick(() => {
				this.obj = {};
				this.dialog = true;
			});
		},
		openRemoveDialog(obj){
			this.obj = JSON.parse(JSON.stringify(obj));
			this.removeDialog = true;
		},
		resetForm(){
			if(this.$refs.form){
				this.$refs.form.reset();
			}
		}
	},
	watch: {
		options(v) {
			this.carregar(v.page);
		}
	}
};
</script>