import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

export default new VueI18n({
	locale: 'pt',
	messages: {
		pt: {
			$vuetify: {
				noDataText: 'Sem dados',
				dataTable: {
					itemsPerPageText: 'Itens por página:'
				},
				dataFooter: {
					itemsPerPageAll: 'Todos',
					pageText: '{0} - {1} de {2}',
					prevPage: 'Página anterior',
					firstPage: 'Primeira página',
					nextPage: 'Próxima página',
					lastPage: 'Última página'
				},
				dataIterator: {
					loadingText: 'Carregando...',
					noResultsText: 'Sem dados'
				}
			}
		}
	}
});