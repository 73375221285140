<template>
	<Teleatendimento portaria></Teleatendimento>
</template>

<script>
import Teleatendimento from './Teleatendimento';
export default {
	components: {
		Teleatendimento
	}
};
</script>