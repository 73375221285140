import axios from '../config/axios';
const PATH = '/taxasTransacao';

export default {
	async create(body) {
		const response = await axios().post(PATH, body);
		return response.data;
	},
	async find(page = 1, params) {
		const response = await axios().get(PATH, {
			params: {
				page,
				...params
			}
		});
		return response.data;
	},
	async update(id, body) {
		const response = await axios().put(`${PATH}/${id}`, body);
		return response.data;
	},
	async remove(id) {
		const response = await axios().delete(`${PATH}/${id}`);
		return response.data;
	}
};