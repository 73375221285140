import Vue from 'vue';
import App from './App.vue';
import vuetify from './config/vuetify';
import router from './config/router';
import store from './config/store';
import i18n from './config/i18n';
import moment from 'moment';
import Filtro from './components/Filtro.vue';
import BuscaEndereco from './components/BuscaEndereco.vue';

import './config/initServices';
import './config/globalFunctions';
import '@mdi/font/css/materialdesignicons.min.css';
import 'leaflet/dist/leaflet.css';
import 'vuetify/dist/vuetify.min.css';
import './style.css';

Vue.filter('uppercase', v => v.toUpperCase());
Vue.filter('endereco', v => (v ? [v.logradouro, v.localidade, v.bairro, v.complemento, v.uf].filter(x => !!x).join(', ') : '---') || '---');
Vue.filter('iniciais', v => v.split(' ').map(x => x[0]).slice(0, 2).join('').toUpperCase());
Vue.filter('date', v => moment(new Date(v)).format('DD/MM/YYYY'));
Vue.filter('datetime', v => v ? moment(new Date(v)).format('DD/MM/YYYY HH:mm') : '---');
Vue.filter('produtos', v => v.map(item => `x${item.quantidade} ${item.produto.nome}`).join(', '));
Vue.filter('limitchars', v => v.length > 26 ? v.substr(0, 23) + '...' : v);
Vue.filter('qrcodeItems', v => v.filter(item => !!item.produto).map(item => `${item.quantidade}x ${item.produto.nome}`).join(', ') || '---');

Vue.component('Filtro', Filtro);
Vue.component('BuscaEndereco', BuscaEndereco);

new Vue({
	el: '#app',
	render: h => h(App),
	vuetify,
	router,
	store,
	i18n
});

//Configurando service-worker
if ('serviceWorker' in navigator) {
	window.addEventListener('load', () => {
		navigator.serviceWorker.register('/sw.js').then(
			() => {
				console.log('SERVICE WORKER REGISTRADO');
			},
			(err) => {
				console.log('FALHA AO REGISTRAR SERVICE WORKER', err);
			}
		);
	});
}