import { qrcode, svg2url } from 'pure-svg-code';

const load = (image) => new Promise((resolve) => {
	image.onload = () => {
		resolve();
	};
});

export default async ({
	img,
	logoImg,
	code,
	width,
	height,
	tamanho,
	qrX,
	qrY,
	logoX,
	logoY,
	logoWidth,
	logoHeight,
	bg,
	fgInfo,
	bgInfo,
	info,
	numerar,
	numeracaoX,
	numeracaoY,
	numeroAtual
}) => {
	const canvas = document.createElement('canvas');
	canvas.width = width;
	canvas.height = height;

	const ctx = canvas.getContext('2d');

	ctx.beginPath();
	ctx.fillStyle = bg;
	ctx.fillRect(0, 0, width, height);

	if (img) {
		const fundo = new Image();
		fundo.src = img;
		fundo.crossOrigin = 'anonymous';
		await load(fundo);
		ctx.drawImage(fundo, 0, 0, width, height);
	}

	if (logoImg) {
		const logo = new Image();
		logo.src = logoImg;
		logo.crossOrigin = 'anonymous';
		await load(logo);
		ctx.drawImage(logo, logoX, logoY, logoWidth, logoHeight);
	}

	const svg = qrcode({
		content: code,
		padding: 2,
		width: 512,
		height: 512,
	});
	const urlQrcode = svg2url(svg);
	const qrImage = new Image();
	qrImage.src = urlQrcode;
	qrImage.crossOrigin = 'anonymous';
	await load(qrImage);
	ctx.drawImage(qrImage, qrX, qrY, tamanho, tamanho);

	const w = 180;
	const h = 45;
	const x = width / 2 - w / 2;
	const r = 10;
	ctx.fillStyle = bgInfo;
	ctx.beginPath();
	ctx.moveTo(x, 0);
	ctx.lineTo(x, h - r);
	ctx.arcTo(x, h, x + r, h, r);
	ctx.lineTo(x + w - r, h);
	ctx.arcTo(x + w, h, x + w, h - r, r);
	ctx.lineTo(x + w, 0);
	ctx.fill();

	ctx.font = 'normal normal bold 17px Arial';
	ctx.fillStyle = fgInfo;
	ctx.textAlign = 'center';
	ctx.fillText('Aqui tem gás da', width / 2, 28, w);

	if (info) {
		const lines = (info || '').split(/\n/);

		const w2 = 180;
		const h2 = 17 + lines.length * 17;
		const x2 = width / 2 - w2 / 2;

		ctx.fillStyle = bgInfo;
		ctx.beginPath();
		ctx.moveTo(x2, height);
		ctx.lineTo(x2, height - h2 + r);
		ctx.arcTo(x2, height - h2, x2 + r, height - h2, r);
		ctx.lineTo(x2 + w2 - r, height - h2);
		ctx.arcTo(x2 + w2, height - h2, x2 + w2, height - h2 + r, r);
		ctx.lineTo(x2 + w2, height);
		ctx.fill();

		ctx.font = 'normal normal bold 14px Arial';
		ctx.fillStyle = fgInfo;
		ctx.textAlign = 'center';
		let lineH = 0;
		for (const line of lines) {
			ctx.fillText(line, width / 2, (height - h2) + lineH + 22, w2);
			lineH += 17;
		}
	}

	if (numerar) {
		const w2 = 180;
		ctx.font = 'normal normal bold 24px Arial';
		ctx.fillStyle = fgInfo;
		ctx.textAlign = 'center';
		ctx.fillText("#" + numeroAtual, numeracaoY, numeracaoX, w2);
	}

	return canvas.toDataURL();
};