<template>
	<div>
		<v-container fluid>
			<img src="~/assets/images/logo-vendergas2.png" class="logo" v-if="$vuetify.breakpoint.mdAndUp">
			<v-row>
				<v-col cols="12" xs="12" :sm="relatorioHistorico ? 12 : 6" :md="relatorioHistorico ? 12 : 8" :lg="relatorioHistorico ? 12 : 9" :xl="relatorioHistorico ? 12 : 10">
					<v-card flat color="transparent">
						<v-sheet color="cyan" dark class="white--text px-5">
							<v-row>
								<v-col cols="12" sm="6" md="4" lg="3" xl="2">
									<v-select clearable outlined hide-details v-model="estabelecimentoId" :items="$estabelecimentos()" item-text="nomeFantasia" item-value="id">
										<template v-slot:label>
											<span style="color: #fff">Selecionar Estabelecimento</span>
										</template>
									</v-select>
								</v-col>
								<v-col cols="12" sm="6" md="4" lg="3" xl="2">
									<v-autocomplete :disabled="!estabelecimentoId" clearable no-data-text="Digite para buscar uma rota" outlined hide-details :loading="loadingRota" :search-input.sync="qsRota" v-model="rotaId" :items="rotasOptions" item-text="nome" item-value="id">
										<template v-slot:label>
											<span style="color: #fff">Selecionar rota</span>
										</template>
									</v-autocomplete>
								</v-col>
								<v-col cols="12" sm="6" md="4" lg="3" xl="2">
									<v-select @change="carregarQrcodes(1)" :disabled="!estabelecimentoId" clearable outlined hide-details v-model="entregadorId" :loading="loadingEntregadores" :items="getEntregadores" item-text="nome" item-value="id">
										<template v-slot:label>
											<span style="color: #fff">Selecionar Entregador</span>
										</template>
									</v-select>
								</v-col>
								<v-col cols="12" sm="3" md="2" lg="3" xl="1">
									<Datepicker :disabled="!estabelecimentoId" outlined hide-details v-model="data" @input="carregarQrcodes(1)" label="Data inicial"></Datepicker>
								</v-col>
								<v-col cols="12" sm="3" md="2" lg="3" xl="1">
									<Datepicker :disabled="!estabelecimentoId" outlined hide-details v-model="dataFinal" @input="carregarQrcodes(1)" label="Data final"></Datepicker>
								</v-col>
								<v-col cols="12" :sm="relatorioHistorico ? 3 : 6" md="2" lg="3" :xl="relatorioHistorico ? 1 : 2">
									<v-select label="Status" v-model="status" item-text="text" @change="carregarQrcodes(1, true, true)" item-value="value" outlined hide-details :disabled="!estabelecimentoId" :items="statuItems"></v-select>
								</v-col>
								<v-col cols="12" sm="3" :md="(mapa || relatorioHistorico) ? 2 : 3" lg="3" :xl="mapa ? 2 : 1">
									<v-text-field label="Valor da última compra" type="number" @input="carregarQrcodes(1)" step="0.01" min="0" v-model="valorUltimaCompra" outlined hide-details :disabled="!estabelecimentoId"></v-text-field>
								</v-col>
                                <v-col cols="12" sm="3" md="2" lg="3" xl="1">
                                    <v-switch color="indigo" label="Mostrar rota em linhas" v-if="mapa" v-model="mostrarRota" @change="carregarQrcodes()"></v-switch>
                                </v-col>
								<v-col cols="12" sm="1" md="2" lg="2" xl="1" v-if="!mapa && !relatorioHistorico">
									<v-btn :block="$vuetify.breakpoint.xlOnly || $vuetify.breakpoint.mdOnly" light small :disabled="!rotaId" @click="openAddCliente" v-if="$can('clientes:atualizar')" height="56">
										<v-icon>mdi-qrcode-plus</v-icon>
									</v-btn>
								</v-col>
								<v-col cols="12" sm="6" md="4" lg="3" xl="2" v-if="relatorioHistorico">
									<v-select clearable outlined hide-details v-model="produtoId" :items="produtos" item-text="nome" item-value="id">
										<template v-slot:label>
											<span style="color: #fff">Selecionar Produto</span>
										</template>
									</v-select>
								</v-col>
							</v-row>
							<v-row v-show="maisFiltros">
								<v-col cols="12" sm="6" md="4" lg="3" xl="2">
									<v-autocomplete @change="carregarQrcodes(1, true, true)" :disabled="!estabelecimentoId" clearable no-data-text="Digite para buscar um cliente" outlined hide-details :loading="loadingBuscaClientes" :search-input.sync="qsBuscaCliente" v-model="buscaClienteId" :items="buscaClientes" item-text="nome" item-value="id">
										<template v-slot:label>
											<span style="color: #fff">Selecionar cliente</span>
										</template>
									</v-autocomplete>
								</v-col>
							</v-row>
						</v-sheet>
						<v-card-actions>
							<span class="caption">{{totalQrcodes}} QR Codes encontrados</span>
							<v-spacer></v-spacer>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn icon small color="primary" outlined v-bind="attrs" v-on="on" @click="maisFiltros = !maisFiltros">
										<v-icon small>{{maisFiltros ? 'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon>
									</v-btn>
								</template>
								<span>{{maisFiltros ? 'Menos filtros' : 'Mais filtros'}}</span>
							</v-tooltip>
						</v-card-actions>
					</v-card>
					<v-progress-linear indeterminate v-if="loadingQrcodes"></v-progress-linear>
					<Mapa v-if="mapa" :qrcodes="historicoFiltrado" :mostrarRota="mostrarRota" @marcador="marcadorSelecionado"></Mapa>
					<v-row v-else-if="!relatorioHistorico">
						<v-col cols="12" xs="12" sm="6" md="3" xl="2" v-for="qrcode in qrcodes" :key="qrcode.id">
							<v-card :flat="qrcodeSelecionado.id != qrcode.id" :class="{'elevation-5': qrcodeSelecionado.id == qrcode.id}" @click="selecionarQrcode(qrcode)">
								<v-system-bar window>
									<span :style="{ fontWeight: qrcode.fg ? 'bolder' : 'normal', color: qrcode.fg }">{{qrcode.nome | limitchars(30)}} ({{getCount(qrcode)}})</span>
									<v-spacer></v-spacer>
									<v-icon v-if="rotaId" :color="qrcode.fg" @click.stop="openRemoveDialog(qrcode)">mdi-delete</v-icon>
								</v-system-bar>
								<v-divider></v-divider>
								<v-sheet tile :color="qrcode.bg" :dark="qrcode.dark">
									<v-layout justify-center>
										<v-icon class="ma-5" size="100">mdi-qrcode-scan</v-icon>
									</v-layout>
									<v-btn fab dark class="elevation-0" small absolute right bottom color="green darken-3" v-if="getCountCompras(qrcode) > 0" style="transform: translate(5px, -30px);">{{getCountCompras(qrcode)}}</v-btn>
								</v-sheet>
							</v-card>
						</v-col>
					</v-row>
					<v-pagination :disabled="loadingQrcodes" v-if="pages > 1 && !mapa && !relatorioHistorico" :length="pages" v-model="page" :total-visible="5" @input="paginate($event)"></v-pagination>
					<v-sheet v-if="historicoFiltrado.length && qrcodes.length && !mapa && !relatorioHistorico" color="cyan" dark class="py-1 px-2 mb-2 mt-3 elevation-1">
						<i class="mdi mdi-history"></i> Histórico
					</v-sheet>
					<v-data-table v-if="historicoFiltrado.length && qrcodes.length && !mapa" :items="historicoFiltrado" :headers="headersHistorico" :items-per-page="relatorioHistorico ? historicoFiltrado.length : 25" :footer-props="{ itemsPerPageOptions: [relatorioHistorico ? historicoFiltrado.length : 25] }">
						<template v-slot:[`item.createdAt`]="{ item }">
							<template v-if="!item.totais">{{item.createdAt | datetime}}</template>
						</template>
						<template v-slot:[`item.compra`]="{ item }">
							<template v-if="!item.totais">{{item.compra ? 'Sim' : 'Não'}}</template>
						</template>
						<template v-slot:[`item.produtos`]="{ item }">{{item.qrcodeItems | qrcodeItems}}</template>
						<template v-slot:[`item.tempoLeituras`]="{ item }">{{item.tempoLeituras ? item.tempoLeituras + ' minuto(s)' :''}}</template>
					</v-data-table>
				</v-col>
				<v-col cols="12" xs="12" sm="6" md="4" lg="3" xl="2" v-if="!relatorioHistorico">
					<v-card class="mb-3">
						<v-toolbar dense flat>
							<span>Legenda</span>
						</v-toolbar>
						<v-divider></v-divider>
						<v-list>
							<template v-for="(legenda, i) in legendas">
							<v-list-item :key="i" dense v-if="!mapa || (legenda.mapa && mapa)">
								<v-list-item-icon>
									<v-icon :color="legenda.color">mdi-square</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title>{{legenda.title}}</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							</template>
						</v-list>
					</v-card>
					<v-card v-if="!qrcodeSelecionado.id" height="80" disabled flat>
						<v-layout fill-height align-center justify-center>
							Selecione um cliente
						</v-layout>
					</v-card>
					<v-card v-else flat>
						<InfoCliente :historico="historico" :carregarQrcodes="carregarQrcodes" :cliente="qrcodeSelecionado" :bg="qrcodeSelecionado.bg" :dark="qrcodeSelecionado.dark"></InfoCliente>
						<div v-for="qrcodeLido in historicoSelecionado" :key="qrcodeLido.id">
							<v-list dense>
								<v-list-item>
									<v-list-item-icon>
										<v-icon>mdi-account-arrow-right</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title>{{qrcodeLido.entregador.nome}}</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-list-item>
									<v-list-item-icon>
										<v-icon>mdi-clock-check</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title>{{qrcodeLido.createdAt | datetime}}</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-list-item>
									<v-list-item-icon>
										<v-icon>mdi-information</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title>{{qrcodeLido.observacao || '---'}}</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-list-item>
									<v-list-item-icon>
										<v-icon>mdi-cash-usd</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title>R$ {{qrcodeLido.valor || '0.00'}}</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-list-item>
									<v-list-item-icon>
										<v-icon>mdi-shopping</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title>{{qrcodeLido.compra ? 'Sim' : 'Não'}}</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-list-item>
									<v-list-item-icon>
										<v-icon>mdi-package</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title>{{qrcodeLido.qrcodeItems | qrcodeItems}}</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
							</v-list>
							<v-divider></v-divider>
						</div>
					</v-card>
				</v-col>
			</v-row>
			<v-card v-if="valorMedioProduto.length > 0">
				<v-toolbar dense flat>
					<span>Valor médio por produto</span>
				</v-toolbar>
				<v-divider></v-divider>
				<v-simple-table>
					<thead>
						<tr>
							<th>Produto</th>
							<th>Quantidade</th>
							<th>Valor total</th>
							<th>Valor médio</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in valorMedioProduto" :key="item.produto.id">
							<td>{{item.produto.nome}}</td>
							<td>{{item.quantidade}}</td>
							<td>R$ {{((item.valorTotal / item.quantidadePedidos) * item.quantidade).toFixed(2)}}</td>
							<td>R$ {{(item.valorTotal / item.quantidadePedidos).toFixed(2)}}</td>
						</tr>
					</tbody>
				</v-simple-table>
			</v-card>
			<v-dialog persistent width="300" v-model="addDialog">
				<v-card :loading="loading">
					<v-toolbar dense flat>
						<span>Adicionar rota</span>
						<v-spacer></v-spacer>
						<v-icon @click="addDialog = false">mdi-close</v-icon>
					</v-toolbar>
					<v-divider></v-divider>
					<v-card-text>
						<v-form>
							<v-text-field hide-details label="Nome" v-model="nomeRota"></v-text-field>
						</v-form>
					</v-card-text>
					<v-card-actions>
						<v-btn :disabled="!nomeRota" color="secondary" @click="addRota">Salvar</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-dialog persistent width="300" v-model="renomearDialog">
				<v-card :loading="loading">
					<v-toolbar dense flat>
						<span>Renomear rota</span>
						<v-spacer></v-spacer>
						<v-icon @click="renomearDialog = false">mdi-close</v-icon>
					</v-toolbar>
					<v-divider></v-divider>
					<v-card-text>
						<v-form>
							<v-text-field hide-details label="Nome" v-model="nomeRota"></v-text-field>
						</v-form>
					</v-card-text>
					<v-card-actions>
						<v-btn :disabled="!nomeRota" color="secondary" @click="renomearRota">Salvar</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-dialog persistent width="600" v-model="addQrcodeDialog">
				<v-card :loading="loading">
					<v-toolbar dense flat>
						<span>Adicionar QR code</span>
						<v-spacer></v-spacer>
						<v-icon @click="addQrcodeDialog = false">mdi-close</v-icon>
					</v-toolbar>
					<v-divider></v-divider>
					<v-card-text class="pa-0">
						<v-simple-table>
							<thead>
								<tr>
									<th>CLiente</th>
									<th>Frenquência de Leitura</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="c in clientesSelecionados" :key="c.id">
									<td>{{c.nome}}</td>
									<td>
										<v-text-field dense outlined hide-details type="number" min="0" v-model="c.frequenciaVisita" style="width:100px"></v-text-field>
									</td>
									<td>
										<v-btn icon @click="removerAssociacao(c.id)">
											<v-icon>mdi-delete</v-icon>
										</v-btn>
									</td>
								</tr>
							</tbody>
						</v-simple-table>
						<v-form class="pa-5">
							<v-autocomplete ref="clienteSelect" label="Adicionar cliente" hide-details :loading="loadingClientes" :search-input.sync="qsCliente" v-model="clienteId" @change="addAssociacao" :items="clientes" item-text="nome" item-value="id"></v-autocomplete>
						</v-form>
					</v-card-text>
					<v-card-actions>
						<v-btn color="secondary" :disabled="!clientesSelecionados.length" @click="addCliente">Salvar</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-dialog width="300" v-model="removeDialog" persistent>
				<v-card :loading="loading">
					<v-container>Deseja remover este QR code?</v-container>
					<v-divider></v-divider>
					<v-card-actions>
						<v-btn dark color="secondary" @click="remover">Sim</v-btn>
						<v-btn color="error" @click="removeDialog = false">Não</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-dialog width="300" v-model="removerRotaDialog" persistent>
				<v-card :loading="loading">
					<v-container>Deseja remover esta rota?</v-container>
					<v-divider></v-divider>
					<v-card-actions>
						<v-btn dark color="secondary" @click="removerRota">Sim</v-btn>
						<v-btn color="error" @click="removerRotaDialog = false">Não</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-container>
	</div>
</template>

<style scoped>
	.logo {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		opacity: 0.5;
		z-index: 0;
	}
	.col-12 {
		z-index: 1;
	}
	.v-badge {
		width: 100%;
	}
</style>

<script>
import eventBus from '../config/eventBus';
import events from '../config/events';
import moment from 'moment';
import Datepicker from '../components/Datepicker';
import InfoCliente from '../components/InfoCliente';
import Mapa from '../components/Mapa';

export default {
	components: {
		Datepicker,
		InfoCliente,
		Mapa
	},
	props: {
		mapa: {
			type: Boolean,
			default: false
		},
		relatorioHistorico: {
			type: Boolean,
			default: false
		}
	},
	data(){
		return {
			rotas: [],
			entregadores: [],
			qsRota: '',
			loadingRota: false,
			loadingEntregadores: false,
			mostrarRota: true,
			rotaId: null,
			addDialog: false,
			nomeRota: '',
			loading: false,
			estabelecimentoId: null,
			clientes: [],
			buscaClientes: [],
			addQrcodeDialog: false,
			loadingClientes: false,
			qsCliente: '',
			qsBuscaCliente: '',
			clienteId: null,
			buscaClienteId: null,
			timerBuscaCliente: null,
			entregadorId: null,
			clientesSelecionados: [],
			loadingQrcodes: false,
			qrcodes: [],
			qrcodesBruto: [],
			pages: 0,
			page: 1,
			qrcode: null,
			removeDialog: false,
			removerRotaDialog: false,
			renomearDialog: false,
			historico: [],
			qrcodeSelecionado: {},
			historicoSelecionado: [],
			totalQrcodes: 0,
			data: moment().hour(0).minute(0).second(0).millisecond(0).toDate(),
			dataFinal: moment().hour(23).minute(59).second(59).millisecond(999).toDate(),
			status: 4,
			valorUltimaCompra: null,
			produtos: [],
			produtoId: null,
			results: null,
			maisFiltros: false,
			loadingBuscaClientes: false,
			statuItems: [
				{ text: 'Visitou', value: 5 },
				{ text: 'Visitou e comprou', value: 1 },
				{ text: 'Visitou e não comprou', value: 2 },
				{ text: 'Não visitou', value: 3 },
				{ text: 'Visita atrasada', value: 6 },
				{ text: 'TODOS', value: 4 },
			],
			headersHistorico: [
				{ text: 'Cliente', value: 'cliente.nome', sortable: false },
				{ text: 'Entregador', value: 'entregador.nome', sortable: false },
				{ text: 'Rota', value: 'rotum.nome', sortable: false },
				{ text: 'Primeira leitura', value: 'leitura1', sortable: false },
				{ text: 'Segunda Leitura', value: 'leitura2', sortable: false },
				{ text: 'Tempo entre leituras', value: 'tempoLeituras', sortable: false },
				{ text: 'Observação', value: 'observacao', sortable: false },
				{ text: 'Valor (R$)', value: 'valor', sortable: false },
				{ text: 'Compra', value: 'compra', sortable: false },
				{ text: 'Produtos', value: 'produtos', sortable: false }
			],
			legendas: [
				{ color: 'red', title: 'Não visita a muito tempo', mapa: false },
				{ color: 'yellow', title: 'Visitou e não comprou', mapa: true },
				{ color: 'secondary', title: 'Visitou e comprou', mapa: true }
			]
		};
	},
	created(){
		this.setEstabelecimento();
		eventBus.$on(events.REFRESH, this.carregarQrcodes);
		eventBus.$on(events.ESTABELECIMENTOS_ATUALIZADOS, this.setEstabelecimento);
		eventBus.$on(events.IMPRIMIR, this.imprimir);
		eventBus.$on(events.EXPORT_EXCEL, this.exportExcel);
		this.$socket().on(events.QRCODE_LIDO, (estabelecimentoId) => {
			if(this.estabelecimentoId == estabelecimentoId) {
				this.carregarQrcodes(1, false);
			}
		});
	},
	mounted(){
		if(this.relatorioHistorico) {
			eventBus.$emit(events.SHOW_PRINT_BUTTON, true);
			eventBus.$emit(events.SHOW_EXCEL_BUTTON, true);
		}
	},
	destroyed(){
		eventBus.$off(events.REFRESH, this.carregarQrcodes);
		eventBus.$off(events.ESTABELECIMENTOS_ATUALIZADOS, this.setEstabelecimento);
		eventBus.$off(events.IMPRIMIR, this.imprimir);
		eventBus.$off(events.EXPORT_EXCEL, this.exportExcel);
		eventBus.$emit(events.SHOW_PRINT_BUTTON, false);
		eventBus.$emit(events.SHOW_EXCEL_BUTTON, false);
	},
	methods: {
		addAssociacao(idCliente) {
			const existente = this.clientesSelecionados.find(c => c.id == idCliente);
			if(existente) {
				this.$alert('Cliente já adicionado');
				this.$nextTick(() => {
					this.clienteId = null;
					this.$refs.clienteSelect.blur();
				});
			}
			else {
				const cliente = this.clientes.find(c => c.id == idCliente);
				this.clientesSelecionados.push(cliente);
				this.$nextTick(() => {
					this.clienteId = null;
					this.$refs.clienteSelect.blur();
				});
			}
		},
		removerAssociacao(idCliente) {
			this.clientesSelecionados = this.clientesSelecionados.filter(c => c.id != idCliente);
		},
		async carregarHistorico(){
			const dataInicio = this.data;
			const dataFim = this.dataFinal;
			this.results = (await this.$qrcodesLidoService.find(1, {
				...(this.rotaId ? { rotumId: this.rotaId } : {}),
				estabelecimentoId: this.estabelecimentoId,
				entregadorId: this.entregadorId,
				paginate: 10000,
				date_createdAt: [moment(dataInicio).toISOString(), moment(dataFim).toISOString()].join()
			}));
			this.historico = this.results.docs;
			if(this.relatorioHistorico) {
				this.carregarProdutos();
			}
		},
		getCount(qrcode) {
			return this.historico.filter(q => q.clienteId == qrcode.id).length;
		},
		getCountCompras(qrcode) {
			return this.historico.filter(q => q.clienteId == qrcode.id && q.compra).length;
		},
		selecionarQrcode(qrcode) {
			this.qrcodeSelecionado = this.qrcodesBruto.find(q => q.id == qrcode.id) || {};
			this.historicoSelecionado = this.historico.filter(h => h.clienteId == qrcode.id);
		},
		openRenomearDialog() {
			const rota = this.rotas.find(x => x.id == this.rotaId);
			this.nomeRota = rota.nome;
			this.renomearDialog = true;
		},
		async renomearRota(){
			this.loading = true;
			try {
				const rota = this.rotas.find(x => x.id == this.rotaId);
				rota.nome = this.nomeRota;
				await this.$rotasService.update(this.rotaId, rota);
				this.renomearDialog = false;
				this.qsRota = rota.nome;
			}
			catch(err) {
				console.log(err);
			}
			this.loading = false;
		},
		setEstabelecimento(){
			this.estabelecimentoId = (this.$estabelecimentos()[0] || {}).id;
		},
		async carregarClientes(){
			this.loadingClientes = true;
			try {
				this.clientes = (await this.$clientesService.find(1, {
					...(this.qsCliente ? { iLike_nome: `%${this.qsCliente}%` } : {}),
					not_qrcode: 'null'
				})).docs;
			}
			catch(err){
				console.log(err);
			}
			this.loadingClientes = false;
		},
		async carregarBuscaClientes(){
			this.loadingBuscaClientes = true;
			try {
				this.buscaClientes = (await this.$clientesService.find(1, {
					...(this.qsBuscaCliente ? { iLike_nome: `%${this.qsBuscaCliente}%` } : {}),
					not_qrcode: 'null'
				})).docs;
			}
			catch(err){
				console.log(err);
			}
			this.loadingBuscaClientes = false;
		},
		openRemoveDialog(qrcode){
			this.qrcode = qrcode;
			this.removeDialog = true;
		},
		async remover(){
			this.loading = true;
			try {
				this.qrcode.rotas = this.qrcode.rotas.filter(x => x != this.rotaId);
				await this.$clientesService.update(this.qrcode.id, this.qrcode);
				await this.carregarQrcodes(this.qrcodes.length == 1 ? this.page - 1 : this.page);
				this.removeDialog = false;
			}
			catch(err) {
				console.log(err);
			}
			this.loading = false;
		},
		async removerRota() {
			this.loading = true;
			try {
				await this.$rotasService.remove(this.rotaId);
				this.qrcodesBruto = [];
				this.qrcodes = [];
				this.qrcodeSelecionado = {};
				this.historicoSelecionado = [];
				this.rotaId = null;
				this.removerRotaDialog = false;
			}
			catch(err) {
				console.log(err);
			}
			this.loading = false;
		},
		async carregarRotas() {
			if(this.estabelecimentoId) {
				this.loadingRota = true;
				try {
					this.rotas = (await this.$rotasService.find(1, {
						...(this.qsRota ? { iLike_nome: `%${this.qsRota}%` } : {}),
						estabelecimentoId: this.estabelecimentoId
					})).docs;
				} catch(err){
					console.log(err);
				}
				this.loadingRota = false;
			} else this.rotas = [];
		},
		openAddDialog(){
			this.nomeRota = '';
			this.addDialog = true;
		},
		async openAddCliente(){
			this.loading = false;
			this.addQrcodeDialog = true;
			this.clientesSelecionados = [];
			this.carregarClientes();
		},
		async addRota(){
			this.loading = true;
			try {
				await this.$rotasService.create({
					nome: this.nomeRota,
					estabelecimentoId: this.estabelecimentoId
				});
				this.addDialog = false;
				await this.carregarRotas();
				this.rotaId = this.rotas[0].id;
			}
			catch(err){
				console.log(err);
			}
			this.loading = false;
		},

		async carregarEntregadores() {
			this.loadingEntregadores = true;
			this.entregadores = await this.$entregadoresService.find(null, {
				in_estabelecimentoId: this.estabelecimentoId || null,
			});
			this.entregadores;
			this.loadingEntregadores = false;
		},
		async carregarQrcodes(page = 1, loading = true, useCache = false){
			if(this.$token()) {
				if(loading) {
					this.loadingQrcodes = true;
				}
				try {
					this.qrcodeSelecionado = {};

					await this.carregarHistorico();
					let clientesJaInseridos = [];
					this.qrcodesBruto = this.historico.map(hist => {
						const qrcode = hist.cliente;
						if(qrcode)  {
							qrcode.diasPassados = qrcode.dataUltimaCompra ? moment().diff(moment(qrcode.dataUltimaCompra), 'days') : 0;
							if(!isNaN(qrcode.diasPassados)) {
								const lastHist = this.historico.filter(h => h.clienteId == qrcode.id)[0] || {};
								if(qrcode.diasPassados > qrcode.frequenciaVisita && qrcode.frequenciaVisita >= 1 && !this.mapa) {
									qrcode.bg = 'red';
									qrcode.fg = '#B71C1C';
									qrcode.dark = true;
								}
								else if(this.historico.find(h => h.clienteId == qrcode.id) && !lastHist.compra) {
									qrcode.bg = 'yellow';
									qrcode.fg = '#F57F17';
									qrcode.dark = false;
								}
								else if(this.historico.find(h => h.clienteId == qrcode.id) && lastHist.compra) {
									qrcode.bg = 'secondary';
									qrcode.fg = '#217a6b';
									qrcode.dark = true;
								}
							}
							return qrcode;
						}
					
					}).filter(qrcode => {
						if(qrcode) {

							const lastHist = this.historico.filter(h => h.clienteId == qrcode.id)[0] || {};
							if(clientesJaInseridos.includes(qrcode.id)) {
								return false;
							}
							if(this.entregadorId && !this.historico.find(qrcodeLido => qrcodeLido.entregador.id == this.entregadorId && qrcodeLido.cliente && qrcodeLido.cliente.id == qrcode.id)) {
								return false;
							}
							if(this.buscaClienteId && qrcode.id != this.buscaClienteId) {
								return false;
							}
							switch(this.status) {
								case 1:
									if(this.historico.find(h => h.clienteId == qrcode.id) && lastHist.compra) {
										clientesJaInseridos.push(qrcode.id);
										return true;
									}
									break;
								case 2:
									if(this.historico.find(h => h.clienteId == qrcode.id) && !lastHist.compra) {
										clientesJaInseridos.push(qrcode.id);
										return true;
									}
									break;
								case 3:
									if(!this.historico.find(h => h.clienteId == qrcode.id)) {
										clientesJaInseridos.push(qrcode.id);
										return true;
									}
									break;
								case 5:
									if(this.historico.find(h => h.clienteId == qrcode.id)) {
										clientesJaInseridos.push(qrcode.id);
										return true;
									}
									break;
								case 6:
									if(qrcode.bg == 'red') {
										clientesJaInseridos.push(qrcode.id);
										return true;
									}
									break;
								case 4:
									clientesJaInseridos.push(qrcode.id);	
									return true;
							}
							return false;
						}
					});

					this.pages = this.results.pages;
					this.totalQrcodes = this.results.total;
					this.qrcodes = this.qrcodesBruto;
				}
				catch(err){
					console.log(err);
				}
				this.loadingQrcodes = false;
			}
			else {
				this.qrcodesBruto = [];
				this.qrcodes = [];
				this.page = 1;
				this.qrcodeSelecionado = {};
				this.historicoSelecionado = [];
			}
		},
		paginate(page) {
			this.carregarQrcodes(page, true)
			this.page = page;
		},
		async addCliente(){
			this.loading = true;
			try {
				await this.$rotasService.associacao(this.rotaId, this.clientesSelecionados);
				await this.carregarQrcodes();
				this.addQrcodeDialog = false;
			}
			catch(err) {
				console.log(err);
			}
			this.loading = false;
		},
		marcadorSelecionado(cliente){
			this.selecionarQrcode(cliente);
		},
		exportExcel() {
			this.$excel({
				title: this.$route.name,
				headers: this.headersHistorico,
				data: this.historicoFiltrado,
				dataInicio: this.data,
				dataFim: this.dataFinal,
				mapper: {
					produtos: (item) => this.$options.filters.qrcodeItems(item.qrcodeItems),
				},
			});
		},
		imprimir() {
			this.$print({
				title: this.$route.name,
				headers: this.headersHistorico,
				data: this.historicoFiltrado,
				dataInicio: this.data,
				dataFim: this.dataFinal,
				mapper: {
					createdAt: (item) => item.totais ? '' : this.$options.filters.datetime(item.createdAt),
					compra: (item) => item.totais ? '' : item.compra ? 'Sim' : 'Não',
					produtos: (item) => this.$options.filters.qrcodeItems(item.qrcodeItems),

				}
			});
		},
		async carregarProdutos(){
			this.loading = true;
			this.produtos = (await this.$produtosService.find(1, {
				estabelecimentoId: this.estabelecimentoId,
				paginate: 10000
			})).docs.filter(prod => this.historico.some(qrcodeLido => qrcodeLido.qrcodeItems.some(item => item.produtoId == prod.id)));
			this.produtos.reverse();
			this.produtos.unshift({
				nome: 'TODOS',
				id: null
			});
			this.loading = false;
		},
		produtosTotais() {
			const qrcodesLidos = this.historicoFiltrado;
			const somas = [];
			for(const qrcodeLido of qrcodesLidos) {
				for(const qrcodeItem of qrcodeLido.qrcodeItems) {
					if(qrcodeItem.produto) {
						const soma = somas.find(s => s.produto.id == qrcodeItem.produto.id);
						if(!soma) {
							somas.push({
								produto: qrcodeItem.produto,
								quantidade: qrcodeItem.quantidade
							});
						}
						else {
							soma.quantidade += qrcodeItem.quantidade;
						}
					}
				}
			}
			return somas.map(soma => `${soma.quantidade}x ${soma.produto.nome}`).join(', ') || '---';
		}
	},
	watch: {
		qsRota(){
			if(navigator.onLine) this.carregarRotas();
		},
		qsCliente(){
			if(navigator.onLine) this.carregarClientes();
		},
		qsBuscaCliente(v) {
			if (this.timerBuscaCliente) {
				clearTimeout(this.timerBuscaCliente);
			}
			this.timerBuscaCliente = setTimeout(() => {
				if (navigator.onLine) this.carregarBuscaClientes();
			}, 500);
		},
		estabelecimentoId(v) {
			if(v) {
				this.rotaId = null;
				this.carregarRotas();
				this.carregarQrcodes();
				this.carregarEntregadores();
			}
			else {
				this.rotas = [];
				this.rotaId = null;
			}
		},
		rotaId() {
			this.carregarQrcodes();
			this.carregarEntregadores();
		},
		// socket() {
		//     console.log("a")
		//     // if(navigator.onLine) {

		//     this.$socket().on(events.QRCODE_LIDO, (rotaId) => {
		//         if(this.rotaId == rotaId) {
		//             this.carregarQrcodes(1, false);
		//             this.carregarEntregadores();
		//         }
		//     });
		//     // }
		// }
	},
	computed: {
		rotasOptions(){
			return [{nome: 'TODAS', id: null}, ...this.rotas];
		},
		historicoFiltrado(){
			let historicoFiltrado = this.historico.filter(qrcodeLido => {
				if(this.status == 3) {
					return false;
				}
				if(this.status == 6 && !this.qrcodesBruto.find(qrcode => qrcodeLido.cliente && qrcodeLido.cliente.id == qrcode.id && qrcode.bg == 'red')) {
					return false;
				}
				if(this.status == 1 && !qrcodeLido.compra) {
					return false;
				}
				if(this.status == 2 && qrcodeLido.compra) {
					return false;
				}
				if(this.valorUltimaCompra && Number(qrcodeLido.valor) != Number(this.valorUltimaCompra)){
					return false;
				}
				if(this.produtoId && !qrcodeLido.qrcodeItems.some(item => item.produtoId == this.produtoId)) {
					return false;
				}
				if(this.buscaClienteId && qrcodeLido.clienteId != this.buscaClienteId) {
					return false;
				}
				return true;
			});
			if(historicoFiltrado.length) {
				let valorTotal = 0;
				const produtosTotais = [];
				for(const qrcodeLido of historicoFiltrado) {
					valorTotal += Number(qrcodeLido.valor || 0);
					for(const qrcodeItem of qrcodeLido.qrcodeItems) {
						if(qrcodeItem.produto) {
							const itemEncontrado = produtosTotais.find(item => item.produto.id == qrcodeItem.produto.id);
							if(itemEncontrado) {
								itemEncontrado.quantidade += qrcodeItem.quantidade;
							}
							else {
								produtosTotais.push({
									produto: qrcodeItem.produto,
									quantidade: qrcodeItem.quantidade
								});
							}
						}
					}
			    }

				// Ordena as leituras das mais antigas para as mais novas. By Pedro Santos
                historicoFiltrado.sort((a, b) => {
					return new Date(a.createdAt) - new Date(b.createdAt)
                })

				let pedidosFiltrados = [];	// Nova formatação das leituras, cada posição corresponde a uma ou duas leituras do mesmo cliente
				let repetidos = []; 		// Váriavel de controle para agrupar os pedidos de dois em dois
				let totalLeituras = 0; 		// Total de leituras realizadas.
                historicoFiltrado.forEach(leitura => {
                    totalLeituras++;
                    let jaExiste = repetidos.find( rep => rep.clienteId === leitura.clienteId);

					// Caso já exista uma leitura do mesmo cliente a nova leitura é
					// adicionada na mesma posição da primeira leitura encontrada.
                    if(jaExiste !== undefined){

                        pedidosFiltrados[jaExiste.index].leitura2 = moment(leitura.createdAt).format('DD/MM/YYYY HH:mm');
                        pedidosFiltrados[jaExiste.index].valor = Number(pedidosFiltrados[jaExiste.index].valor) + Number(leitura.valor);
                        pedidosFiltrados[jaExiste.index].compra = pedidosFiltrados[jaExiste.index].compra || leitura.compra;
                        pedidosFiltrados[jaExiste.index].qrcodeItems.push(...leitura.qrcodeItems);
                        pedidosFiltrados[jaExiste.index].tempoLeituras = 
                                Math.abs(moment(pedidosFiltrados[jaExiste.index].leitura1, 'DD/MM/YYYY HH:mm')
                                .diff(moment(pedidosFiltrados[jaExiste.index].leitura2, 'DD/MM/YYYY HH:mm'), 'minutes'));
						// Remove a leitura do array de repetidos, para não ser adicionada uma terceira leitura.
                        repetidos = repetidos.filter( rep => rep.clienteId !== leitura.clienteId);
                    } else {

						// Caso não exista uma leitura do mesmo cliente, a nova 
						// leitura é adicionada e é criada um registro na variável 
						// de repetidos com a posição da primeira leitura do cliente
                        repetidos.push({ clienteId: leitura.clienteId, index: pedidosFiltrados.length });
                        pedidosFiltrados.push({...leitura, leitura1: moment(leitura.createdAt).format('DD/MM/YYYY HH:mm')});
                    }
                });

                historicoFiltrado = pedidosFiltrados;

				const totalClientes = new Set(historicoFiltrado.map(x => x.cliente && x.cliente.id )).size;
				historicoFiltrado.push({
					cliente: {
						nome: `TOTAL (${totalClientes} clientes, ${totalLeituras} leituras)`
					},
					totais: true,
					qrcodeItems: produtosTotais,
					valor: `R$ ${valorTotal.toFixed(2)}`
				});
			}
			return historicoFiltrado;
		},
		getEntregadores() {
            if(this.entregadores.docs == undefined)  return  [{ nome: 'TODOS', id: null }]
            this.entregadores.docs.unshift({
				nome: 'TODOS',
				id: null
			});
			return this.entregadores.docs;
		},
		valorMedioProduto(){
			const pedidos = this.historico;
			const mediaProduto = [];
			for(const pedido of pedidos) {
				if(pedido.qrcodeItems.length > 0 && pedido.compra) {
					const produto = pedido.qrcodeItems[0].produto;
					const quantidade = pedido.qrcodeItems[0].quantidade;
					if(produto) {
						const produtoEncontrado = mediaProduto.find(m => m.produto.id == produto.id);
						const valor = Number(pedido.valor);
						if(!produtoEncontrado) {
							mediaProduto.push({
								produto,
								valorTotal: valor * quantidade,
								quantidadePedidos: 1,
								quantidade: quantidade
							});
						}
						else {
							produtoEncontrado.valorTotal += valor;
							produtoEncontrado.quantidadePedidos++;
							produtoEncontrado.quantidade += quantidade;
						}
					}
				}
			}
			return mediaProduto;
		}
	}
};
</script>